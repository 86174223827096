<template>
  <div ref="zoomRef" class="image-zoom" :class="{ 'can-zoom': isEnableZoom }">
    <div class="normal-preview" :style="{ width: `${width}px`, height: `${height}px` }" @mousemove="mousemoveHandler" @mouseout="isShowZoom = false">
      <img :src="src" alt="" class="normal-image" @load="imageLoadHandler" @error="normalRatio = 0" />
      <div
        class="position-block"
        :style="{ width: `${zoomNormalWidth}px`, height: `${zoomNormalHeight}px`, transform: `translate(${zoomNormalX}px, ${zoomNormalY}px)` }"
      ></div>
      <div class="image-error" v-if="!isEnableZoom">加载失败</div>
    </div>
    <div class="zoom-preview" :style="{ right: `-${width + 12}px`, width: `${width + 2}px`, height: `${height + 2}px` }" v-show="isShowZoom">
      <canvas class="zoom-canvas" :width="width" :height="height">当前浏览器不支持</canvas>
    </div>
  </div>
</template>

<script>
let ctx, img;
export default {
  name: 'HtImageZoomer',
  props: {
    // 图片地址
    src: {
      type: String,
      default: '',
    },
    // 正常视图宽度
    width: {
      type: Number,
      default: 600,
    },
    // 正常视图高度
    height: {
      type: Number,
      default: 600,
    },
    // 放大比例（基于正常视图）
    zoomRatio: {
      type: Number,
      default: 2.5,
    },
  },
  data() {
    return {
      // 是否显示放大预览区域
      isShowZoom: false,
      // 放大区域在正常视图中坐标
      zoomNormalX: 0,
      zoomNormalY: 0,
      // 正常视图与原图尺寸比例
      normalRatio: 0,
      // 正常视图与原图比例不一致时出现的宽高方向留白（单侧值）
      blankWidth: 0,
      blankHeight: 0,
    };
  },
  watch: {
    src() {
      this.normalRatio = 0;
    },
    normalRatio(ratio) {
      if (ratio) {
        ctx = this.$refs.zoomRef.querySelector('.zoom-canvas').getContext('2d');
        img = this.$refs.zoomRef.querySelector('.normal-image');
      }
    },
  },
  computed: {
    // 是否启用放大器（图片是否正常加载）
    isEnableZoom() {
      return this.src && this.normalRatio;
    },
    // 放大区域在正常视图中尺寸
    zoomNormalWidth() {
      return this.width / this.zoomRatio;
    },
    zoomNormalHeight() {
      return this.height / this.zoomRatio;
    },
  },
  methods: {
    imageLoadHandler(e) {
      const normalWidth = this.width;
      const normalHeight = this.height;
      const naturalWidth = e.target.naturalWidth;
      const naturalHeight = e.target.naturalHeight;
      const widthRatio = normalWidth / naturalWidth;
      const heightRatio = normalHeight / naturalHeight;
      if (widthRatio < heightRatio) {
        // 高度方向上有留白
        this.normalRatio = widthRatio;
        this.blankWidth = 0;
        this.blankHeight = (normalHeight - naturalHeight * widthRatio) / 2;
      } else if (widthRatio / heightRatio) {
        // 宽度方向上有留白
        this.normalRatio = heightRatio;
        this.blankWidth = (normalWidth - naturalWidth * heightRatio) / 2;
        this.blankHeight = 0;
      } else {
        this.normalRatio = 1;
        this.blankWidth = 0;
        this.blankHeight = 0;
      }
    },
    zoomViewHandler() {
      if (ctx && img) {
        const canvasWidth = this.width;
        const canvasHeight = this.height;
        // 清空画布
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);
        this.isShowZoom = true;
        // 放大区域在原图中坐标
        let zoomOriginX, zoomOriginY;
        if (this.blankWidth) {
          zoomOriginX = (this.zoomNormalX - this.blankWidth) / this.normalRatio;
        } else {
          zoomOriginX = this.zoomNormalX / this.normalRatio;
        }
        if (this.blankHeight) {
          zoomOriginY = (this.zoomNormalY - this.blankHeight) / this.normalRatio;
        } else {
          zoomOriginY = this.zoomNormalY / this.normalRatio;
        }
        // 放大区域在原图中尺寸
        const zoomOriginWidth = this.zoomNormalWidth / this.normalRatio;
        const zoomOriginHeight = this.zoomNormalHeight / this.normalRatio;
        ctx.drawImage(img, zoomOriginX, zoomOriginY, zoomOriginWidth, zoomOriginHeight, 0, 0, canvasWidth, canvasHeight);
      }
    },
    mousemoveHandler(e) {
      if (this.isEnableZoom) {
        let offsetX = e.layerX - this.zoomNormalWidth / 2;
        let offsetY = e.layerY - this.zoomNormalHeight / 2;
        if (offsetX < 0) {
          offsetX = 0;
        }
        if (offsetX > this.width - this.zoomNormalWidth) {
          offsetX = this.width - this.zoomNormalWidth;
        }
        if (offsetY < 0) {
          offsetY = 0;
        }
        if (offsetY > this.height - this.zoomNormalHeight) {
          offsetY = this.height - this.zoomNormalHeight;
        }
        this.zoomNormalX = offsetX;
        this.zoomNormalY = offsetY;
        this.zoomViewHandler();
      }
    },
  },
};
</script>

<style scoped>
.image-zoom {
  position: relative;
  display: inline-block;
}

.image-zoom .normal-preview {
  box-sizing: content-box;
  position: relative;
  border: 1px solid #ebeef5;
  overflow: hidden;
}

.image-zoom .normal-preview .normal-image {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-zoom .normal-preview .position-block {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  background-image: radial-gradient(rgba(255, 37, 92, 0.45) 1px, rgba(255, 37, 92, 0.15) 1px);
  background-repeat: repeat;
  background-size: 4px 4px;
}

.image-zoom.can-zoom:hover .normal-preview .position-block {
  display: block;
}

.image-zoom .normal-preview .image-error {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  font-size: 14px;
  color: #c0c4cc;
  user-select: none;
  background-color: rgb(245, 247, 250);
}

.image-zoom.can-zoom .normal-preview {
  cursor: move;
}

.image-zoom .zoom-preview {
  position: absolute;
  top: 0;
  z-index: 9;
  background-color: #ffffff;
  border: 1px solid #ebeef5;
}
</style>
