<template>
  <div class="custom">
    <div class="fx" v-if="goldenWeighList && goldenWeighList.length > 0">
      <div class="title">金重</div>
      <div class="material selectBox">
        <el-radio-group v-model="dataFrom.goldWeigh" size="small" @change="getSizeRange($event, 'goldWeigh')">
          <span class="inchesFix">
            <el-radio-button v-for="item in goldenWeighList" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
          </span>
        </el-radio-group>
      </div>
    </div>
    <!-- 表带颜色 -->
    <div class="fx" v-if="bandColorList && bandColorList.length > 0">
      <div class="title">表带颜色</div>
      <div class="material selectBox">
        <el-radio-group v-model="dataFrom.bandColor" size="small" @change="getSizeRange($event, 'bandColor')">
          <span class="inchesFix">
            <el-radio-button v-for="item in bandColorList" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
          </span>
        </el-radio-group>
      </div>
    </div>
    <!-- 备注 -->
    <div class="textareaRemark">
      <div class="title">备注</div>
      <el-input
        :rows="6"
        :maxlength="64"
        type="textarea"
        placeholder="其他要求与说明请在此处备注说明,字符数限制64位"
        v-model="dataFrom.productRemark"
      ></el-input>
    </div>
    <!-- 下单件数 -->
    <div class="box_detail_input marginFx">
      <div class="input-wrap">
        <span class="prompt">库存{{ dropDownOptional.stockQuantity || 0 }}件，可选择不同规格</span>
        <span class="prompt placeNum">购买数量：</span>
        <el-input-number v-model="dataFrom.quantity" :min="0" :max="dropDownOptional.stockQuantity" label="描述文字"></el-input-number>
      </div>
    </div>
  </div>
</template>
<script>
import { getOptional, getPriceRangeSpot } from '@/api/detailsApi';
import { deepClone } from '@/utils/util';
export default {
  name: 'Sopt',
  props: {
    DataList: {
      type: Object,
      default() {
        return {};
      },
    },
    leastQuantity: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dataFrom: {
        styleNumber: this.DataList.styleNumber, // 公司款号
        productName: this.DataList.productName,
        isCouples: this.DataList.isCouples, // 是否情侣款
        classify: 1, // 产品结构
        quantity: 0, // 数量
        shopType: 3,
        spotFlag: 1,
        bandColor: '',
        goldWeigh: '',
        productRemark: '',
      },
      dropDownOptional: {},
      goldenWeighList: [],
      bandColorList: [],
    };
  },
  mounted() {
    this.dataFrom = { ...this.dataFrom, ...this.DataList };
    // 获取可选属性
    this.getOptional();
  },
  methods: {
    // 金重排序
    mainStoneSort(list) {
      // 1. 将每个区间字符串解析为对象
      const parsedRanges = list?.map((range) => {
        const [start, end] = range.split('-').map(parseFloat);
        return { start, end, range }; // 保留原始字符串以便最后恢复
      });
      // 2. 根据 start 值进行排序
      parsedRanges.sort((a, b) => a.start - b.start);
      // 3. 提取排序后的原始字符串
      return parsedRanges.map((item) => item.range);
    },
    // 获取可选属性
    getOptional(text) {
      let obj = {
        styleNumber: this.DataList.styleNumber,
        classify: 3,
        spotFlag: 1,
        gender: '',
      };
      getOptional(obj).then((res) => {
        this.dropDownOptional = { ...this.dropDownOptional, ...res.data };
        this.$emit('upDataStockQuantity', this.dropDownOptional.stockQuantity);
        this.goldenWeighList = this.mainStoneSort(res.data.goldenWeigh);
        this.bandColorList = res.data.bandColor;
      });
    },
    getSizeRange(value, type) {
      this.dataFrom[type] = value;
      this.fetchPriceRangeSpot();
    },
    fetchPriceRangeSpot() {
      const obj = deepClone(this.dataFrom);
      delete obj.texture;
      if (!obj.goldWeigh || !obj.bandColor) return;
      getPriceRangeSpot(obj).then((res) => {
        this.dropDownOptional.stockQuantity = res.data.stockQuantity;
        if (res.data.stockQuantity > this.leastQuantity && this.dataFrom.quantity > res.data.stockQuantity) {
          this.dataFrom.quantity = res.data.stockQuantity;
        }
        if (res.data.stockQuantity < this.leastQuantity) {
          this.dataFrom.quantity = this.leastQuantity;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.custom {
  .fx {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    position: relative;
    margin: 8px 0;
  }

  .fl {
    display: flex;
    align-items: center;
    position: relative;
  }

  .selectBox {
    ::v-deep .el-popper {
      left: 0 !important;
    }

    .el-scrollbar__view li {
      width: 178px;
    }
  }

  .selectBoxWatch {
    .el-scrollbar__view li {
      min-width: 90px;
    }
  }
  .inchesFix {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .inchesTips {
    color: red;
    font-size: 12px;
    margin-left: 4px;
  }
  .material {
    width: 800px;
    ::v-deep .el-radio-button__inner {
      min-width: 65px;
    }
  }
  .title {
    font-size: 18px;
    color: #000;
    display: inline-block;
    margin-bottom: 8px;
    .hidden {
      visibility: hidden;
    }
  }

  .value-font {
    font-size: 18px;
    color: #ec9368;
  }
  .mainStoneClssBoxFx {
    width: 675px;
    display: flex;
    align-items: center;
    position: relative;
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #fff;
    background-color: #ec9368;
    border-color: #ec9368;
    box-shadow: none;
  }
  ::v-deep .el-radio-button__inner:hover {
    color: rgb(236, 147, 104);
  }
  ::v-deep .el-radio-button__inner {
    margin: 5px;
    border-left: 1px solid #dcdfe6;
    border-radius: 4px;
  }
  ::v-deep .inchesFix:first-child .el-radio-button__inner {
    margin-left: 0;
  }
  ::v-deep .el-radio-group {
    display: flex;
    flex-wrap: wrap;
  }
  .input-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
}
</style>
