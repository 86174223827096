<template>
  <div class="back">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
      <div class="login animate__animated animate__bounceInDown">
        <!-- <el-card class="box-card">
          <div class="cardText"> 自2023-05-26 6:00 起，原云展厅账号将无法使用，<span class="cardColrd">加盟门店请使用云平台老板或店长账号密码登陆，总部员工（业务、展厅）请使用运营平台账号密码登陆</span>，如有任何疑问，可联系周六福总部信息技术部曹亚虎（手机：18145838124，总部座机：221）、马博（手机：17727985221、座机：213），谢谢！</div>
        </el-card> -->
        <img class="zlfLogo" src="../Img/login/logo.png" alt />
        <div class="login_title">
          <p>Hi~欢迎来到周六福珠宝云展厅</p>
        </div>
        <div class="user">
          <p class="usersize">用户登录</p>
          <el-form-item prop="username">
            <el-input
              prefix-icon="el-icon-user"
              v-model="ruleForm.username"
              :readonly="false"
              placeholder="请输入账号"
              @focus="handlerIptClick"
              class="data"
              @keyup.enter.native="loginuser()"
            ></el-input>
          </el-form-item>
        </div>
        <div class="messg">
          <el-col :span="24">
            <el-form-item prop="password">
              <el-input
                prefix-icon="el-icon-lock"
                v-model="ruleForm.password"
                placeholder="请输入密码"
                class="data"
                :type="passw"
                @keyup.enter.native="loginuser()"
                ><i slot="suffix" :class="icon" @click="showPass"></i
              ></el-input>
            </el-form-item>
          </el-col>
        </div>
        <div class="loginUser">
          <el-button @click="loginuser" class="user-button_login">
            <p class="loginsize">登录</p>
          </el-button>
        </div>
        <div class="loginTips">
          (如有账号不能登录问题，请联系信息部，4008608388转分机221)
        </div>
        <div class="visitor-button">
          <el-button type="text" @click="loginVisitor"> 游客登录 </el-button>
        </div>
      </div>
    </el-form>
    <!-- 备案信息 -->
    <div class="copyright-text">
      <div class="copyright-item">
        <a :underline="false" href="https://www.xiaoyudi.cn/" target="_blank">
          技术支持：深圳市小雨滴信息技术有限公司
        </a>
      </div>
      <div class="copyright-item">
        <p>COPYRIGHT © 2014 ZLF 周六福珠宝股份有限公司 ALL RIGHTS RESERVED.</p>
        <a :underline="false" href="https://beian.miit.gov.cn/" target="_blank">
          粤ICP备13087551号
        </a>
      </div>
      <div class="copyright-item">
        <img src="@/Img/record.png" />
        <a :underline="false" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030302001449" target="_blank">
          粤公网安备 44030302001449号
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ls from '@/utils/localStorage';
export default {
  inject: ['reload'],
  data() {
    return {
      captcha: null,
      input: '',
      ruleForm: {
        username: '',
        password: '',
        code: '',
        device_type: 1, // 加盟商登录传值1；内部员工登录双击周六福logo传值时间戳
        randomStr: 'blockPuzzle',
      },
      //用于改变Input类型
      passw: 'password',
      //用于更换Input中的图标
      icon: 'el-icon-hk-yincang',
      rules: {
        username: [
          {
            required: true,
            message: '账号不能为空',
            trigger: 'blur',
          },
        ],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
        code: [{ required: true, message: '验证码不能为空', trigger: 'blur' }],
      },
    };
  },
  mounted() {},
  beforeRouteLeave(to, from, next) {
    // 设置下一个路由的 meta
    this.$route.meta.keepAlive = true;
    this.$route.meta.flag = true;
    next();
  },
  methods: {
    //密码的隐藏和显示
    showPass() {
      //点击图标是密码隐藏或显示
      if (this.passw == 'text') {
        this.passw = 'password';
        //更换图标
        this.icon = 'el-icon-hk-yincang';
      } else {
        this.passw = 'text';
        this.icon = 'el-input__icon el-icon-view';
      }
    },
    // 解决自动填充问题
    handlerIptClick() {
      this.ruleForm.readonly = false;
    },
    // getcaptchaImg () {
    //   this.$axios
    //     .get("/captcha/captchaImage?type=math2", {
    //       responseType: "arraybuffer"
    //     })
    //     .then(res => {
    //       this.captcha =
    //         "data:image/png;base64," +
    //         btoa(
    //           new Uint8Array(res.data).reduce(
    //             (data, byte) => data + String.fromCharCode(byte),
    //             ""
    //           )
    //         )
    //     })
    // },
    loginuser() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$store.dispatch('LoginByUsername', this.ruleForm).then((userInfo) => {
            if (userInfo?.siteId) ls.set('siteId', userInfo.siteId);
            if (ls.get('coludQuery')) {
              return this.$router.push({
                path:
                  ls.get('coludQuery').type == '1'
                    ? '/order'
                    : ls.get('coludQuery').type === '2'
                    ? '/plaingold'
                    : ls.get('coludQuery').type === '3'
                    ? '/watch'
                    : ls.get('coludQuery').type == '4'
                    ? '/goldsetting'
                    : '',
                query: ls.get('coludQuery'),
              });
            }
            this.$router.replace({ path: '/' });
          });
        }
      });
    },
    // 游客登录
    loginVisitor() {
      const params = {
        username: '18888888888',
        password: '1123.com',
        code: '',
        device_type: 1,
        randomStr: 'blockPuzzle',
      };
      this.$store.dispatch('LoginByUsername', params).then(() => {
        this.$router.replace({ path: '/' });
      });
    },
  },
};
</script>
<style>
.el-icon-hk-yincang {
  height: 100%;
  width: 25px;
  text-align: center;
  transition: all 0.3s;
  line-height: 40px;
  cursor: pointer;
}
.el-icon-view {
  cursor: pointer;
}
.login .el-input__inner {
  border-radius: 0 !important;
}
.login .el-input__inner::placeholder {
  padding-left: 10px;
  color: #999;
  font-size: 14px !important;
}
.el-form-item {
  margin-bottom: 0 !important;
}
.login .el-button {
  width: 326px;
  height: 40px;
  background: #ec9368;
}
.login .el-button:focus,
.login .el-button:hover {
  width: 326px;
  height: 40px;
  background: #ec9368;
  border: #ec9368;
}
</style>
<style scope lang="scss">
.box-card {
  width: 580px;
  position: absolute;
  top: -32%;
  left: -24%;
  .cardText {
    font-size: 14px;
    text-indent: 20px;
    line-height: 20px;
  }
  .cardColrd {
    color: red;
  }
}
.loginsize {
  font-size: 14px;
  color: #ffffff;
  letter-spacing: -0.34px;
  text-align: center;
}
.usersize {
  font-size: 14px;
  color: #000000;
  letter-spacing: -0.34px;
  margin-bottom: 16px;
}
.data {
  width: 326px !important;
  height: 40px !important;
}
.zlfLogo {
  width: 106px;
  height: 48px;
  display: block;
  margin: 0 auto;
  margin-top: 32px;
  margin-bottom: 16px;
}
.login {
  width: 390px;
  position: absolute;
  top: 50%;
  left: 80%;
  margin-left: -250px;
  margin-top: -218px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(236, 147, 104, 0.1);
  border-radius: 8px;
}
.login_title {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #b99145;
  letter-spacing: -0.39px;
  text-align: center;
}
.user {
  font-size: 18px;
  color: #000;
  margin-left: 32px;
  padding-top: 34px;
}
.messg {
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
  width: 388px;
  height: 40px;
  padding-left: 32px;
}
.loginUser {
  width: 326px;
  height: 40px;
  margin: 30px 32px 0 32px;
}
.loginTips {
  color: #f56c6c;
  font-size: 12px;
  margin: 10px 0 30px 0;
  text-align: center;
}
.back {
  width: 100%;
  height: 100vh;
  background: url('../Img/login/pc_bg.png') no-repeat;
  background-size: cover;
  margin: 0;
}
.copyright-text {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  bottom: 32px;
  width: 100%;

  .copyright-item {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 32px;
    a {
      font-size: 14px;
      color: #606266;
    }
    p {
      margin-right: 5px;
      font-size: 14px;
      color: #606266;
    }
    img {
      margin-right: 5px;
    }
  }
}
.visitor-button {
  margin: auto;
  padding: 0 0 20px 0;
  text-align: center;
  .el-button {
    width: 160px;
    height: 32px;
    line-height: 6px;
    color: #ec9368;
    background-color: #fff;
    border: 1px solid #ec9368;
  }
}
.visitor-button .el-button:focus,
.visitor-button .el-button:hover {
  width: 160px;
  height: 32px;
  background: #fff;
  border: 1px solid #ec9368;
}
</style>
