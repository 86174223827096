<template>
  <div class="ordinaryModify">
    <el-dialog
      :popper-append-to-body="false"
      center
      :title="'手表现货列表' + '(' + modifyingReplication.styleNumber + ')'"
      :close-on-click-modal="false"
      :visible.sync="watchSpotDialogVisible"
      :before-close="handleClose"
    >
      <el-table
        :data="gridData"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        class="tableData"
        style="width: 100%"
      >
        <el-table-column property="mechanicalType" label="类型"></el-table-column>
        <el-table-column property="gender" label="款式"></el-table-column>
        <el-table-column property="goldWeigh" label="金重"></el-table-column>
        <el-table-column property="" label="情侣款">
          <template slot-scope="scope">
            <span>{{ scope.row.hasOwnProperty('couplesNo') ? '是' : '否' }}</span>
          </template>
        </el-table-column>
        <el-table-column property="dialColor" label="表盘颜色"></el-table-column>
        <el-table-column property="bandMaterial" label="表带材质"></el-table-column>
        <el-table-column property="bandColor" label="表带颜色"></el-table-column>
        <el-table-column property="accessoryStone" label="副石配宝石"></el-table-column>
        <el-table-column property="tradePrice" v-if="!hasPermission('展厅') || !hasPermission('加盟公司总经理')" label="批发价格"></el-table-column>
        <el-table-column property="labelPrice" label="标签价格"></el-table-column>
      </el-table>
      <div class="pageClass">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :current-page="listQuery.current"
          :page-size="listQuery.size"
          @current-change="cartSpotListCurrentPage($event)"
        ></el-pagination>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getSpot } from '@/api/orderApi';
import { cutFilter } from '@/utils/filetrs';
export default {
  props: {
    watchSpotDialogVisible: Boolean,
    modifyingReplication: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      listQuery: {
        id: '',
        current: 1,
        size: 5,
        styleNumber: this.modifyingReplication.styleNumber,
      },
      total: 0,
      gridData: [],
    };
  },
  filters: {
    cutFilter,
  },
  mounted() {
    this.getSpotList();
  },
  methods: {
    // 获取列表数据
    getSpotList() {
      this.listQuery.id = this.modifyingReplication.id;
      getSpot(this.listQuery).then((res) => {
        this.gridData = [];
        res.data.records.forEach((item) => {
          this.gridData.push(item);
        });
        if (res.data.records?.length == 0) {
          this.$emit('onCloseSelectDialog');
          // 修改购物车数据
          this.$emit('updataCart');
        }
        this.total = res.data.total;
      });
    },
    // 分页
    cartSpotListCurrentPage(ev) {
      this.listQuery.current = ev;
      this.getSpotList();
    },
    // 关闭弹窗
    handleClose() {
      this.$emit('onCloseSelectDialog');
    },
  },
};
</script>

<style scoped lang="scss">
.ordinaryModify {
  /deep/ .el-dialog {
    width: 1500px;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    padding: 0 10px 10px 10px;
  }

  /deep/ .el-table .cell {
    padding: 0;
  }

  .el-button {
    width: 100%;
  }

  .el-button--text {
    color: red;
  }

  .pageClass {
    text-align: center;
    padding: 20px 0;
    background: #fff;
  }

  /deep/ .el-pager li.active + li {
    border-left: none;
  }
}
</style>
