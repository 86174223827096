import { render, staticRenderFns } from "./UpdatePlainGold.vue?vue&type=template&id=9384fd88&scoped=true"
import script from "./UpdatePlainGold.vue?vue&type=script&lang=js"
export * from "./UpdatePlainGold.vue?vue&type=script&lang=js"
import style0 from "./UpdatePlainGold.vue?vue&type=style&index=0&id=9384fd88&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9384fd88",
  null
  
)

export default component.exports