import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './assets/icons'; // svg

import store from './store';
import 'normalize.css/normalize.css';
import '@/permission';
import 'default-passive-events';
import VueClipBoard from 'vue-clipboard2';

import ElementUi from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../node_modules/element-ui/lib/theme-chalk/index.css';
import 'animate.css';
import domMessage from '../messageOnce.js';
// new 对象实例
const messageOnce = new domMessage();
import './assets/icon/iconfont.css';

import Router from 'vue-router';
import cjyMagnify from 'vue-cjy-magnify';
Vue.component('cjy-magnify', cjyMagnify);
var bus = new Vue();
export default bus;
Vue.use(ElementUi);

/**
 * 判断价格权限的全局函数，使用场景为按钮禁用功能，故返回值取反
 * @param {String} action 按钮权限，多个用逗号拼接
 * @returns {Boolean}
 */
Vue.prototype.hasPermission = (action) => {
  if (!action) {
    throw new Error('按钮权限写法有误，请检查');
  }
  // 多个用逗号拼接，考虑暂无场景使用，暂时注释
  // const actions = action.split(',')
  // let hasIntersection = store.getters.permissions.filter(v => actions.indexOf(v) > -1)
  // return !hasIntersection.length
  return !store.getters.permissions.includes(action);
};
/**
 * 判断是年轻空间还是周六福的全局函数，使用场景为字段展示隐藏功能
 * @returns {Boolean}
 */
Vue.prototype.hasMainBodyType = () => {
  return store.getters.mainBodyType;
};
const on = Vue.prototype.$on;
// 函数节流
Vue.prototype.$on = function(event, func) {
  let previous = 0;
  let newFunc = func;
  if (event === 'click') {
    newFunc = function() {
      const now = new Date().getTime();
      if (previous + 1000 <= now) {
        func.apply(this, arguments);
        previous = now;
      }
    };
  }
  on.call(this, event, newFunc);
};

router.beforeEach((to, from, next) => {
  Vue.prototype.beforeRouter = from;
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
  next();
});
Vue.use(VueClipBoard);
new Vue({
  router,
  store,
  render: function(h) {
    return h(App);
  },
}).$mount('#app');
