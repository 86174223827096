<template>
  <div class="order">
    <appheader index="2"></appheader>
    <Search @searchOrder="searchOrder" :CommodityArr="CommodityArr" />
    <div class="orderContent">
      <!-- 订单列表 -->
      <div v-if="orderNoListFlag">
        <div class="orderHeader" :class="navBarFixed == true ? 'navBarWrap' : ''">
          <div class="goods">商品</div>
          <div class="orderSpecifications">规格</div>
          <div class="orderLabel">金额</div>
          <div class="ordrerNumber">数量</div>
          <!-- <div class="orderLabel" v-if="!hasPermission('展厅') || !hasPermission('加盟公司总经理')">批发金额</div> -->
          <div class="orderReamrk">商品备注</div>
          <div class="orderReamrk">订单备注</div>
          <div class="orderTotal">合计</div>
          <div class="ordrerNumber">待发数量</div>
          <div class="ordrerStatus">订单状态</div>
          <div class="ordrerStatus">操作</div>
        </div>
        <div v-for="(item, index) in orderList" :key="index" class="subject">
          <Diamond @showHidden="showHidden" v-if="item.detailList[0].classify == '2'" :pieceData="item" :index="index" @fetchExpress="fetchExpress" />
          <GoldWatch
            @showHidden="showHidden"
            v-if="item.detailList[0].classify == '1' || item.detailList[0].classify == '3'"
            :pieceData="item"
            :index="index"
            @fetchExpress="fetchExpress"
          />
          <Goldsetting
            @showHidden="showHidden"
            v-if="item.detailList[0].classify === '4'"
            :pieceData="item"
            :index="index"
            @fetchExpress="fetchExpress"
          />
        </div>
        <!-- 分页组件 -->
        <div class="box_fenye">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="listQuery.current"
            :page-sizes="[10, 20, 40, 60, 100]"
            :page-size="listQuery.size"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- 物流信息 -->
      <Express
        v-if="expressDialogVisible"
        :expressDialogVisible="expressDialogVisible"
        :expressData="expressData"
        @onCloseSelectDialog="onCloseSelectDialog"
      />
      <div v-if="!orderNoListFlag" style="margin: 159px 0;text-align: center;">
        <img src="@/Img/noData.png" alt="" />
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import Appheader from '../moudel/Appheader';
import bottom from '../bady_bottom/bottom';
import Search from './components/Search.vue';
import Diamond from './components/Diamond.vue';
import GoldWatch from './components/GoldWatch.vue';
import Goldsetting from './components/Goldsetting.vue';
import Express from './components/Express.vue';
import { getList, getState, getExpress, cancelOrder, verifyOrder } from '@/api/orderApi';
export default {
  name: 'Order',
  components: {
    Appheader,
    bottom,
    Search,
    Diamond,
    GoldWatch,
    Goldsetting,
    Express,
  },
  data() {
    return {
      orderNoListFlag: true,
      navBarFixed: false,
      CommodityArr: [
        {
          title: '全部',
          status: '',
          badge: '',
        },
        {
          title: '待受理',
          status: '0',
          badge: '',
        },
        {
          title: '正在生产',
          status: '1',
          badge: '',
        },
        {
          title: '配货中',
          status: '2',
          badge: '',
        },
        {
          title: '待付款',
          status: '3',
          badge: '',
        },
        {
          title: '待发货',
          status: '5',
          badge: '',
        },
        {
          title: '待收货',
          status: '6',
          badge: '',
        },
        {
          title: '待自提',
          status: '4',
          badge: '',
        },
        {
          title: '已完成',
          status: '7',
          badge: '',
        },
        {
          title: '已取消',
          status: '8',
          badge: '',
        },
      ],
      listQuery: {
        size: 10,
        current: 1,
        styleNumber: '',
        orderNo: '',
        netAddress: '',
        netCode: '',
        orderStatus: '',
      },
      orderList: [],
      badge: {},
      total: 0,
      expressDialogVisible: false,
      expressData: {},
    };
  },
  mounted() {
    // 订单列表
    this.getOrderList();
    // 订单未读数量查询
    this.getCustomerCode();
    // 事件监听滚动条
    window.addEventListener('scroll', this.watchScroll);
  },
  methods: {
    onCloseSelectDialog() {
      this.expressDialogVisible = false;
    },
    // 获取物流
    fetchExpress(type, orderNo) {
      let obj = {
        orderNo: orderNo,
      };
      if (type == 'view') {
        getExpress(obj).then((res) => {
          if (!res.data) {
            this.$message({
              message: '暂无物流信息!',
              type: 'warning',
              duration: 1500,
            });
          } else {
            this.expressData = res.data;
            this.expressDialogVisible = true;
          }
        });
      } else if (type == 'cancel') {
        this.$confirm('确定取消订单嘛？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            cancelOrder(obj).then((res) => {
              this.$message({
                message: '取消成功!',
                type: 'success',
                duration: 1500,
              });
              this.fetchList();
            });
          })
          .catch(() => {});
      } else if (type == 'verify') {
        this.$confirm('确定收货？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            verifyOrder(obj).then((res) => {
              this.$message({
                message: '操作成功!',
                type: 'success',
                duration: 1500,
              });
              this.fetchList();
            });
          })
          .catch(() => {});
      }
    },
    fetchList() {
      // 订单列表
      this.getOrderList();
      // 订单未读数量查询
      this.getCustomerCode();
    },
    // 监听滚动
    watchScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      //  当滚动超过 50 时，实现吸顶效果
      if (scrollTop > 289) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    // 点击搜索
    searchOrder(searchCondition, status) {
      this.listQuery.current = 1;
      this.listQuery = { ...this.listQuery, ...searchCondition };
      this.listQuery.orderStatus = status;
      this.getOrderList(status);
    },
    // 获取角标数量
    getCustomerCode() {
      const type = 'get';
      getState(type).then((res) => {
        // 未读角标
        this.badge = res.data;
        this.$nextTick(() => {
          let isEmpty = (obj) => (JSON.stringify(obj) === '{}' ? true : false);
          this.CommodityArr.forEach((ele) => {
            if (isEmpty(this.badge)) {
              ele.badge = '';
            } else {
              for (var i in this.badge) {
                if (i == ele.status) {
                  ele.badge = this.badge[i];
                } else {
                  ele.badge = '';
                }
              }
            }
          });
        });
      });
    },
    // 获取已读角标
    getUnread() {
      let obj = {
        ids: [],
      };
      const type = 'PUT';
      this.orderList.forEach((item) => {
        obj.ids.push(item.id);
      });
      getState(obj.ids, type).then((res) => {
        this.getCustomerCode();
      });
    },
    // 获取列表
    getOrderList(status) {
      getList(this.listQuery, 1).then((res) => {
        if (res.code === 0) {
          this.orderList = [];
          res.data.records.forEach((item) => {
            if (item.detailList.length > 1) {
              item.showNone = true;
            } else {
              item.showNone = false;
            }
            this.orderList.push(item);
          });
          if (res.data.total > 0) {
            this.orderNoListFlag = true;
          } else {
            this.orderNoListFlag = false;
          }
          this.total = res.data.total;
          // 点击标题改变角标数量
          if (status) {
            // this.getUnread();
          }
        }
      });
    },
    // 点击展示隐藏
    showHidden(index) {
      this.orderList[index].showNone = !this.orderList[index].showNone;
    },
    // 分页组件切换页数
    handleSizeChange(val) {
      this.listQuery.size = val;
      this.getOrderList();
    },
    // 外层分页组件切换
    handleCurrentChange(val) {
      this.listQuery.current = val;
      this.getOrderList();
    },
  },
  // 退出界面清除监听
  beforeDestroy() {
    window.removeEventListener('scroll', this.watchScroll);
  },
};
</script>

<style scoped lang="scss">
.order {
  .orderContent {
    max-width: 1500px;
    margin: 0 auto;
    min-height: 700px;

    .subject {
      margin-bottom: 12px;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
    }
  }
  .styleNumber-text {
    white-space: normal;
    padding-right: 10px;
  }
}

.orderHeader {
  display: flex;
  align-items: center;
  height: 50px;
  background: rgba(242, 242, 242, 1);
  padding-left: 6px;
}

.navBarWrap {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 999;
  max-width: 1494px;
}

.goods {
  display: flex;
  width: 230px;
}

.orderSpecifications {
  width: 270px;
  padding-right: 10px;
}

.orderLabel {
  width: 150px;
}

.ordrerNumber {
  width: 80px;
}
.ordrerStatus {
  width: 100px;
}

.orderReamrk {
  width: 100px;
}
.orderTotal {
  width: 210px;
}
.goodsRemark {
  width: 150px;
}

.orderAmount {
  width: 150px;
  margin-left: 20px;
}

.orderType {
  width: 80px;
  white-space: break-spaces;
}

.right {
  border-right: none;
}
/* 表格数据为空时显示——*/
.tableData :empty::before {
  content: '-';
}
</style>
<style>
.demo-tabletable-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

.box_fenye {
  max-width: 1500px;
  width: 100%;
  margin: 0 auto;
  margin-top: 68px;
  text-align: center;
  position: relative;
}

.box_fenye .el-pagination__total {
  line-height: 42px !important;
  color: #ec9368;
  font-size: 16px !important;
}

.box_fenye .el-pagination__jump {
  color: #ec9368;
  font-size: 16px;
}

.box_fenye .el-pagination__jump .el-input__inner {
  height: 42px !important;
  width: 40px;
}

.el-scrollbar__view .selected {
  color: #cdcdcd;
}

.el-scrollbar__view li {
  color: #ec9368;
}

.box_fenye .el-input__inner {
  width: 102px;
  height: 42px !important;
  border: 1px solid #cdcdcd;
  color: #ec9368;
  font-size: 16px !important;
}

.box_fenye .btn-next i::before {
  color: #ec9368;
  font-size: 16px;
  line-height: 42px;
}

.box_fenye .btn-prev i::before {
  color: #ec9368;
  font-size: 16px;
  line-height: 42px;
}

.box_fenye .active {
  color: #fff !important;
  background-color: #ec9368;
}

.box_fenye .el-pager li {
  color: #ec9368;
  width: 42px;
  height: 42px;
  line-height: 42px;
  font-size: 16px;
}

.box_fenye .el-pager li:hover {
  color: #fff;
  background-color: #ec9368;
}

.box_fenye .number {
  border: 1px solid #cdcdcd;
  margin: 0 2px;
}
</style>
