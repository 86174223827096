<template>
  <div class="search">
    <div class="searchContent">
      <h3 class="title">
        我的订单
      </h3>
      <div class="searchInput">
        <div class="searchInput_child">
          <div>订单编号：</div>
          <div class="orderSearch">
            <el-input placeholder="请输入订单编号" v-model="searchCondition.orderNo" @keyup.enter.native="searchOrder()"></el-input>
          </div>
        </div>
        <div class="searchInput_child">
          <div>网点名称：</div>
          <div class="orderSearch">
            <el-input placeholder="请输入网点名称" v-model="searchCondition.netAddress" @keyup.enter.native="searchOrder()"></el-input>
          </div>
        </div>
        <div class="searchInput_child">
          <div>网点编码：</div>
          <div class="orderSearch">
            <el-input placeholder="请输入网点编码" v-model="searchCondition.netCode" @keyup.enter.native="searchOrder()"></el-input>
          </div>
        </div>
        <el-button class="mg" @click="searchOrder">搜 索</el-button>
        <el-button class="mg" @click="clear">重 置</el-button>
      </div>
      <div class="angle">
        <div
          v-for="(item, index) in CommodityArr"
          :key="index"
          v-if="
            (item.title === '全部' || item.title === '待支付' || item.title === '配货中' || item.title === '已出货') &&
              hasMainBodyType()[0].value === '2'
          "
        >
          <el-badge :value="item.badge" class="item">
            <span @click="getTitle(index, item)" :class="index === activeIndex ? 'isActive' : ''">{{ item.title }}</span>
          </el-badge>
        </div>
        <div v-for="(item, index) in CommodityArr" :key="index" v-if="hasMainBodyType()[0].value !== '2'">
          <el-badge :value="item.badge" class="item">
            <span @click="getTitle(index, item)" :class="index === activeIndex ? 'isActive' : ''">{{ item.title }}</span>
          </el-badge>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Search',
  props: {
    CommodityArr: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      searchCondition: {
        orderNo: '',
        netAddress: '',
        netCode: '',
      },
      badge: {},
      activeIndex: 0,
      status: '',
    };
  },

  mounted() {},
  methods: {
    // 订单搜索
    searchOrder() {
      this.$emit('searchOrder', this.searchCondition, this.status);
    },
    // 重置
    clear() {
      this.searchCondition = {
        orderNo: '',
        netAddress: '',
        netCode: '',
      };
      this.$emit('searchOrder', this.searchCondition, '');
    },
    // 点击title元素显示选中效果
    getTitle(i, e) {
      this.searchCondition = {
        orderNo: '',
        netAddress: '',
        netCode: '',
      };
      this.activeIndex = i;
      this.status = e.status;
      this.$emit('searchOrder', this.searchCondition, e.status);
    },
  },
};
</script>

<style scoped lang="scss">
.search {
  .searchContent {
    max-width: 1500px;
    margin: 0 auto;

    .title {
      font-size: 28px;
      font-weight: 500;
      line-height: 33.6px;
      margin-top: 20px;
    }

    .searchInput {
      display: flex;
      align-items: center;
      margin-top: 20px;
    }

    .searchInput_child {
      display: flex;
      align-items: center;
      font-size: 14px;
    }

    /deep/ .searchInput .el-button:hover {
      border-radius: none;
      border: 1px solid #ec9368;
      background: #ec9368;
      color: #fff;
    }

    /deep/ .searchInput .el-button:focus {
      background: #ec9368;
      border: 1px solid #ec9368;
      color: #fff;
    }

    /deep/ .searchInput .el-button {
      width: 70px;
      padding: 0;
      color: #fff;
      background: #ec9368;
      display: inline-block;
      height: 35px;
      border-radius: none;
      font-size: 14px;
    }

    .angle {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 20px 0 20px 0;
    }

    .angle div {
      display: inline-block;
      cursor: pointer;
      margin-right: 30px;
    }

    .isActive {
      color: #ed9970;
    }

    /deep/ .orderSearch .el-input__inner {
      border-radius: 24px !important;
      border: 1px solid #ec9368 !important;
    }

    /deep/ .orderSearch .el-input__inner {
      border-radius: 24px !important;
      height: 35px !important;
      line-height: 35px !important;
    }

    .orderSearch {
      margin-right: 10px;
    }
  }
}
</style>
