<template>
  <div class="ordinaryModify custom">
    <el-dialog :popper-append-to-body="false" :close-on-click-modal="false" :visible.sync="plainGoldSelectDialogVisible" :before-close="handleClose">
      <div class="ordinaryModifyChild">
        <div class="secBox">
          <div class="child" v-if="lcTechnologList && lcTechnologList.length > 0">
            <div class="selb">
              <span class="selb-title">鎏彩工艺：</span>
              <div class="material selectBox">
                <el-radio-group v-model="amendDuplication.lcTechnology" size="small" @change="getSizeRange($event, 'lcTechnology')">
                  <span class="inchesFix">
                    <el-radio-button v-for="item in lcTechnologList" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
                  </span>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="child" v-if="goldenWeighList && goldenWeighList.length > 0">
            <div class="selb">
              <span class="selb-title">金重：</span>
              <div class="material selectBox">
                <el-radio-group v-model="amendDuplication.goldWeigh" size="small" @change="getSizeRange($event, 'goldWeigh')">
                  <span class="inchesFix">
                    <el-radio-button v-for="item in goldenWeighList" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
                  </span>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="child" v-if="handSizeList && handSizeList.length > 0">
            <div class="selb">
              <span class="inchesTips-size" v-if="handSizeList && handSizeList[0].includes('寸')">(1英寸＝2.54厘米)</span>
              <span class="selb-title">规格：</span>
              <div class="material selectBox">
                <el-radio-group v-model="amendDuplication.ringSize" size="small" @change="getSizeRange($event, 'ringSize')">
                  <span class="inchesFix">
                    <el-radio-button v-for="item in handSizeList" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
                  </span>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="child">
            <div class="selb">
              <span class="selb-title">商品数量：</span>
              <el-input-number
                :min="product.leastQuantity"
                :max="amendDuplication.spotFlag == 2 ? 9999 : optionsList.stockQuantity"
                v-model="amendDuplication.quantity"
              ></el-input-number>
              <span class="inchesTips" v-if="amendDuplication.spotFlag == 1">库存：{{ optionsList.stockQuantity || 0 }}件</span>
            </div>
          </div>
          <div class="child remorkBox">
            <div class="selb">
              <span class="selb-title">备注：</span>
              <el-input type="textarea" :autosize="{ minRows: 3 }" placeholder="请输入内容" v-model="amendDuplication.productRemark"> </el-input>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="btnFlag" class="modifyCart" @click.stop="updateCpoyCart('修改')">确定修改</el-button>
        <!-- <el-button :loading="btnFlag" class="copyCart" @click.stop="updateCpoyCart('复制')">新增商品</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getOptional, getPriceRangeSpot } from '@/api/detailsApi';
import { modifyCartPublic } from '@/api/cartApi';
import { deepClone } from '@/utils/util';
import { debounce } from '@/utils/index';
import ls from '@/utils/localStorage';
export default {
  props: {
    plainGoldSelectDialogVisible: Boolean,
    modifyingReplication: {
      type: Object,
      default() {
        return {};
      },
    },
    product: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      optionsList: {},
      amendDuplication: {},
      btnFlag: false,
      goldenWeighList: [],
      handSizeList: [],
      lcTechnologList: [],
      lcTechnologyIndex: 0,
      goldWeighIndex: 0,
    };
  },
  mounted() {
    this.amendDuplication = deepClone(this.modifyingReplication);
    this.getOptional();
  },
  methods: {
    // 金重排序
    mainStoneSort(list) {
      // 1. 将每个区间字符串解析为对象
      const parsedRanges = list?.map((range) => {
        const [start, end] = range.split('-').map(parseFloat);
        return { start, end, range }; // 保留原始字符串以便最后恢复
      });
      // 2. 根据 start 值进行排序
      parsedRanges.sort((a, b) => a.start - b.start);
      // 3. 提取排序后的原始字符串
      return parsedRanges.map((item) => item.range);
    },
    // 规格重新排序
    sortFun(handSizeList) {
      return handSizeList?.sort(function(a, b) {
        return a - b;
      });
    },
    // 获取可选属性
    getOptional() {
      let obj = {
        styleNumber: this.amendDuplication.styleNumber,
        gender: this.amendDuplication.gender,
        classify: this.amendDuplication.classify,
        spotFlag: this.amendDuplication.spotFlag,
        cartId: this.amendDuplication.id,
        requestFrom: 1,
        sequence: this.amendDuplication.sequence,
      };
      getOptional(obj).then((res) => {
        this.optionsList = { ...this.optionsList, ...res.data };
        if (this.amendDuplication.isCouples == 2) {
          if (this.optionsList.lcTechnologyFlag == 1) {
            this.lcTechnologList = Object.keys(res.data.handSizeMap);
            this.lcTechnologyIndex = this.lcTechnologList.indexOf(this.amendDuplication.lcTechnology);
            this.goldenWeighList = this.mainStoneSort(Object.keys(Object.values(res.data.handSizeMap)[this.lcTechnologyIndex]));
          } else {
            this.goldenWeighList = this.mainStoneSort(Object.keys(res.data.handSizeMap));
          }
        } else {
          if (this.optionsList.lcTechnologyFlag == 1) {
            this.lcTechnologList = Object.keys(this.optionsList.couplesMap[this.amendDuplication.gender]);
            this.lcTechnologyIndex = this.lcTechnologList.indexOf(this.amendDuplication.lcTechnology);
            this.goldenWeighList = this.mainStoneSort(
              Object.keys(Object.values(this.optionsList.couplesMap[this.amendDuplication.gender])[this.lcTechnologyIndex])
            );
          } else {
            this.goldenWeighList = this.mainStoneSort(Object.keys(res.data.couplesMap[this.amendDuplication.gender]));
          }
        }
        if (this.amendDuplication.spotFlag == 1) this.getPriceRange();
        this.goldenWeighList.forEach((res, index) => {
          if (this.amendDuplication.goldWeigh?.indexOf('-') > 0) {
            if (
              res?.indexOf('-') > 0 &&
              Number(res.split('-')[0]) <= Number(this.amendDuplication.goldWeigh.split('-')[0]) &&
              Number(res.split('-')[1]) >= Number(this.amendDuplication.goldWeigh.split('-')[0])
            ) {
              this.goldWeighIndex = index;
              return (this.amendDuplication.goldWeigh = res);
            }
          } else {
            if (
              res?.indexOf('-') > 0 &&
              Number(res.split('-')[0]) <= Number(this.amendDuplication.goldWeigh) &&
              Number(res.split('-')[1]) >= Number(this.amendDuplication.goldWeigh)
            ) {
              this.goldWeighIndex = index;
              return (this.amendDuplication.goldWeigh = res);
            }
          }
        });
        if (this.amendDuplication.isCouples == 2) {
          if (this.optionsList.lcTechnologyFlag == 1) {
            this.handSizeList = this.sortFun(
              Object.values(Object.values(Object.values(res.data.handSizeMap)[this.lcTechnologyIndex]))[this.goldWeighIndex]
            );
          } else {
            this.handSizeList = this.sortFun(Object.values(res.data.handSizeMap)[this.goldWeighIndex]);
          }
        } else {
          if (this.optionsList.lcTechnologyFlag == 1) {
            this.handSizeList = this.sortFun(
              Object.values(Object.values(this.optionsList.couplesMap[this.amendDuplication.gender])[this.lcTechnologyIndex])[this.goldWeighIndex]
            );
          } else {
            this.handSizeList = this.sortFun(Object.values(res.data.couplesMap[this.amendDuplication.gender])[this.goldWeighIndex]);
          }
        }
      });
    },
    getSizeRange(value, type) {
      this.amendDuplication[type] = value;
      if (this.optionsList.lcTechnologyFlag == 1) {
        if (type === 'lcTechnology') {
          this.lcTechnologyIndex = this.lcTechnologList.indexOf(value);
          if (this.amendDuplication.isCouples == 2) {
            this.goldenWeighList = this.mainStoneSort(Object.keys(Object.values(this.optionsList.handSizeMap)[this.lcTechnologyIndex]));
            this.handSizeList = this.sortFun(
              Object.values(Object.values(Object.values(this.optionsList.handSizeMap)[this.lcTechnologyIndex]))[this.goldWeighIndex]
            );
          } else {
            this.goldenWeighList = this.mainStoneSort(
              Object.keys(Object.values(this.optionsList.couplesMap[this.amendDuplication.gender])[this.lcTechnologyIndex])
            );
            this.handSizeList = this.sortFun(
              Object.values(Object.values(this.optionsList.couplesMap[this.amendDuplication.gender])[this.lcTechnologyIndex])[this.goldWeighIndex]
            );
          }
          this.amendDuplication.goldWeigh = '';
          this.amendDuplication.ringSize = '';
        }
        if (type === 'goldWeigh') {
          this.goldWeighIndex = this.goldenWeighList.indexOf(value);
          if (this.amendDuplication.isCouples == 2) {
            this.handSizeList = this.sortFun(
              Object.values(Object.values(Object.values(this.optionsList?.handSizeMap)[this.lcTechnologyIndex]))[this.goldWeighIndex]
            );
          } else {
            this.handSizeList = this.sortFun(
              Object.values(Object.values(this.optionsList.couplesMap[this.amendDuplication.gender])[this.lcTechnologyIndex])[this.goldWeighIndex]
            );
          }
          this.amendDuplication.ringSize = '';
        }
      } else {
        if (type === 'goldWeigh') {
          this.goldWeighIndex = this.goldenWeighList.indexOf(value);
          if (this.amendDuplication.isCouples == 2) {
            this.handSizeList = this.sortFun(Object.values(this.optionsList.handSizeMap)[this.goldWeighIndex]);
          } else {
            this.handSizeList = this.sortFun(Object.values(this.optionsList.couplesMap[this.amendDuplication.gender])[this.goldWeighIndex]);
          }
          this.amendDuplication.ringSize = '';
        }
      }
      if (this.amendDuplication.spotFlag == 1) this.getPriceRange();
    },
    // 复制修改商品
    updateCpoyCart(text) {
      let type = '';
      let obj = {};
      if (this.handSizeList.length > 0 && !this.amendDuplication.ringSize) {
        return this.$message({
          message: '请选择规格',
          type: 'warning',
          duration: 500,
        });
      }
      if (!this.amendDuplication.quantity) {
        return this.$message({
          message: '数量不能为0',
          type: 'warning',
          duration: 500,
        });
      }
      if (text === '修改') {
        type = 'put';
      } else {
        obj = {
          productDtoList: [this.amendDuplication],
          shopType: 2,
          type: 2,
          orderPath: 1,
          requestFrom: 1,
          spotFlag: this.amendDuplication.spotFlag,
          purchaseSource: ls.get('coludQuery') ? 0 : 1, //0 云平台产品推荐, 1 其他渠道"
          siteId: ls.get('siteId') || ls.get('userInfo').sysUser?.siteId || '',
        };
        type = 'post';
      }
      modifyCartPublic(text === '修改' ? this.amendDuplication : obj, type).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: text === '修改' ? '修改成功' : '复制成功',
            type: 'success',
            duration: 1500,
          });
          // 修改购物车数据
          this.$emit('updataCart');
          // 关闭弹窗
          this.$emit('onCloseSelectDialog');
        }
      });
    },
    // 实时监听 防抖debounce
    onNative: debounce(
      function(event) {
        if (Number(event.target.value)) {
          if (this.amendDuplication.spotFlag == 2 && event.target.value > 9999) {
            this.amendDuplication.quantity = 9999;
          } else if (this.amendDuplication.spotFlag == 1 && event.target.value > this.optionsList.stockQuantity) {
            this.amendDuplication.quantity = this.optionsList.stockQuantity;
          }
        }
      },
      500,
      true
    ),
    //获取价格范围
    getPriceRange() {
      this.btnFlag = true;
      const obj = deepClone(this.amendDuplication);
      if (!obj.goldWeigh) return;
      delete obj.texture;
      getPriceRangeSpot(obj)
        .then((res) => {
          this.$nextTick(() => {
            this.optionsList.stockQuantity = res.data.stockQuantity;
            this.btnFlag = false;
          });
        })
        .catch(() => {
          this.btnFlag = false;
        });
    },
    handleClose() {
      this.$emit('onCloseSelectDialog');
    },
  },
};
</script>

<style scoped lang="scss">
.ordinaryModify {
  ::v-deep .el-dialog {
    width: 900px;
  }

  ::v-deep .el-dialog__body {
    padding-top: 0;
  }

  .ordinaryModifyChild {
    display: flex;
  }

  ::v-deep .el-select > .el-input {
    width: 300px;
  }

  .secBox {
    width: 100%;
  }

  .remorkBox {
    margin-top: 25px !important;
  }

  .child {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;

    .selb {
      // height: 40px;
      // line-height: 40px;
      display: flex;
      align-items: center;
      position: relative;
      // width: 400px;

      .el-radio-group {
        // margin-left: 20px;
      }

      ::v-deep .el-radio__input.is-checked .el-radio__inner {
        border-color: #ec9368;
        background: #ec9368;
      }

      ::v-deep .el-radio__input.is-checked + .el-radio__label {
        color: #ec9368;
      }

      span {
        font-size: 14px;
        display: inline-block;
        margin-right: 5px;
        min-width: 70px;
      }
      .selb-title {
        text-align: right;
      }
    }
  }

  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    font-size: 24px;
  }

  ::v-deep .el-dialog__headerbtn {
    top: 5px;
    right: 5px;
  }
}

::v-deep .el-select-dropdown__item {
  padding: 0;
  height: auto;
}

::v-deep .el-select-dropdown__list {
  display: flex;
  width: 300px;
  flex-wrap: wrap;
}

::v-deep .el-input__inner {
  color: #ec9368;
}

::v-deep .el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: #fff;
}

::v-deep .el-input-number {
  width: 300px;
}

::v-deep .el-icon-minus {
  font-size: 14px;
  color: #ec9368;
}

::v-deep .el-icon-plus {
  font-size: 14px;
  color: #ec9368;
}

::v-deep .el-textarea__inner {
  width: 760px;
}

::v-deep .el-dialog__footer {
  text-align: center;
}

::v-deep .el-button span {
  font-size: 14px;
}

.modifyCart {
  background: #87ceeb;
  color: #fff;
  border: none;
}

.copyCart {
  background: #ec9368;
  color: #fff;
  border: none;
  margin-left: 20px;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  color: #c0c4cc;
  cursor: not-allowed;
  background-image: none;
  background-color: #fff;
  border: 1px solid #ebeef5;
}
.custom {
  .fx {
    position: relative;
    margin: 8px 0;
  }

  .fl {
    display: flex;
    align-items: center;
    position: relative;
  }

  .selectBox {
    ::v-deep .el-popper {
      left: 0 !important;
    }

    .el-scrollbar__view li {
      width: 178px;
    }
  }

  .selectBoxWatch {
    .el-scrollbar__view li {
      min-width: 90px;
    }
  }
  .inchesFix {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .inchesTips {
    color: red;
    font-size: 12px;
    margin-left: 8px;
  }
  .inchesTips-size {
    color: red;
    font-size: 12px !important;
    margin-left: 8px;
    position: absolute;
    top: 37px;
    left: 20px;
  }
  .material {
    // width: 800px;
    ::v-deep .el-radio-button__inner {
      min-width: 65px;
    }
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #fff;
    background-color: #ec9368;
    border-color: #ec9368;
    box-shadow: none;
  }
  ::v-deep.el-radio-button__inner:hover {
    color: rgb(236, 147, 104);
  }
  ::v-deep .el-radio-button__inner {
    margin: 5px;
    border-left: 1px solid #dcdfe6;
    border-radius: 4px;
  }
  ::v-deep .inchesFix:first-child .el-radio-button__inner {
    margin-left: 0;
  }
  ::v-deep .el-radio-group {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
