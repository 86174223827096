<template>
  <div :class="['interestNumber', isLikeTwo === '1' ? 'interestNumberActive' : '']" @click="getInterest" v-if="isPresell === '1'">
    <svg-icon v-if="isLikeTwo === '0' || !isLikeTwo" iconClass="hollow-love" className="hollow-love"></svg-icon>
    <svg-icon v-if="isLikeTwo === '1'" iconClass="solid-love" className="hollow-love"></svg-icon>感兴趣({{ interestNumber }})
  </div>
</template>

<script>
import { interestCut, interestedIn } from '@/api/detailsApi';
export default {
  name: 'BeInterestedIn',
  props: {
    isLikeTwo: {
      type: String,
      default: '',
    },
    isPresell: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    styleNumber: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      interestNumber: null, //感兴趣数量
    };
  },
  mounted() {
    this.interestCut();
  },
  methods: {
    // 点击感兴趣
    getInterest() {
      let obj = {
        id: this.id,
        isLike: this.isLikeTwo === '0' || !this.isLikeTwo ? 1 : 0,
      };
      interestedIn(obj).then((res) => {
        if (res.code === 0) {
          this.interestCut();
          // 刷新父页面
          this.$emit('interestedInUpdata');
        }
      });
    },
    // 查询感兴趣数量
    interestCut() {
      interestCut(this.styleNumber).then((res) => {
        if (res.code === 0) {
          this.interestNumber = res.data;
        }
      });
    },
  },
};
</script>

<style scoped></style>
