import request from '@/utils/request';

// 反馈类型
export function getFeedbackType(data) {
  return request({
    url: '/exhibition/dic/type/exhibition_hall_feedback_type',
    method: 'get',
  });
}

// 提交反馈
export function createFeedb(data) {
  return request({
    url: '/exhibition/feedback',
    method: 'post',
    data,
  });
}
