<template>
  <div class="ordinaryModify">
    <el-dialog
      :popper-append-to-body="false"
      center
      title="物流信息"
      :close-on-click-modal="false"
      :visible.sync="expressDialogVisible"
      :before-close="handleClose"
    >
      <div>
        <div class="expressInfo-box">
          <div class="expressInfo">
            <div class="expressInfo-child">订单号：{{ expressData.orderNo }}</div>
            <div class="expressInfo-child">网点名称：{{ expressData.orgName }}</div>
          </div>
          <div class="expressInfo">
            <div class="expressInfo-child">联系人姓名：{{ expressData.payerName }}</div>
            <div class="expressInfo-child">联系人电话：{{ expressData.payerContact }}</div>
            <div class="expressInfo-child">取件方式：{{ expressData.receiveType }}</div>
          </div>
        </div>

        <el-tabs v-model="activeName" type="card" @tab-click="tabClick">
          <el-tab-pane v-for="(item, index) in expressData.parcelList" :key="item.orderNo" :label="'包裹' + (index + 1)" :name="'包裹' + (index + 1)">
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in expressList"
                :key="index"
                :timestamp="activity.time"
                :color="activity.color"
                :size="activity.size"
              >
                {{ activity.context }}
              </el-timeline-item>
            </el-timeline>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getParcel } from '@/api/orderApi';
export default {
  props: {
    expressDialogVisible: Boolean,
    expressData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      listQuery: {
        orderNo: '',
        expressNo: '',
      },
      activeName: '包裹1',
      parcelData: [],
      expressList: [],
    };
  },
  mounted() {
    this.listQuery.orderNo = this.expressData.orderNo;
    this.listQuery.expressNo = this.expressData.parcelList[0]?.expressNo;
    this.getExpressList();
  },
  methods: {
    // 获取列表数据
    getExpressList() {
      getParcel(this.listQuery).then((res) => {
        res.data.forEach((element, index) => {
          if (index === 0 && element.accept === true) {
            element.color = '#0bbd87';
            element.size = 'large';
          }
        });
        this.expressList = res.data;
      });
    },
    tabClick(tab) {
      this.listQuery.expressNo = this.expressData.parcelList[Number(tab.index)]?.expressNo;
      this.getExpressList();
    },
    // 关闭弹窗
    handleClose() {
      this.$emit('onCloseSelectDialog');
    },
  },
};
</script>

<style scoped lang="scss">
.ordinaryModify {
  /deep/ .el-dialog {
    // width: 1500px;
  }
  .expressInfo-box {
    margin-bottom: 10px;
    .expressInfo {
      display: flex;
      flex-wrap: wrap;

      .expressInfo-child {
        padding: 5px;
        margin-right: 10px;
      }
    }
  }
  /deep/ .el-tabs__nav {
    width: auto;
  }
  /deep/ .el-tabs__item {
    width: 80px;
  }
  /deep/ .el-tabs--card > .el-tabs__header .el-tabs__item {
    border-bottom: 1px solid #e4e7ed;
  }
  /deep/ .el-dialog--center .el-dialog__body {
    padding: 0 10px 10px 10px;
  }
}
</style>
