<template>
  <div class="order diamonDetails" v-cloak>
    <appheader></appheader>
    <div class="box">
      <div>
        <div @mouseenter="stopImglunbo" @mouseleave="mouseLeave" class="magnifier-wrap">
          <svg-icon v-if="DataList.isPresell === '1'" iconClass="pre-sale-label" className="pre-sale-label"></svg-icon>
          <magnifier :src="imgurl"></magnifier>
        </div>
        <div class="banner">
          <div class="slide">
            <div :class="[index == 0 ? 'imgbck' : '', 'imgbox']" v-for="(item, index) in DataList.imgPathList" :key="index" @click="changeImg(index)">
              <img :src="item" alt />
            </div>
          </div>
        </div>
      </div>
      <div class="content-box">
        <div class="introduction">
          <div class="productNameClass">{{ DataList.productName }}</div>
          <div>
            <div v-if="!isColleFlag" @click="colleBtn('收藏')" class="introduction cursor">
              <svg-icon iconClass="collection_default" className="collection_default"></svg-icon>
              收藏商品
            </div>
            <div v-if="isColleFlag" @click="colleBtn('取消')" class="introduction  cursor">
              <svg-icon iconClass="collection_selected" className="collection_default"></svg-icon>
              已收藏
            </div>
          </div>
        </div>
        <div class="content-details">
          <div class="content-details-child">
            <div class="content-details-text" v-if="DataList.styleNumber">
              公司款号：{{ DataList.styleNumber }}<span class="copyText cursor" @click="seccendCopy">复制</span>
            </div>
            <div class="content-details-text" v-if="DataList.bigSeries">大系列名：{{ DataList.bigSeries }}</div>
            <div class="content-details-text" v-if="DataList.mainStoneStart && DataList.mainStoneEnd">
              主石范围：{{ DataList.mainStoneStart }}-{{ DataList.mainStoneEnd }}ct
            </div>
          </div>
          <div class="content-details-child">
            <div class="content-details-text" v-if="DataList.assistStoneStart && DataList.assistStoneEnd">
              副石范围：{{ DataList.assistStoneStart }}-{{ DataList.assistStoneEnd }}ct
            </div>
            <div class="content-details-text" v-if="DataList.goldWeighStart && DataList.goldWeighEnd">
              金重范围：{{ DataList.goldWeighStart }}-{{ DataList.goldWeighEnd }}g
            </div>
          </div>
        </div>
        <!-- 单独款 -->
        <div class="box_goumai" v-if="DataList.isCouples === '2'" :class="[$route.query.groupFlag == 1 ? 'onGroup' : '']">
          <el-tabs v-model="activeName" @tab-click="handleClick" type="card">
            <el-tab-pane
              :disabled="DataList.isPresell === '1' || $route.query.groupFlag == 1"
              label="现货"
              name="first"
              v-if="$route.query.groupFlag != 1"
            >
              <Spot
                ref="spot"
                v-if="DataList.stockQuantity > 0"
                :stockQuantityCut="DataList.stockQuantity"
                :leastQuantity="DataList.leastQuantity"
                :DataList="DataList"
              />
            </el-tab-pane>
            <el-tab-pane v-if="hasMainBodyType()[0].value !== '2'" :label="$route.query.groupFlag == 1 ? '预售拼团' : '定单'" name="second">
              <Custom ref="custom" :leastQuantity="DataList.leastQuantity" :DataList="DataList" :groupFlag="$route.query.groupFlag" />
            </el-tab-pane>
          </el-tabs>
        </div>
        <!-- 情侣对戒 -->
        <div v-if="DataList.isCouples === '1'" :class="[$route.query.groupFlag == 1 ? 'onGroup' : '']">
          <el-tabs v-model="activeName" @tab-click="handleClick" type="card">
            <el-tab-pane
              :disabled="DataList.isPresell === '1' || $route.query.groupFlag == 1"
              label="现货"
              name="first"
              v-if="$route.query.groupFlag != 1"
            >
              <LoversSpot
                v-if="DataList.stockQuantity > 0"
                ref="loversSpot"
                :stockQuantityCut="DataList.stockQuantity"
                :leastQuantity="DataList.leastQuantity"
                :DataList="DataList"
              />
            </el-tab-pane>
            <el-tab-pane v-if="hasMainBodyType()[0].value !== '2'" :label="$route.query.groupFlag == 1 ? '预售拼团' : '定单'" name="second">
              <LoversCustom :leastQuantity="DataList.leastQuantity" ref="loversCustom" :DataList="DataList" :groupFlag="$route.query.groupFlag" />
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="subscribeToContainer" v-if="activeName === 'first' && DataList.stockQuantity == 0">
          <div class="subscribeTo" @click="updateSubscribe" v-if="!isSubscribe">
            <img src="../../Img/detaile/not-subscribe-to.png" alt="" /> 订阅到货通知
          </div>
          <div class="subscribeTo hasSubscribe" @click="updateSubscribe" v-if="isSubscribe">
            <img src="../../Img/detaile/has-subscribe-to.png" alt="" /> 已订阅到货通知
          </div>
        </div>
        <div class="addClass" v-if="(activeName === 'first' && DataList.stockQuantity > 0) || activeName === 'second'">
          <BeInterestedIn
            @interestedInUpdata="interestedInUpdata"
            :id="$route.query.id"
            :isLikeTwo="DataList.isLikeTwo"
            :isPresell="DataList.isPresell"
            :styleNumber="DataList.styleNumber"
          ></BeInterestedIn>
          <div class="onebottom">
            <el-button @click="pushshopping('cart')" class="pushCart" v-if="$route.query.groupFlag != 1">加入购物车</el-button>
            <el-button @click="pushshopping('order')">{{ $route.query.groupFlag != 1 ? '立即购买' : '拼团购买' }}</el-button>
          </div>
        </div>
      </div>
      <div class="toBottom topBottom" v-if="!goTopShow && (recommendationList.length > 0 || mantleSystemList.length > 0)" @click="bottomScrollClick">
        <svg-icon iconClass="gengduotuijian" className="gengduotuijian"></svg-icon>
        更多推荐
      </div>
    </div>
    <!-- 套系推荐 -->
    <MantleSystemRecommended :mantleSystemList="mantleSystemList" :codeType="$route.query.codeType"> </MantleSystemRecommended>
    <!-- 相似推荐 -->
    <SimilarRecommendation :recommendationList="recommendationList" :codeType="$route.query.codeType"> </SimilarRecommendation>
    <bottom></bottom>
    <div class="toTop topBottom" @click="topScrollClick" v-if="goTopShow">
      <svg-icon iconClass="fanhuidingbu" className="gengduotuijian"></svg-icon>
      返回顶部
    </div>
    <SubmitDialog
      v-if="submitDialogVisible"
      :submitDialogVisible="submitDialogVisible"
      @onCloseSelectDialog="onCloseSelectDialog"
      @placeOrder="placeOrder"
    />
  </div>
</template>

<script>
import Magnifier from '../moudel/magnifier';
import $ from 'jquery';
import Appheader from '../moudel/Appheader';
import bottom from '../bady_bottom/bottom';
import { debounce } from '@/utils/index';
import { getDiamondInfo, addCollection, getSimilarity, getMantleSystem, whetherSubscribeTo, editSubscribeTo, messagePoint } from '@/api/detailsApi';
import { modifyCartPublic, pushOrder } from '@/api/cartApi';
import Spot from './components/Spot.vue';
import Custom from './components/Custom.vue';
import LoversSpot from './components/LoversSpot.vue';
import LoversCustom from './components/LoversCustom.vue';
import BeInterestedIn from '../../components/BeInterestedIn.vue';
import SimilarRecommendation from '../../components/SimilarRecommendation.vue';
import MantleSystemRecommended from '../../components/MantleSystemRecommended.vue';
import SubmitDialog from '@/components/SubmitDialog.vue';
import ls from '@/utils/localStorage';
import { deepClone } from '@/utils/util';
export default {
  components: {
    Magnifier,
    Appheader,
    bottom,
    Spot,
    Custom,
    LoversSpot,
    LoversCustom,
    BeInterestedIn,
    SimilarRecommendation,
    MantleSystemRecommended,
    SubmitDialog,
  },
  data() {
    return {
      activeName: 'first',
      total: 0,
      nantotal: 0,
      submitDialogVisible: false,
      DataList: {
        styleNumber: this.$route.query.styleNumber,
      },
      productRemark: '',
      imgurl: '',
      index: 0, //开始轮播的值
      timer: null, //停止轮播的值
      size: 0, //图片的张数
      picHeight: 0, //图片的高度
      numberTotal: {
        spotNumber: 0, //普通款隐藏数组长度
        femaleSpotNumber: 0, //女款隐藏数组长度
        maleSpotNumber: 0, //男款隐藏数组长度
        customNumber: 0, //单款已选现货件数
        femaleCustomNumber: 0, //女款已选现货件数
        maleCustomNumber: 0, //男款已选现货件数
      },
      isColleFlag: false,
      recommendationList: [], //相似推荐
      mantleSystemList: [], //套系推荐
      scrollTop: null,
      goTopShow: false,
      isSubscribe: false,
      flag: false,
      addCartOrder: {},
    };
  },
  watch: {
    $route: {
      handler() {
        // 获取商品详情
        this.getDiamondInfo();
      },
      deep: true,
    },
  },
  created() {
    // 监听滚动事件
    window.addEventListener('scroll', this.handleScroll);
    // 获取商品详情
    this.getDiamondInfo();
    if (ls.get('coludQuery')) {
      // 埋点
      this.messagePoint('0');
    }
    if (this.$route.query.groupFlag == 1) {
      this.activeName = 'second';
    }
  },
  destroyed() {
    ls.remove('coludQuery');
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    // 关闭弹窗
    onCloseSelectDialog() {
      this.submitDialogVisible = false;
    },
    // 复制款号
    seccendCopy() {
      var that = this;
      this.$copyText(this.DataList.styleNumber).then(
        function(e) {
          that.$message({
            message: '复制成功!',
            type: 'success',
            duration: 1500,
          });
        },
        function(e) {
          that.$message({
            message: '复制失败!',
            type: 'warning',
            duration: 1500,
          });
        }
      );
    },
    messagePoint(pointType) {
      const query = {
        styleNumber: this.$route.query.styleNumber,
        pointType: pointType,
      };
      messagePoint(query).then((res) => {});
    },
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scrollTop > 300) {
        this.goTopShow = true;
      } else {
        this.goTopShow = false;
      }
    },
    bottomScrollClick() {
      let timer = null;
      const _that = this;
      cancelAnimationFrame(timer);
      timer = requestAnimationFrame(function fn() {
        if (_that.scrollTop != 1000) {
          _that.scrollTop = 1000;
          document.body.scrollTop = document.documentElement.scrollTop = _that.scrollTop;
          timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(timer);
          _that.goTopShow = true;
        }
      });
    },
    topScrollClick() {
      let timer = null;
      const _that = this;
      cancelAnimationFrame(timer);
      timer = requestAnimationFrame(function fn() {
        if (_that.scrollTop > 0) {
          _that.scrollTop = 0;
          document.body.scrollTop = document.documentElement.scrollTop = _that.scrollTop;
          timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(timer);
          _that.goTopShow = false;
        }
      });
    },
    // 点击感兴趣刷新页面
    interestedInUpdata() {
      this.getDiamondInfo();
    },
    // 相似推荐-套系推荐
    getRecommendation(styleNumber) {
      //相似推荐
      getSimilarity(styleNumber).then((res) => {
        this.recommendationList = res.data.records || [];
      });
      //套系推荐
      getMantleSystem(styleNumber).then((res) => {
        this.mantleSystemList = res.data.records || [];
      });
    },
    // 订阅-取消订阅
    updateSubscribe() {
      let obj = {
        styleNumber: this.DataList.styleNumber,
        columnCode: this.$route.query.codeType,
        requestFrom: 2,
      };
      if (!this.flag) {
        this.flag = true;
        editSubscribeTo(obj).then((res) => {
          this.isSubscribe = !this.isSubscribe;
          this.$message({
            message: '修改成功',
            type: 'success',
            duration: 1000,
          });
          this.flag = false;
        });
      }
    },
    // 查询是否订阅
    whetherSubscribeTo(styleNumber) {
      let obj = {
        styleNumber: styleNumber,
        columnCode: this.$route.query.codeType,
      };
      whetherSubscribeTo(obj).then((res) => {
        this.isSubscribe = res.data;
      });
    },
    // 点击收藏
    colleBtn(text) {
      let obj = {
        id: this.$route.query.styleNumber,
        isLike: text === '收藏' ? 1 : 0,
        columnCode: this.$route.query.codeType,
      };
      addCollection(obj).then((res) => {
        text === '收藏' ? (this.isColleFlag = true) : (this.isColleFlag = false);
        this.$message({
          message: res.data,
          type: 'success',
          duration: 1500,
        });
      });
    },
    // 鼠标移开开始滚动
    mouseLeave() {
      this.autoSlide(); // 图片开始再次轮播
    },
    // 重置定时器
    stopImglunbo() {
      clearInterval(this.timer);
    },
    // 点击定制现货清空所有已选勾选状态数量
    handleClick(tab, event) {},
    // 加入购物车
    pushshopping: debounce(
      function(type) {
        this.addCartOrder = {
          productDtoList: [],
          orderNowDtoList: [],
          shopType: 1,
          type: 1,
          spotFlag: 1,
          isCouples: this.DataList.isCouples,
          columnCode: this.$route.query.codeType,
          orderPath: 1,
          requestFrom: 1,
          purchaseSource: ls.get('coludQuery') ? 0 : 1, //0 云平台产品推荐, 1 其他渠道"
          siteId: ls.get('siteId') || ls.get('userInfo').sysUser?.siteId || '',
        };
        if (this.DataList.isCouples === '1') {
          if (this.activeName === 'first') {
            this.addCartOrder.productDtoList.push(this.$refs.loversSpot.dataFrom);
            this.addCartOrder.productDtoList.push(this.$refs.loversSpot.maleDataFrom);
            if (
              (this.$refs.loversSpot.dropDownOptional.handSizeList?.length > 0 && !this.$refs.loversSpot.dataFrom.ringSize) ||
              (this.$refs.loversSpot.dropDownOptional.mainStoneColour.length > 0 &&
                this.$refs.loversSpot.dataFrom.mainStoneColour == null &&
                this.$refs.loversSpot.dataFrom.mainStoneColour == undefined) ||
              (this.$refs.loversSpot.dropDownOptional.mainStoneClarity.length > 0 &&
                this.$refs.loversSpot.dataFrom.mainStoneClarity == null &&
                this.$refs.loversSpot.dataFrom.mainStoneClarity == undefined) ||
              (this.$refs.loversSpot.dropDownOptional.formatTypes.length > 0 && !this.$refs.loversSpot.dataFrom.formatType) ||
              (this.$refs.loversSpot.dropDownOptional.gemNameList.length > 0 && !this.$refs.loversSpot.dataFrom.gemName) ||
              !this.$refs.loversSpot.dataFrom.texture ||
              !this.$refs.loversSpot.dataFrom.mainStone ||
              !this.$refs.loversSpot.dataFrom.gia ||
              (this.$refs.loversSpot.dropDownOptional.maleHandSizeList.length > 0 && !this.$refs.loversSpot.maleDataFrom.ringSize) ||
              (this.$refs.loversSpot.dropDownOptional.maleMainStoneColour.length > 0 &&
                !this.$refs.loversSpot.maleDataFrom.mainStoneColour &&
                this.$refs.loversSpot.maleDataFrom.mainStoneColour == null &&
                this.$refs.loversSpot.maleDataFrom.mainStoneColour == undefined) ||
              (this.$refs.loversSpot.dropDownOptional.maleMainStoneClarity.length > 0 &&
                this.$refs.loversSpot.maleDataFrom.mainStoneClarity == null &&
                this.$refs.loversSpot.maleDataFrom.mainStoneClarity == undefined) ||
              (this.$refs.loversSpot.dropDownOptional.maleFormatTypes.length > 0 && !this.$refs.loversSpot.maleDataFrom.formatType) ||
              (this.$refs.loversSpot.dropDownOptional.maleGemNameList.length > 0 && !this.$refs.loversSpot.maleDataFrom.gemName) ||
              !this.$refs.loversSpot.maleDataFrom.texture ||
              !this.$refs.loversSpot.maleDataFrom.mainStone ||
              !this.$refs.loversSpot.maleDataFrom.gia
            ) {
              return this.$message({
                message: '请选择参数',
                type: 'warning',
                duration: 1500,
              });
            }
          } else {
            this.addCartOrder.type = 2;
            this.addCartOrder.spotFlag = 2;
            this.addCartOrder.productDtoList.push(this.$refs.loversCustom.dataFrom);
            if (
              (this.$refs.loversCustom.handSizeList?.length > 0 && !this.$refs.loversCustom.dataFrom.ringSize) ||
              (this.$refs.loversCustom.dropDownOptional.mainStoneColour.length > 0 &&
                this.$refs.loversCustom.dataFrom.mainStoneColour == null &&
                this.$refs.loversCustom.dataFrom.mainStoneColour == undefined) ||
              (this.$refs.loversCustom.dropDownOptional.mainStoneClarity.length > 0 &&
                this.$refs.loversCustom.dataFrom.mainStoneClarity == null &&
                this.$refs.loversCustom.dataFrom.mainStoneClarity == undefined) ||
              (this.$refs.loversCustom.dropDownOptional.formatTypes.length > 0 && !this.$refs.loversCustom.dataFrom.formatType) ||
              (this.$refs.loversCustom.dropDownOptional.gemNameList.length > 0 && !this.$refs.loversCustom.dataFrom.gemName) ||
              !this.$refs.loversCustom.dataFrom.texture ||
              !this.$refs.loversCustom.dataFrom.mainStone ||
              !this.$refs.loversCustom.dataFrom.gia
            ) {
              return this.$message({
                message: '请选择参数',
                type: 'warning',
                duration: 1500,
              });
            }
          }
        } else {
          // first现货 second定单
          if (this.activeName === 'first') {
            this.addCartOrder.productDtoList.push(this.$refs.spot.dataFrom);
            if (
              (this.$refs.spot.dropDownOptional.handSizeList?.length > 0 && !this.$refs.spot.dataFrom.ringSize) ||
              (this.$refs.spot.dropDownOptional.mainStoneColour.length > 0 &&
                this.$refs.spot.dataFrom.mainStoneColour == null &&
                this.$refs.spot.dataFrom.mainStoneColour == undefined) ||
              (this.$refs.spot.dropDownOptional.mainStoneClarity.length > 0 &&
                this.$refs.spot.dataFrom.mainStoneClarity == null &&
                this.$refs.spot.dataFrom.mainStoneClarity == undefined) ||
              (this.$refs.spot.dropDownOptional.formatTypes.length > 0 && !this.$refs.spot.dataFrom.formatType) ||
              (this.$refs.spot.dropDownOptional.gemNameList.length > 0 && !this.$refs.spot.dataFrom.gemName) ||
              !this.$refs.spot.dataFrom.texture ||
              !this.$refs.spot.dataFrom.mainStone ||
              !this.$refs.spot.dataFrom.gia
            ) {
              return this.$message({
                message: '请选择参数',
                type: 'warning',
                duration: 1500,
              });
            }
          } else {
            this.addCartOrder.type = 2;
            this.addCartOrder.spotFlag = 2;
            this.addCartOrder.productDtoList.push(this.$refs.custom.dataFrom);
            if (
              (this.$refs.custom.handSizeList.length > 0 && !this.$refs.custom.dataFrom.ringSize) ||
              (this.$refs.custom.dropDownOptional.mainStoneColour.length > 0 &&
                this.$refs.custom.dataFrom.mainStoneColour == null &&
                this.$refs.custom.dataFrom.mainStoneColour == undefined) ||
              (this.$refs.custom.dropDownOptional.mainStoneClarity.length > 0 &&
                this.$refs.custom.dataFrom.mainStoneClarity == null &&
                this.$refs.custom.dataFrom.mainStoneClarity == undefined) ||
              (this.$refs.custom.dropDownOptional.formatTypes.length > 0 && !this.$refs.custom.dataFrom.formatType) ||
              (this.$refs.custom.dropDownOptional.gemNameList.length > 0 && !this.$refs.custom.dataFrom.gemName) ||
              !this.$refs.custom.dataFrom.texture ||
              !this.$refs.custom.dataFrom.mainStone ||
              !this.$refs.custom.dataFrom.gia
            ) {
              return this.$message({
                message: '请选择参数',
                type: 'warning',
                duration: 1500,
              });
            }
          }
        }
        if (type == 'cart') {
          modifyCartPublic(this.addCartOrder, 'post').then((res) => {
            if (res.code == 0) {
              this.$confirm('添加成功', '提示', {
                confirmButtonText: '去购物车',
                cancelButtonText: '继续添加',
                type: 'success',
              })
                .then(() => {
                  this.$router.push('/shoppingCart');
                })
                .catch(() => {
                  // 加完购物车刷新现货列表
                });
            }
          });
        } else {
          this.addCartOrder.orderPath = this.$route.query.groupFlag == 1 ? 4 : this.activeName === 'first' ? 2 : 3;
          this.addCartOrder.orderNowDtoList = deepClone(this.addCartOrder.productDtoList);
          this.submitDialogVisible = true;
        }
      },
      500,
      true
    ),
    // 确定下单
    placeOrder(from) {
      let orderInfor = deepClone(from);
      delete orderInfor.organizationList;
      // 修改bug
      if (orderInfor.netAddress?.indexOf('-') > 0) {
        orderInfor.netCode = from.netAddress.split('-')[0];
        orderInfor.netAddress = from.netAddress.split('-')[1];
      } else {
        orderInfor.netCode = '';
        orderInfor.netAddress = '';
      }
      this.addCartOrder = { ...this.addCartOrder, ...orderInfor };
      delete this.addCartOrder.productDtoList;
      pushOrder(this.addCartOrder)
        .then((res) => {
          if (res.code === 0) {
            this.submitDialogVisible = false;
            this.$confirm('提交成功', '提示', {
              confirmButtonText: '继续下单',
              cancelButtonText: '查看订单',
              type: 'success',
            })
              .then(() => {
                // 强制刷新页面
                window.location.reload();
              })
              .catch(() => {
                if (this.$route.query.groupFlag == 1) {
                  this.$router.push('/PresellOrder');
                } else {
                  this.$router.push('/CommodityOrder');
                }
              });
          }
        })
        .catch((err) => {
          if (err === '存在已售或下架商品') {
            this.submitDialogVisible = false;
          }
        });
    },
    //获取商品详情
    getDiamondInfo() {
      const query = {
        styleNumber: this.$route.query.styleNumber,
        classify: this.$route.query.classify,
      };
      getDiamondInfo(query).then((res) => {
        this.DataList = res.data;
        //isCouples 1是情侣款 2 否
        //isPresell 1是预收款 2 否
        //isLike 0是未收藏  2是已收藏
        //isLikeTwo 0是未感兴趣  1是感兴趣
        if (this.DataList.isLike === '0') {
          this.isColleFlag = false;
        } else {
          this.isColleFlag = true;
        }
        this.imgurl = res.data.imgPathList[0];
        if (res.data.imgPathList.length > 3) {
          this.DataList.imgPathList = res.data.imgPathList.splice(0, 3);
        } else {
          this.DataList.imgPathList = res.data.imgPathList;
        }
        if (this.DataList.isPresell === '1') {
          this.activeName = 'second';
        }
        if (this.hasMainBodyType()[0].value === '2') {
          this.activeName = 'first';
        }
        // 相识推荐-套系
        this.getRecommendation(this.DataList.styleNumber);
        // 是否订阅
        this.whetherSubscribeTo(this.DataList.styleNumber);
        // DOM 渲染完毕后执行轮播事件
        this.$nextTick(function() {
          this.picHeight = $('.slide img').width(); //获取宽度度
          this.size = $('.slide').children().length; // 获取图片张数
          this.autoSlide(); //进页面默认轮播
          $('.banner').mouseover(() => {
            clearInterval(this.timer); // 停止图片轮播
          });
          $('.banner').mouseleave(() => {
            this.autoSlide(); // 图片开始再次轮播
          });
          // $('.slide').mouseleave();
        });
      });
    },
    // 图片轮播函数
    autoSlide() {
      this.timer = setInterval(() => {
        let l = this.DataList.imgPathList.length;
        // debugger
        if (this.index < l) {
          $('.imgbox').removeClass('imgbck');
          $('.imgbox')
            .eq(this.index)
            .addClass('imgbck');
          this.imgurl = this.DataList.imgPathList[this.index];
          this.index++;
        } else {
          $('.imgbox').removeClass('imgbck');
          $('.imgbox')
            .eq(0)
            .addClass('imgbck');
          this.imgurl = this.DataList.imgPathList[0];
          this.index = 1;
        }
      }, 3000); //每隔1.5S就切换一次图片
    },
    // 点击图片
    changeImg(index) {
      $('.imgbox').removeClass('imgbck');
      $('.imgbox')
        .eq(index)
        .addClass('imgbck');
      this.imgurl = this.DataList.imgPathList[index];
      this.index = index;
    },
  },
  // 离开页面清除定时器
  beforeDestroy() {
    clearInterval(this.timer);
  },
  // 缓存首页（旧版本）
  beforeRouteLeave(to, from, next) {
    // 从列表页去到别的页面，如果不是详情页，则不缓存列表页
    if (to.name != 'home') {
      this.$route.meta.keepAlive = false;
      this.$route.meta.flag = false;
    } else if (to.name == 'home') {
      this.$route.meta.keepAlive = true;
      this.$route.meta.flag = true;
    }
    next();
  },
};
</script>

<style scoped lang="scss">
.diamonDetails {
  position: relative;
  .content-box {
    width: 800px;
  }

  .introduction {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .productNameClass {
    font-size: 24px;
    color: #ec9368;
  }

  .cursor {
    cursor: pointer;
  }
  .content-details {
    display: flex;
    margin: 10px 0;
    .content-details-child {
      .content-details-text {
        padding: 0 30px 14px 0;
      }
      .copyText {
        color: #ec9368;
        margin-left: 10px;
      }
    }
  }
}
.pic-box {
  width: 500px;
  height: 500px;
  border: 1px solid #eee;
}
</style>
