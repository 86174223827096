<template>
  <div class="back">
    <div class="zlfLogo">
      <img src="../Img/login/logo-2.png" alt />
    </div>
    <div class="login_class" v-loading="loading" element-loading-text="拼命登录中。。。"></div>
    <bottom style="position:absolute;bottom:0;"></bottom>
  </div>
</template>

<script>
import bottom from "../views/bady_bottom/bottom";
import { posLogin } from '@/api/userApi'
export default {
  data () {
    return {
      loading: true,
    };
  },
  components: {
    bottom,
  },
  mounted () {
    this.getLogin();
  },
  methods: {
    getLogin () {
      var that = this;
      if (this.$route.query.token) {
        var gskh = that.$route.query.gskh;
        var token = that.$route.query.token;
        posLogin(token).then(res => {
          if (res.code === 200) {
            setUserData(res.userData)
            if (gskh) {
              this.$router.push({
                path: '/order',
                query: { id: gskh }
              })
            } else {
              this.$router.push({ path: '/home' })
            }
          } else {
            this.$router.push({ path: '/login' })
          }
        })
      } else {
        this.$router.push({ path: "/login" });
      }
    },
  },
};
</script>
<style scoped>
.el-loading-mask {
  background-color: transparent !important;
}
.zlfLogo {
  width: 126px;
  height: 58px;
  position: absolute;
  top: 78px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.back {
  position: absolute;
  width: 100%;
  /* overflow: scroll; */
  /* height: 100%; */
  background: url("../Img/login/back.png");
}
.login_class {
  width: 100%;
  margin-top: 300px;
}
.el-loading-spinner .circular {
  width: 55px !important;
  height: 55px !important;
}
.el-loading-spinner .el-loading-text {
  font-size: 20px !important;
}
</style>

<style scope>
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1600px) {
}
@media screen and (min-width: 1920px) {
}
</style>
