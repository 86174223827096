<template>
  <div class="fx_1">
    <template v-for="(item, index) in subMenuList">
      <el-submenu :popper-append-to-body="false" v-if="item.subList && item.subList.length > 0" :key="index + 'el-submenu'" :index="item.code">
        <template slot="title">
          <span>{{ item.name }}</span>
        </template>
        <SubMenu :subMenuList="item.subList" />
      </el-submenu>
      <el-menu-item v-else :key="index + item.name" :index="item.code">
        <template slot="title">
          <span v-if="item.name !== '新品预售'">{{ item.name }}</span>
          <svg-icon iconClass="pre-sale-tab" v-if="item.name === '新品预售'" className="pre-sale-tab"></svg-icon>
        </template>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
import SubMenu from './menuTree.vue';

export default {
  name: 'SubMenu', //必须要 递归组件的关键，并且要与引入组件的变量名称一致
  components: {
    SubMenu,
  },
  props: {
    subMenuList: {
      //接收到的菜单信息
      type: Array,
      default: [],
    },
    authority: {
      //填报权限 0 1不展示
      type: Number,
      default: 0,
    },
    mode: {
      //菜单 水平  垂直
      type: String,
      default: 'horizontal',
    },
  },
  data() {
    return {};
  },
  computed: {},
  //生命周期 - 创建完成
  created() {},
  //DOM挂载完毕
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.fx_1 {
  display: flex;
  position: relative;
  flex-wrap: wrap;
}

/deep/ .el-menu {
  border-right: none;
}

/deep/ .el-menu--popup .fx_1 {
  display: flex;
  flex-direction: column;
  background: #fff;
}

/deep/ .el-menu--horizontal {
  left: 0 !important;
  top: 50px !important;
  position: absolute !important;
  background: #fff !important;
}

.pre-sale-tab {
  width: 64px;
  height: 48px;
}
</style>
