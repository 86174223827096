<template>
  <div>
    <keep-alive>
      <div
        class="goodsBox"
        v-cloak
        v-loading="loading"
        element-loading-text="正在加载数据"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0,.1)"
      >
        <div class="breadCrumbs" v-if="breadCrumbsFlag == false">
          <span>首页</span>
          <span v-if="bigTitle">&nbsp;>&nbsp;{{ bigTitle }}</span
          >&nbsp;>&nbsp;<span>{{ levelTitle }}</span>
        </div>
        <div class="bgHome"></div>
        <div class="box_filter_top" v-if="codeType !== 'other_style'">
          <div class="box_filter">
            <div class="box_filter_shaixuan" @click="boxFilterUnfold()">
              <div class="screeningBox">
                <div class="screeningLogo">
                  筛选
                  <img :src="logo" alt class="img_zhankai" />
                </div>
              </div>
              <el-tag
                onClick="event.cancelBubble = true"
                :key="index"
                v-for="(tag, index) in dynamicTags"
                closable
                :type="tag.type"
                :disable-transitions="false"
                @close="handleClose(tag)"
                >{{ tag.name }}</el-tag
              >
            </div>
            <div class="box_filter_zhankai" v-if="dynamicTags.length > 0 ? true : false">
              <span class="box_filter_qingkong" @click="deleteFilet">清空</span>
            </div>
          </div>
          <div v-show="show_guanjianci">
            <div class="box_biankuang_shuaixuan">
              <div class="box_filter_xilie" v-if="keyWordsList.textureList && keyWordsList.textureList.length > 0">
                <span class="screeningTltle">材质种类：</span>
                <div :class="['box_fliter_xl_gengduo']">
                  <div
                    v-for="(item, index) in keyWordsList.textureList"
                    :class="[SPlist.type == item.type ? 'activesTltle' : '']"
                    :key="index"
                    class="box_fliter_xl_mc"
                    @click="getFilterPL(item, '材质种类')"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div class="box_filter_xuxian" v-if="keyWordsList.textureList && keyWordsList.textureList.length > 0"></div>
              <div class="box_filter_xilie" v-if="keyWordsList.eosGeneraNameList && keyWordsList.eosGeneraNameList.length > 0">
                <span class="screeningTltle">大类名称：</span>
                <div :class="['box_fliter_xl_gengduo']">
                  <div
                    v-for="(item, index) in keyWordsList.eosGeneraNameList"
                    :key="index"
                    class="box_fliter_xl_mc"
                    @click="getFilterPL(item, '分类')"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div class="box_filter_xuxian" v-if="keyWordsList.eosGeneraNameList && keyWordsList.eosGeneraNameList.length > 0"></div>
              <div class="box_filter_xilie" v-if="keyWordsList.customCategoryList && keyWordsList.customCategoryList.length > 0">
                <span class="screeningTltle">品类：</span>
                <div :class="['box_fliter_xl_gengduo']">
                  <div
                    v-for="(item, index) in keyWordsList.customCategoryList"
                    :key="index"
                    class="box_fliter_xl_mc"
                    @click="getFilterPL(item, '品类')"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div class="box_filter_xuxian" v-if="keyWordsList.customCategoryList && keyWordsList.customCategoryList.length > 0"></div>
              <div class="box_filter_xilie" v-if="keyWordsList.bigSeries && keyWordsList.bigSeries.length > 0">
                <span class="screeningTltle">大系列名：</span>
                <div :class="['box_fliter_xl_gengduo']">
                  <div v-for="(item, index) in keyWordsList.bigSeries" :key="index" class="box_fliter_xl_mc" @click="getFilterPL(item, '大系列名')">
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div class="box_filter_xuxian" v-if="keyWordsList.bigSeries && keyWordsList.bigSeries.length > 0"></div>
              <div class="box_filter_xilie" v-if="keyWordsList.chipList && keyWordsList.chipList.length > 0">
                <span class="screeningTltle">机芯：</span>
                <div :class="['box_fliter_xl_gengduo']">
                  <div v-for="(item, index) in keyWordsList.chipList" :key="index" class="box_fliter_xl_mc" @click="getFilterPL(item, '机芯')">
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div class="box_filter_xuxian" v-if="keyWordsList.chipList && keyWordsList.chipList.length > 0"></div>
            </div>
          </div>
        </div>
        <div class="bgHome"></div>
        <div class="home_sort" v-if="codeType !== 'other_style' && codeType !== 'gold18_style'">
          <div class="inputBox">
            <div
              class="sales"
              :class="[screenType == '1' ? 'classColor' : '']"
              @click.stop="salesSelection('newTime')"
              v-if="codeType !== 'sell_hot' && codeType !== 'pre_sale_customization'"
            >
              新品
            </div>
            <div
              class="sales"
              :class="[screenType == '2' ? 'classColor' : '']"
              @click.stop="salesSelection('sales')"
              v-if="codeType !== 'sell_hot' && codeType !== 'pre_sale_customization'"
            >
              热销
            </div>
            <div class="citySelect" v-if="codeType === 'sell_hot'">
              <el-select v-model="area" clearable placeholder="请选择热销区域" :popper-append-to-body="false" @change="changeArea">
                <el-option v-for="item in areaMenu" :key="item" :label="item" :value="item"> </el-option>
              </el-select>
            </div>
            <div class="citySelect" v-if="codeType === 'sell_hot'">
              <el-select
                v-model="provinceCopyList"
                multiple
                :disabled="!area"
                clearable
                placeholder="请选择热销省份"
                :popper-append-to-body="false"
                @change="changeCity"
              >
                <el-option v-for="item in city" :key="item" :label="item" :value="item"> </el-option>
              </el-select>
            </div>
            <div
              class="sales citySelect"
              :class="[screenType == '3' ? 'classColor' : '']"
              @click.stop="salesSelection('aYear')"
              v-if="codeType === 'sell_hot'"
            >
              近一年
            </div>
            <div
              class="sales"
              :class="[screenType == '4' ? 'classColor' : '']"
              @click.stop="salesSelection('halfYear')"
              v-if="codeType === 'sell_hot'"
            >
              近半年
            </div>
            <!-- 排序  -->
            <div class="sortWrap" v-if="codeType == 'brand_vegangold' || codeType == 'celebrity_matching'">
              <div v-for="(item, index) in storList" :key="index" class="sortMain">
                <div
                  :class="[SPlist.searchType == item.id ? 'activeScreenactiveScreen' : '']"
                  v-if="item.id !== '3' || ((!hasPermission('展厅') || !hasPermission('加盟公司总经理')) && item.id == '3')"
                  @click="onSort(item)"
                >
                  {{ item.name }}
                </div>
                <div class="sortSublevel" v-if="item.id !== '3' || ((!hasPermission('展厅') || !hasPermission('加盟公司总经理')) && item.id == '3')">
                  <div :class="SPlist.searchType == item.id && SPlist.sortShopType == 'asc' ? 'activeSort' : ''" class="iconfont up"></div>
                  <div :class="SPlist.searchType == item.id && SPlist.sortShopType == 'desc' ? 'activeSort' : ''" class="iconfont down"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- 素金之外才展示 -->
          <div class="showFlag spotRelevanceStyle" v-if="codeType == 'branded_diamond' || codeType == 'general_diamond'">
            <div class="inputBox" v-if="codeType == 'branded_diamond' || codeType == 'general_diamond'">
              <div class="inputTitle">主石重量</div>
              <div style="width: 115px">
                <el-input
                  size="mini"
                  @blur="isNumber('开始重量')"
                  @keyup.enter.native="getSearch()"
                  placeholder="请输入最小值"
                  v-model="SPlist.startMainWeight"
                >
                </el-input>
              </div>
              -
              <div style="width: 115px">
                <el-input
                  size="mini"
                  @blur="isNumber('截止重量')"
                  @keyup.enter.native="getSearch()"
                  placeholder="请输入最大值"
                  v-model="SPlist.endMainWeight"
                >
                </el-input>
              </div>
            </div>
            <div class="inputBox" v-if="codeType == 'branded_diamond' || codeType == 'general_diamond'">
              <div class="inputTitle">标签价格</div>
              <div style="width: 115px">
                <el-input
                  size="mini"
                  @blur="isNumber('开始价格')"
                  placeholder="请输入价格筛选"
                  v-model="SPlist.startLabelPrice"
                  @keyup.enter.native="getSearch()"
                >
                </el-input>
              </div>
              -
              <div style="width: 115px">
                <el-input
                  size="mini"
                  @blur="isNumber('截止价格')"
                  placeholder="请输入价格筛选"
                  v-model="SPlist.endLabelPrice"
                  @keyup.enter.native="getSearch()"
                >
                </el-input>
              </div>
            </div>
            <div class="screenBtn" @click="getSearch()" v-if="codeType != 'custom_presell'">
              筛 选
            </div>
          </div>
          <div
            class="spotRelevanceStyle"
            v-if="
              codeType == 'brand_vegangold' ||
                codeType == 'branded_diamond' ||
                codeType == 'general_diamond' ||
                codeType == 'celebrity_matching' ||
                codeType == 'gold18_style'
            "
          >
            <el-checkbox v-model="SPlist.spotFlag" :true-label="1" :false-label="null" @change="onChange($event, 'spotFlag')">仅看现货</el-checkbox>
            <el-checkbox
              v-model="SPlist.isRelevanceStyleNumber"
              true-label="0"
              :false-label="null"
              @change="onChange($event, 'isRelevanceStyleNumber')"
              >呈套系展示</el-checkbox
            >
          </div>
          <span class="home_sort_hotal">共&nbsp;{{ total }}&nbsp;件商品</span>
        </div>
        <div class="custom-style-number" v-if="codeType === 'other_style' || codeType === 'gold18_style'">
          <span class="customTitle" v-if="codeType === 'other_style'">定单款号</span>
          <span class="customTitle" v-if="codeType === 'gold18_style'">18K黄金黄</span>
          <el-input placeholder="请输入定单款号" v-model="SPlist.otherStyleNumber" clearable> </el-input>
          <div class="customBtn" @click="otherCustom">确定</div>
          <div class="spotRelevanceStyle" v-if="codeType == 'gold18_style'">
            <el-checkbox v-model="SPlist.spotFlag" :true-label="1" :false-label="null" @change="onChange($event, 'spotFlag')">仅看现货</el-checkbox>
            <el-checkbox
              v-model="SPlist.isRelevanceStyleNumber"
              true-label="0"
              :false-label="null"
              @change="onChange($event, 'isRelevanceStyleNumber')"
              >呈套系展示</el-checkbox
            >
          </div>
        </div>
        <div class="loadingClass">
          <div>
            <div class="infinite-list-wrapper" style="overflow: auto; font-size: 14px; max-height: 900px">
              <ul
                v-infinite-scroll="load"
                infinite-scroll-disabled="disabled"
                infinite-scroll-distance="1"
                :class="['list', 'box_Img', 'box_Img_top', datalist.length < 5 ? 'box_ImgActive' : '']"
              >
                <li v-for="(item, index) in datalist" class="box_Img_list" @click="getitem(item)" :key="index">
                  <svg-icon v-if="item.isPresell === '1'" iconClass="pre-sale-label" className="pre-sale-label"></svg-icon>
                  <div class="box_Img_chirld">
                    <img :src="item.imgPath" alt class="img_chirld" />
                  </div>
                  <div class="box_detail_name">
                    <div style="display: inline-block">
                      <div class="box_SP_bianhao">
                        <span>{{ item.styleNumber }}</span>
                      </div>
                      <div class="box_SP_title">
                        {{ item.productName }}
                      </div>
                      <div class="box_SP_title" v-if="item.type === '2' && item.minGoldWeight && item.maxGoldWeight && item.stockQuantity > 10">
                        金重：{{ item.minGoldWeight }}g-{{ item.maxGoldWeight }}g
                      </div>
                      <div class="box_SP_title" v-if="item.type === '1' && item.minLabelPrice && item.maxLabelPrice && item.stockQuantity > 1">
                        标签价格：￥{{ item.minLabelPrice }}-￥{{ item.maxLabelPrice }}
                      </div>
                    </div>
                    <div :class="['salesNumbersClass']" v-if="item.isPresell !== '1'">月销：{{ parseInt(item.salesNumbers) }}件</div>
                    <div
                      @click.stop="getInterest(item)"
                      :class="['salesNumbersClass', item.isPresell === '1' ? 'salesNumbersClassActive' : '']"
                      v-if="item.isPresell === '1'"
                    >
                      <svg-icon iconClass="solid-love" className="hollow-love"></svg-icon>({{ item.likeCount }})
                    </div>
                  </div>
                </li>
              </ul>
              <p v-if="listLoading" class="lodClass">加载中...</p>
              <p v-if="noMore && datalist.length > 0" class="noMore">
                没有更多了
              </p>
            </div>
          </div>
        </div>
        <div v-if="withWithoutShow" style="text-align: center; margin: 60px 0">
          <img src="@/Img/noData.png" alt />
        </div>
      </div>
    </keep-alive>
    <visitorDialog ref="visitorRef" />
  </div>
</template>
<script>
import bus from '@/utils/bus';
import { getScreening, getGoodsList, getCity, getSelectDefault, getCategoryList, getAreaMenu } from '@/api/homeApi';
import { CITY } from '@/utils/const';
import { interestedIn } from '@/api/detailsApi';
import { isNumber } from '@/utils/index';
import ls from '@/utils/localStorage';
import visitorDialog from '@/components/visitorDialog.vue';
export default {
  components: {
    visitorDialog,
  },
  data() {
    return {
      userData: ls.get('userData'),
      categoryFlag: false,
      seriesFlag: false,
      loading: false,
      breadCrumbsFlag: false,
      total: 0,
      dynamicTags: [],
      logo: require('@/Img/List/shouqi.png'),
      show_guanjianci: true,
      withWithoutShow: false,
      datalist: [],
      keyWordsList: {
        bigSeries: [], //大系列名
        customCategoryList: [], //品类
        chiplist: [], //机芯
        eosGeneraNameList: [], //大类名称
        textureList: [], //明星同款材质种类
      },
      FilterXLGD: '更多',
      FilterBXGD: '更多',
      SPlist: {
        styleNumber: '',
        sortType: null, //排序，默认null 1新品 2热销
        startGoldenWeight: null, //现货足金镶嵌金重
        endGoldenWeight: null, //现货足金镶嵌金重
        startMainWeight: null, //现货分数段起始值
        endMainWeight: null, //现货分数段终点值
        startLabelPrice: null, //分数段起始值
        endLabelPrice: null, //分数段终点值
        otherStyleNumber: '', //定单款号
        bigSeries: [], //大系列名
        customCategoryList: [], //品类
        categoryList: [], //明星同款列表品类筛查条件
        chiplist: [], //机芯
        eosGeneraNameList: [], //大类名称
        provinceList: [], //省份
        searchType: '',
        sortShopType: '', //排序类型金重工费
        current: 1,
        size: 20,
        requestFrom: 1,
        classify: null,
        goodsType: null,
        type: null,
        eliminate: null,
        generaName: null,
        is18Gold: null,
        spotFlag: null,
        isRelevanceStyleNumber: null,
        otherType: null,
        months: '',
        groupFlag: null,
      },
      city: [],
      levelTitle: '现货钻石',
      bigTitle: '现货产品',
      codeType: '', //导航栏code
      screenType: '',
      listLoading: false,
      storList: [
        {
          id: '1',
          name: '金重',
        },
        {
          id: '2',
          name: '销售克工费',
        },
        {
          id: '3',
          name: '批发克工费',
        },
      ],
      area: '',
      areaMenu: [],
      provinceCopyList: [],
    };
  },
  // 离开页面卸载事件
  beforeDestroy() {
    bus.$off('getList');
  },
  computed: {
    noMore() {
      return this.total <= this.datalist.length;
    },
    disabled() {
      return this.listLoading || this.noMore;
    },
  },
  mounted() {
    // 获取筛选条件以及列表
    bus.$on('getList', (id, name, code, text, bigTitle) => {
      this.screenType = null;
      this.SPlist.current = 1;
      this.SPlist.type = null;
      this.SPlist.eliminate = null;
      this.SPlist.generaName = null;
      this.SPlist.is18Gold = null;
      this.SPlist.spotFlag = null;
      this.SPlist.isRelevanceStyleNumber = null;
      this.SPlist.classify = null;
      this.SPlist.otherType = null;
      this.SPlist.months = '';
      this.SPlist.provinceList = [];
      this.provinceCopyList = [];
      this.SPlist.groupFlag = 0;
      this.area = '';
      if (bigTitle === '套系订单') {
        this.SPlist.sortType = 4;
      } else {
        this.SPlist.sortType = 1;
      }
      if (text === 'search') {
        this.SPlist.styleNumber = name;
        this.breadCrumbsFlag = true;
      } else {
        this.SPlist.styleNumber = '';
        this.levelTitle = name;
        this.codeType = code;
        this.breadCrumbsFlag = false;
        this.bigTitle = bigTitle;
        bus.$emit('emptySearch');
      }
      this.SPlist.otherStyleNumber = '';
      this.keyWordsList.textureList = [];
      this.SPlist.goodsType = null;
      this.clear();
      if (
        code != 'brand_vegangold' &&
        code != 'branded_diamond' &&
        code != 'general_diamond' &&
        code != 'pure_gold_watch' &&
        code != 'gold18_style'
      ) {
        this.clearScreening();
      }
      if (
        code == 'brand_vegangold' ||
        code == 'branded_diamond' ||
        code == 'general_diamond' ||
        code == 'pure_gold_watch' ||
        code == 'gold18_style'
      ) {
        if (code == 'brand_vegangold' || code == 'branded_diamond') this.fetchSelectDefault(id);
        if (code == 'general_diamond') this.fetchSelectDefault(id, name);
        this.getScreening(code);
      }
      if (code == 'brand_vegangold') {
        this.SPlist.classify = 1;
      } else if (code == 'branded_diamond' || code == 'general_diamond' || code == 'pure_gold_watch') {
        this.SPlist.classify = 2;
      } else {
        this.SPlist.classify = null;
      }
      if (code == 'general_diamond') {
        this.SPlist.bigSeries = [name];
      }
      if (code == 'pure_gold_watch') {
        this.SPlist.bigSeries = ['手表'];
      }
      if (code == 'celebrity_matching') {
        this.keyWordsList.textureList = [
          { name: '素金饰品', type: 2 },
          { name: '钻石饰品', type: 1 },
          { name: '黄金镶嵌', type: 4 },
        ];
        this.SPlist.type = 2;
        this.SPlist.goodsType = '明星同款';
        this.fetchCategoryList(2);
      }
      if (code == 'gold18_style') {
        this.keyWordsList.eosGeneraNameList = [];
        this.SPlist.generaName = '钻石饰品';
        this.SPlist.is18Gold = 1;
        this.SPlist.classify = 2;
        this.SPlist.otherType = 2;
      }
      if (code == 'other_style') {
        this.SPlist.eliminate = 1;
        this.SPlist.otherType = 1;
        this.total = 0;
        this.datalist = [];
      }
      if (code == 'sell_hot' && this.areaMenu.length == 0) {
        this.fetchAreaMenu();
      }
      if (text === 'search') {
        this.SPlist.classify = null;
      }
      if (code === 'pre_sale_customization') {
        this.SPlist.groupFlag = 1;
      }
      this.getList(true);
    });
  },
  methods: {
    changeArea(value) {
      this.provinceCopyList = [];
      this.getCity(value);
    },
    // 获取大区
    fetchAreaMenu() {
      getAreaMenu().then((res) => {
        this.areaMenu = res.data;
      });
    },
    // 获取省份
    getCity(value) {
      const query = {
        area: [value],
      };
      getCity(query).then((res) => {
        this.city = [];
        res.data?.forEach((item) => {
          this.city.push(item);
        });
        this.SPlist.provinceList = res.data;
        this.getList(true);
      });
    },
    // 点击感兴趣
    getInterest(item) {
      let obj = {
        id: item.id,
        isLike: item.isLike === '0' ? 1 : 0,
      };
      interestedIn(obj).then((res) => {
        if (res.code === 0) {
          this.getList(true);
        }
      });
    },
    // 失焦判断筛选条件
    isNumber(tetx) {
      if (tetx === '开始金重') {
        if (!isNumber(this.SPlist.startGoldenWeight)) {
          this.SPlist.startGoldenWeight = null;
        }
      } else if (tetx === '截止金重') {
        if (!isNumber(this.SPlist.endGoldenWeight)) {
          this.SPlist.endGoldenWeight = null;
        }
      } else if (tetx === '开始重量') {
        if (!isNumber(this.SPlist.startMainWeight)) {
          this.SPlist.startMainWeight = null;
        }
      } else if (tetx === '截止重量') {
        if (!isNumber(this.SPlist.endMainWeight)) {
          this.SPlist.endMainWeight = null;
        }
      } else if (tetx === '开始价格') {
        if (!isNumber(this.SPlist.startLabelPrice)) {
          this.SPlist.startLabelPrice = null;
        }
      } else if (tetx === '截止价格') {
        if (!isNumber(this.SPlist.endLabelPrice)) {
          this.SPlist.endLabelPrice = null;
        }
      }
    },
    // 重置
    clear() {
      this.SPlist.startLabelPrice = '';
      this.SPlist.endLabelPrice = '';
      this.SPlist.startMainWeight = '';
      this.SPlist.endMainWeight = '';
      this.SPlist.startGoldenWeight = '';
      this.SPlist.endGoldenWeight = '';
      this.SPlist.bigSeries = [];
      this.SPlist.customCategoryList = [];
      this.SPlist.categoryList = [];
      this.SPlist.chipList = [];
      this.SPlist.eosGeneraNameList = [];
      this.dynamicTags = [];
      this.SPlist.provinceList = [];
      this.provinceCopyList = [];
      this.SPlist.sortType = null;
      this.SPlist.searchType = null;
      this.SPlist.sortShopType = null;
      this.SPlist.months = '';
    },
    clearScreening() {
      this.keyWordsList.customCategoryList = [];
      this.keyWordsList.bigSeries = [];
      this.keyWordsList.eosGeneraNameList = [];
      this.keyWordsList.chipList = [];
      this.keyWordsList.textureList = [];
    },
    // 点击其它定单搜索
    otherCustom() {
      this.levelTitle = '其他定单';
      this.codeType = 'other_style';
      this.SPlist.current = 1;
      this.clear();
      this.getList(true);
    },
    // 按顺序查询
    salesSelection(item) {
      // this.clear();
      this.SPlist.current = 1;
      this.SPlist.months = '';
      if (item == 'sales') {
        this.screenType = '2';
        this.SPlist.sortType = 21;
      } else if (item == 'newTime') {
        this.screenType = '1';
        this.SPlist.sortType = 11;
      } else if (item == 'aYear') {
        this.SPlist.months = 12;
        this.screenType = '3';
      } else if (item == 'halfYear') {
        this.screenType = '4';
        this.SPlist.months = 6;
      }
      this.SPlist.current = 1;
      this.getList(true);
    },
    // 按金重还有工费排序
    onSort(item) {
      if (this.SPlist.searchType == item.id) {
        if (this.SPlist.sortShopType == 'desc') {
          this.SPlist.sortShopType = 'asc';
        } else {
          this.SPlist.sortShopType = 'desc';
        }
        if (item.id === '1') {
          if (this.SPlist.sortType == 31) {
            this.SPlist.sortType = 30;
          } else {
            this.SPlist.sortType = 31;
          }
        } else if (item.id === '2') {
          if (this.SPlist.sortType == 41) {
            this.SPlist.sortType = 40;
          } else {
            this.SPlist.sortType = 41;
          }
        } else if (item.id === '3') {
          if (this.SPlist.sortType == 51) {
            this.SPlist.sortType = 50;
          } else {
            this.SPlist.sortType = 51;
          }
        }
      } else {
        this.SPlist.searchType = item.id;
        this.SPlist.sortShopType = 'desc';
        if (item.id === '1') {
          this.SPlist.sortType = 31;
        } else if (item.id === '2') {
          this.SPlist.sortType = 41;
        } else if (item.id === '3') {
          this.SPlist.sortType = 51;
        }
      }
      this.SPlist.current = 1;
      this.getList(true);
    },
    // 切換省份
    changeCity(value) {
      this.SPlist.current = 1;
      this.SPlist.provinceList = value;
      this.provinceCopyList = value;
      this.getList(true);
    },
    onChange(e, type) {
      this.SPlist.current = 1;
      this.getList(true);
    },
    // 点击搜索
    getSearch() {
      if (this.codeType == 'brand_vegangold' && this.SPlist.startGoldenWeight && !this.SPlist.endGoldenWeight) {
        this.$message({
          message: '请输入截止金重',
          type: 'warning',
          duration: 1500,
        });
      } else if (this.codeType == 'brand_vegangold' && Number(this.SPlist.startGoldenWeight) > Number(this.SPlist.endGoldenWeight)) {
        this.$message({
          message: '截止金重不能小于开始金重',
          type: 'warning',
          duration: 1500,
        });
      } else if (this.SPlist.startMainWeight && !this.SPlist.endMainWeight) {
        this.$message({
          message: '请输入截止分数段',
          type: 'warning',
          duration: 1500,
        });
      } else if (Number(this.SPlist.startMainWeight) > Number(this.SPlist.endMainWeight)) {
        this.$message({
          message: '截止分数段不能小于开始分数段',
          type: 'warning',
          duration: 1500,
        });
      } else if (this.SPlist.startLabelPrice && !this.SPlist.endLabelPrice) {
        this.$message({
          message: '请输入截止价格',
          type: 'warning',
          duration: 1500,
        });
      } else if (Number(this.SPlist.startLabelPrice) > Number(this.SPlist.endLabelPrice)) {
        this.$message({
          message: '截止价格不能小于开始价格',
          type: 'warning',
          duration: 1500,
        });
      } else {
        this.SPlist.current = 1;
        this.SPlist.styleNumber = '';
        this.SPlist.otherStyleNumber = '';
        bus.$emit('emptySearch');
        this.getList(true);
      }
    },
    getFilterPL(e, text) {
      if (text == '分类') {
        var data = this.SPlist.eosGeneraNameList.find((item) => {
          return item === e.name;
        });
        if (!data) {
          this.SPlist.eosGeneraNameList.push(e.name);
        } else {
          //  过滤删除选中元素
          this.SPlist.eosGeneraNameList.splice(
            this.SPlist.eosGeneraNameList.findIndex((item) => item === e.name),
            1
          );
        }
      } else if (text == '品类') {
        if (this.codeType == 'celebrity_matching') {
          var data = this.SPlist.categoryList.find((item) => {
            return item === e.name;
          });
          if (!data) {
            this.SPlist.categoryList.push(e.name);
          } else {
            //  过滤删除选中元素
            this.SPlist.categoryList.splice(
              this.SPlist.categoryList.findIndex((item) => item === e.name),
              1
            );
          }
        } else {
          var data = this.SPlist.customCategoryList.find((item) => {
            return item === e.name;
          });
          if (!data) {
            this.SPlist.customCategoryList.push(e.name);
          } else {
            //  过滤删除选中元素
            this.SPlist.customCategoryList.splice(
              this.SPlist.customCategoryList.findIndex((item) => item === e.name),
              1
            );
          }
        }
      } else if (text == '大系列名') {
        var data = this.SPlist.bigSeries.find((item) => {
          return item === e.name;
        });
        if (!data) {
          this.SPlist.bigSeries.push(e.name);
        } else {
          //  过滤删除选中元素
          this.SPlist.bigSeries.splice(
            this.SPlist.bigSeries.findIndex((item) => item === e.name),
            1
          );
        }
      } else if (text == '机芯') {
        var data = this.SPlist.chipList.find((item) => {
          return item === e.name;
        });
        if (!data) {
          this.SPlist.chipList.push(e.name);
        } else {
          //  过滤删除选中元素
          this.SPlist.chipList.splice(
            this.SPlist.chipList.findIndex((item) => item === e.name),
            1
          );
        }
      } else if (text == '材质种类') {
        this.SPlist.type = e.type;
        this.fetchCategoryList(e.type);
        this.deleteFilet();
        this.getList(true);
        return;
      }
      var arr = this.dynamicTags.find((item) => {
        return item.name === e.name;
      });
      if (!arr) {
        this.dynamicTags.push(e);
      } else {
        // 过滤删除选中元素
        this.dynamicTags.splice(
          this.dynamicTags.findIndex((item) => item.name === e.name),
          1
        );
      }
      this.SPlist.current = 1;
      this.getList(true);
    },
    // 更多点击函数
    getMore(text) {
      if (text === '品类') {
        this.categoryFlag = !this.categoryFlag;
        if (this.categoryFlag === false) {
          this.FilterBXGD = '更多';
        } else {
          this.FilterBXGD = '收起';
        }
      } else {
        this.seriesFlag = !this.seriesFlag;
        if (this.seriesFlag === false) {
          this.FilterXLGD = '更多';
        } else {
          this.FilterXLGD = '收起';
        }
      }
    },
    // 筛选条件展开-收起
    boxFilterUnfold() {
      this.show_guanjianci = !this.show_guanjianci;
      if (!this.show_guanjianci) {
        this.logo = require('@/Img/List/zhankai.png');
      } else {
        this.logo = require('@/Img/List/shouqi.png');
      }
    },
    // 分页组件切换页数
    handleSizeChange(val) {
      this.SPlist.size = val;
      this.getList(true);
    },
    // 分页组件切换
    handleCurrentChange(val) {
      this.SPlist.current = val;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.getList(true);
    },
    // 点击跳转详情页
    getitem(data) {
      if (this.userData.username == '18888888888') {
        this.$refs.visitorRef.handleVisitor();
      } else {
        if (data.classify == '2' || data.classify == '1') {
          var query = {
            id: data.id,
            styleNumber: data.styleNumber,
            eliminate: data.eliminate,
            generaName: data.generaName,
            type: this.codeType == ('current_diamond' || 'current_gold') ? '1' : '2',
            codeType: this.codeType,
            classify: data.classify,
            groupFlag: data.groupFlag,
          };
        } else {
          var queryPage = {
            id: data.id,
            eliminate: data.eliminate,
            styleNumber: data.styleNumber,
            serialNumber: data.serialNumber,
            generaName: data.generaName,
            categoryName: data.categoryName,
            type: this.codeType == ('current_diamond' || 'current_gold') ? '1' : '2',
            codeType: this.codeType,
            classify: data.classify,
            groupFlag: data.groupFlag,
          };
        }
        this.$router.push({
          path:
            data.classify == '2'
              ? '/order'
              : data.classify == '1'
              ? '/plaingold'
              : data.classify == '3'
              ? '/watch'
              : data.classify == '4'
              ? '/goldsetting'
              : '',
          query:
            data.classify == '2' ? query : data.classify == '1' ? query : data.classify == '3' ? queryPage : data.classify == '4' ? queryPage : '',
        });
      }
      // let routeData = this.$router.resolve({
      //   path: data.type == "1" ? "/order" : data.type === "2" ? "/plaingold" : data.type === "3" ? "/watch" : data.type == "4" ? "/goldsetting" : '',
      //   query: data.type == "1" ? query : data.type === "2" ? query : data.type === "3" ? queryPage : data.type == "4" ? queryPage : ''
      // })
      // 打开新页面
      // window.open(routeData.href, '_blank')
    },
    // 筛选清空
    deleteFilet() {
      this.dynamicTags = [];
      if (this.codeType != 'general_diamond' && this.codeType != 'pure_gold_watch') {
        this.SPlist.bigSeries = [];
      }
      this.SPlist.customCategoryList = [];
      this.SPlist.categoryList = [];
      this.SPlist.chipList = [];
      this.SPlist.eosGeneraNameList = [];
      this.SPlist.current = 1;
      this.getList(true);
    },
    // 删除标签页
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      for (var i in this.SPlist) {
        if (Array.isArray(this.SPlist[i])) {
          if (this.SPlist[i].indexOf(tag.name) != -1) {
            this.SPlist[i].splice(this.SPlist[i].indexOf(tag.name), 1);
          }
        }
      }
      this.SPlist.current = 1;
      this.getList(true);
    },
    fetchSelectDefault(classify, name) {
      const query = {
        bigSeries: name || null,
        classify: classify,
        customCategoryList: [],
      };
      getSelectDefault(query).then((res) => {
        this.keyWordsList.eosGeneraNameList = [];
        res.data?.forEach((item) => {
          const obj = {
            name: item,
          };
          this.keyWordsList.eosGeneraNameList.push(obj);
        });
      });
    },
    fetchCategoryList(type) {
      const query = {
        goodsType: '明星同款',
        type: type,
      };
      getCategoryList(query).then((res) => {
        this.keyWordsList.customCategoryList = [];
        res.data?.forEach((item) => {
          const obj = {
            name: item,
          };
          this.keyWordsList.customCategoryList.push(obj);
        });
      });
    },
    // 获取筛选条件
    getScreening(code) {
      getScreening().then((res) => {
        const copyCategoryList = [];
        this.keyWordsList.customCategoryList = [];
        this.keyWordsList.bigSeries = [];
        this.keyWordsList.chipList = [];
        if (code === 'brand_vegangold') {
          this.keyWordsList.bigSeries.push(...res.data[0].childList);
        }
        if (code === 'branded_diamond') {
          res.data[1].childList?.forEach((item) => {
            if (item.name !== '手表' && item.name !== '普货钻石') {
              this.keyWordsList.bigSeries.push(item);
            }
          });
        }
        if (code === 'gold18_style') {
          res.data[1].childList?.forEach((item) => {
            this.keyWordsList.bigSeries.push(item);
          });
        }
        if (code === 'brand_vegangold' || code === 'branded_diamond' || code === 'gold18_style') {
          this.keyWordsList.bigSeries?.forEach((item) => {
            copyCategoryList.push(...item.childList);
          });
        }
        if (code === 'general_diamond') {
          res.data[1].childList.forEach((item) => {
            if (item.name === '普货钻石') {
              copyCategoryList.push(...item.childList);
            }
          });
        }
        if (code === 'pure_gold_watch') {
          this.keyWordsList.eosGeneraNameList = [];
          res.data[1].childList.forEach((item) => {
            if (item.name === '手表') {
              copyCategoryList.push(...item.childList);
            }
          });
          this.keyWordsList.chipList = [{ name: '石英' }, { name: '机械' }];
        }
        // 品类去重
        this.keyWordsList.customCategoryList = copyCategoryList.reduce((acc, curr) => {
          if (!acc.some((item) => item.name === curr.name)) {
            acc.push(curr);
          }
          return acc;
        }, []);
        console.log(res.data);
      });
    },
    // 获取商品列表
    getList(isReload = false) {
      this.loading = true;
      getGoodsList(this.SPlist)
        .then((res) => {
          if (isReload) this.datalist = [];
          res.data.records?.forEach((item) => {
            this.datalist.push(item);
          });
          this.total = res.data.total;
          if (res.data.total > 0) {
            this.withWithoutShow = false;
          } else if (res.data.total == 0) {
            this.withWithoutShow = true;
          }
          this.listLoading = false;
          this.loading = false;
        })
        .catch((err) => {
          this.listLoading = false;
          this.loading = false;
        });
    },
    // 商品列表数据
    load() {
      if (this.loading == false && this.noMore == false) {
        this.SPlist.current++;
        this.listLoading = true;
        this.getList();
      }
    },
  },
  activated() {},
};
</script>

<style>
.el-radio__input.is-checked .el-radio__inner {
  border-color: rgb(236, 147, 104) !important;
  background: rgb(236, 147, 104) !important;
}

.el-radio__input.is-checked + .el-radio__label {
  color: rgb(236, 147, 104) !important;
}

.shoppingpush {
  display: inline-block;
  position: absolute;
  right: 15px;
  bottom: 17px;
}

.salesNumbersClass {
  position: absolute;
  right: 6px;
  top: 10px;
  font-size: 16px;
  color: #999999;
  display: flex;
  align-items: center;
}

.salesNumbersClass .hollow-love {
  width: 20px;
  height: 20px;
}

.salesNumbersClassActive {
  color: #ec9368;
}

.boe_fenye .el-pagination__total {
  line-height: 42px !important;
  color: #ec9368;
  font-size: 16px !important;
}

.boe_fenye .el-pagination__jump {
  color: #ec9368;
  font-size: 16px;
}

.boe_fenye .el-pagination__jump .el-input__inner {
  height: 42px !important;
  width: 40px;
}

.home_sort .el-input__inner {
  padding: 0px 10px;
}

.el-scrollbar__view .selected {
  color: #cdcdcd;
}

.el-scrollbar__view li {
  color: #ec9368;
}

.boe_fenye .el-input__inner {
  width: 102px;
  height: 42px !important;
  border: 1px solid #cdcdcd;
  color: #ec9368;
  font-size: 16px !important;
}

.boe_fenye .btn-next i::before {
  color: #ec9368;
  font-size: 16px;
  line-height: 42px;
}

.boe_fenye .btn-prev i::before {
  color: #ec9368;
  font-size: 16px;
  line-height: 42px;
}

.boe_fenye .active {
  color: #fff !important;
  background-color: #ec9368;
}

.boe_fenye .el-pager li {
  color: #ec9368;
  width: 42px;
  height: 42px;
  line-height: 42px !important;
  font-size: 16px;
}

.boe_fenye .el-pager li:hover {
  color: #fff;
  background-color: #ec9368;
}

.boe_fenye .number {
  border: 1px solid #cdcdcd;
  margin: 0 2px;
}

.box_SP_bianhao {
  margin: 10px 6px 0 5px;
  font-size: 16px;
  color: #ec9368;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 277px;
}
.commodityAttribute {
  font-size: 14px;
  color: #999999;
}
.box_SP_title {
  font-size: 16px;
  color: #000;
  margin: 5px;
  width: 280px;
  overflow: hidden;
  /*超出的部分隐藏起来。*/
  white-space: nowrap;
  /*不显示的地方用省略号...代替*/
  text-overflow: ellipsis;
  /* 支持 IE */
}

.box_filter_shaixuan .el-tag {
  background-color: #fff2ea;
  border-radius: 0;
  /* width: 114px; */
  font-size: 16px;
  color: #000;
  margin-left: 18px;
  color: #ec9368;
  font-size: 16px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #ec9368;
}

.box_filter_shaixuan .el-tag i::before {
  line-height: 30px;
}

.box_filter_shaixuan .el-tag i {
  border-radius: 0;
  width: 30px;
  color: #000;
  height: 30px;
  left: 10px;
  top: -3px;
}

.box_filter_shaixuan span:hover {
  /* border: 1px solid red; */
  cursor: pointer;
}

.box_filter_shaixuan .el-tag i:hover {
  background: #ec9368;
}
</style>

<style scoped lang="scss">
@import '@/style/homeList.scss';
/deep/ .el-loading-mask {
  background-color: rgba(0, 0, 0, 0.1) !important;
  min-height: 278px;
  // margin-top: 20px;
  margin: auto;
  max-width: 1500px;
  top: -450px;
}
.activesTltle {
  color: #ec9368;
}
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #ec9368;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ec9368;
  border-color: #ec9368;
}
</style>
