import Vue from 'vue';
import Router from 'vue-router';
import login from '../Login/login.vue';
import Home from '../views/home/home.vue';
import order from '../views/diamond/diamondDetails.vue';
import watch from '../views/watch/watch.vue';
import plaingold from '../views/plaingold/plaingold.vue';
import CommodityOrder from '../views/CommodityOrder/CommodityOrder.vue';
import PresellOrder from '../views/presellOrder/PresellOrder.vue';
import shoppingCart from '../views/shoppingCart/shoppingCart.vue';
import posLogin from '../poslogin/posLogin.vue';
import yunLogin from '../yunlogin/yunLogin.vue';

Vue.use(Router);

const router = new Router({
  routes: [
    // 登录页
    {
      path: '/login',
      name: 'login',
      component: login,
    },
    {
      path: '/',
      redirect: '/home',
      component: Home,
      meta: {
        keepAlive: true,
        flag: true,
      },
    },
    {
      //商品列表
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        keepAlive: true,
        flag: true,
      },
    },
    {
      // 钻石商品详情
      path: '/order',
      name: 'order',
      component: order,
    },
    {
      // 手表商品详情
      path: '/watch',
      name: 'watch',
      component: watch,
    },
    {
      // 我的收藏
      path: '/goldsetting',
      name: 'goldsetting',
      component: () => import('@/views/goldsetting/goldsetting.vue'),
    },
    {
      // 我的订单
      path: '/CommodityOrder',
      name: 'CommodityOrder',
      component: CommodityOrder,
    },
    {
      // 预售订单
      path: '/PresellOrder',
      name: 'PresellOrder',
      component: PresellOrder,
    },
    {
      // 有效商品购物车
      path: '/shoppingCart',
      name: 'shoppingCart',
      component: shoppingCart,
    },
    {
      // 失效商品购物车
      path: '/invalidGoods',
      name: 'InvalidGoods',
      component: () => import('@/views/invalidgoods/InvalidGoods.vue'),
    },
    {
      // 我的收藏
      path: '/collection',
      name: 'Collection',
      component: () => import('@/views/collection/Collection.vue'),
    },
    {
      // 我的足迹
      path: '/footprint',
      name: 'Footprint',
      component: () => import('@/views/footprint/Footprint.vue'),
    },
    {
      // 我的订阅商品
      path: '/subscribe',
      name: 'Subscribe',
      component: () => import('@/views/subscribe/Subscribe.vue'),
    },
    {
      //修改密码
      path: '/changepassword',
      name: 'Changepassword',
      component: () => import('@/views/changepassword/Changepassword.vue'),
    },
    {
      //日志管理
      path: '/logmanagement',
      name: 'Logmanagement',
      component: () => import('@/views/logmanagement/Logmanagement.vue'),
    },
    {
      //账号管理
      path: '/accountmanagement',
      name: 'Accountmanagement',
      component: () => import('@/views/accountmanagement/Accountmanagement.vue'),
    },
    {
      //新增账号
      path: '/newaccount',
      name: 'Newaccount',
      component: () => import('@/views/newaccount/Newaccount.vue'),
    },
    {
      //组织机构
      path: '/organizationalstructure',
      name: 'Organizationalstructure',
      component: () => import('@/views/organizationalstructure/Organizationalstructure.vue'),
    },
    {
      //联系我们
      path: '/contactUs',
      name: 'ContactUs',
      component: () => import('@/views/contactUs/ContactUs.vue'),
    },
    {
      // pos登录页
      path: '/poslogin',
      name: 'poslogin',
      component: posLogin,
    },
    {
      // 云平台登录页
      path: '/yunLogin',
      name: 'yunLogin',
      component: yunLogin,
    },
    {
      // 素金页面
      path: '/plaingold',
      name: 'plaingold',
      component: plaingold,
    },
  ],
});
/**
 * 解决重复触发同一个路由报错问题
 */
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
