<template>
  <keep-alive>
    <div class="search" v-cloak>
      <div class="detai_box_img">
        <el-carousel ref="carousel" :interval="cutTime" arrow="always" :autoplay="autoplay" @change="onChange">
          <el-carousel-item v-for="(item, index) in imgList" :key="index">
            <video class="video" :ref="'videoPlayer' + index" autoplay muted controls v-if="item.fileType === '2'">
              <source :src="item.filePath" type="video/mp4" />
              您的浏览器不支持Video标签。
            </video>
            <img v-else :ref="'videoPlayer' + index" :src="item.filePath" alt="" style="max-width:1500px;height:304px;width:100%" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="box_search" :class="navBarFixed == true ? 'navBarWrap' : ''">
        <div class="bgHome"></div>
        <div class="box_shuqain">
          <div class="rel_commodity">
            <div class="taBox">
              <el-menu
                :default-active="activeName"
                background-color="rgba(0,0,0,0)"
                @select="handleSelect"
                active-text-color="#ec9368"
                unique-opened
                :mode="mode"
              >
                <SubMenu :subMenuList="clumn" :mode="mode" />
              </el-menu>
            </div>
            <div class="searchBox">
              <el-input
                autocomplete="on"
                name="styleNumber"
                class="box_input"
                placeholder="请输入搜索的款号/小系列名"
                prefix-icon="el-icon-search"
                v-model="styleNumber"
                @keyup.enter.native="getFilterKeywords()"
              ></el-input>
              <el-button class="box_serar" @click="getFilterKeywords()">搜索</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="bgHome"></div>
    </div>
  </keep-alive>
</template>

<script>
import bus from '@/utils/bus';
import SubMenu from './menuTree.vue';
import { getBenner, getColumn } from '@/api/homeApi';
export default {
  name: 'ZlfSearch',
  components: {
    SubMenu,
  },
  data() {
    return {
      styleNumber: '',
      activeName: 'brand_vegangold',
      navBarFixed: false,
      mode: 'horizontal',
      isPlay: false, //播放状态
      cutTime: 5000, //轮播时间，单位毫秒
      autoplay: true, //是否自动切换
      nowIndex: 0,
      clumn: [],
      filtList: [],
      imgList: [],
      bigTitle: '',
    };
  },
  mounted() {
    // 获取轮播图
    this.getBenner();
    // 获取栏目
    this.getColumn();
    // 事件监听滚动条
    window.addEventListener('scroll', this.watchScroll);
    bus.$on('emptySearch', () => {
      this.styleNumber = null;
    });
  },
  methods: {
    // 监听轮播图
    onChange(nowIndex, oldIndex) {
      // 如果是视频,就等播放完再进行下一个
      if (this.imgList[nowIndex].fileType === '2') {
        this.autoplay = false;
        this.$refs['videoPlayer' + nowIndex][0].play();
        this.nowIndex = nowIndex;
        this.listenVideo();
      }
      // 跳转之后清空上一个视频进度条
      if (this.imgList[oldIndex].fileType === '2') {
        // 如果上一个内容是视频就清空进度条
        let myVideo = this.$refs['videoPlayer' + oldIndex];
        myVideo[0].pause();
        myVideo[0].currentTime = 0;
      }
    },
    //监听视频的播放、暂停、播放完毕等事件
    listenVideo() {
      //注意：这样获取的dom是一个数组，必须加上[0]索引才能正确的添加事件
      let myVideo = this.$refs['videoPlayer' + this.nowIndex];
      //监听播放
      myVideo[0].addEventListener('play', () => {
        this.autoplay = false;
      });
      //监听暂停
      myVideo[0].addEventListener('pause', () => {
        this.autoplay = true;
      });
      //监听播放完毕
      myVideo[0].addEventListener('ended', () => {
        //时间归零
        myVideo[0].currentTime = 0;
        this.autoplay = true;
        this.next();
      });
    },
    // 视频播放完手动切换下一张
    next() {
      //调动setActiveItem方法，动态切换图片
      if (this.nowIndex === this.imgList.length - 1) {
        this.$refs.carousel.setActiveItem(0);
      } else {
        this.$refs.carousel.setActiveItem(this.nowIndex + 1);
      }
    },
    // 获取轮播图图片、视频
    getBenner() {
      getBenner().then((res) => {
        res.data.forEach((element) => {
          this.imgList.push(element);
        });
      });
    },
    // 获取栏目
    getColumn() {
      const array = [
        { code: 'brand_vegangold', id: '1', name: '品牌素金' },
        { code: 'branded_diamond', id: '2', name: '品牌钻石' },
        {
          code: 'general_cargo',
          id: '3',
          name: '普货钻石',
          subList: [
            { code: 'general_diamond', id: '2', name: '普货钻石', parentId: '3' },
            { code: 'pure_gold_watch', id: '3', name: '足金手表', parentId: '3' },
          ],
        },
        { code: 'celebrity_matching', id: '4', name: '明星同款' },
        { code: 'sell_hot', id: '6', name: '爆款热销' },
        {
          code: 'other',
          id: '5',
          name: '其他订单',
          subList: [
            { code: 'gold18_style', id: '1', name: '18K黄金黄订单', parentId: '5' },
            { code: 'other_style', id: '2', name: '其他订单', parentId: '5' },
          ],
        },
        { code: 'pre_sale_customization', id: '7', name: '预售定制' },
      ];
      this.clumn.push(...array);
      array.forEach((item) => {
        if (!item.subList) {
          this.filtList.push(item);
        } else {
          this.filtList.push(item?.subList);
        }
      });
      this.$nextTick(() => {
        bus.$emit(
          'getList',
          this.filtList.flat(Infinity)[0].id,
          this.filtList.flat(Infinity)[0].name,
          this.filtList.flat(Infinity)[0].code,
          'tab',
          this.bigTitle
        );
        this.activeName = this.filtList.flat(Infinity)[0].code;
      });
    },
    // 点击栏目
    handleSelect(key) {
      this.activeName = key;
      const item = this.filtList?.flat(Infinity).find((item) => item.code == key);
      this.clumn.forEach((ele) => {
        if (ele.subList?.length > 0) {
          ele.subList.forEach((aly) => {
            if (aly.code === item.code) {
              this.bigTitle = ele.name || null;
            }
          });
        } else if (ele.code === item.code) {
          this.bigTitle = null;
        }
      });
      bus.$emit('getList', item.id, item.name, item.code, 'tab', this.bigTitle);
    },
    // 滚动吸顶事件
    watchScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      //  当滚动超过 384 时，实现吸顶效果
      if (scrollTop > 384) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    // 点击搜索
    getFilterKeywords() {
      const item = this.filtList?.flat(Infinity).find((item) => item.code == this.activeName);
      bus.$emit('getList', item.id, this.styleNumber, item.code, 'search');
    },
  },
  // 离开页面清除事件
  beforeDestroy() {
    bus.$off('emptySearch');
    window.removeEventListener('scroll', this.watchScroll);
  },
};
</script>

<style>
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.navBarWrap {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 999;
  background: #fff;
  width: 100%;
}

.el-carousel__container {
  height: 304px !important;
}

.box_shuqain .box_serar {
  background-color: #ec9368;
  width: 74px;
  padding: 6px;
  position: relative;
  left: -1px;
  height: 34px;
  font-size: 18px;
  color: #fff;
}

.box_input .el-input__inner {
  height: 34px !important;
  padding-left: 40px !important;
  background-color: #fff !important;
  border-radius: 0px !important;
  border: 1px solid #ec9368;
}

.box_input .el-icon-search:before {
  font-size: 18px;
}

.box_input .el-input__inner::placeholder {
  padding-left: 10px;
  color: #999;
  font-size: 18px !important;
}

.box_input .el-input__prefix {
  left: 10px;
}

.el-input__inner:focus,
.el-input__inner:hover {
  border: 1px solid #ec9368 !important;
  background: #fff !important;
}
</style>

<style scoped lang="scss">
::v-deep .el-input__icon {
  line-height: 35px !important;
}

::v-deep .el-input__prefix,
.el-input__suffix {
  line-height: 35px !important;
  height: 35px !important;
}

.searchBox {
  max-width: 350px;
  min-width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  margin: 0 5px;

  ::v-deep .el-input .el-input__inner::placeholder {
    font-size: 14px !important;
  }
}

.el-button {
  border: 1px solid #ec9368;
}

.box_input {
  height: 34px;
  width: 320px;
}

.box_search {
  max-width: 1500px;
  margin: 0 auto;
}

.el-button:focus,
.el-button:hover {
  background: #ec9368;
  color: #fff;
  border: none;
}

.detai_box_img {
  max-width: 1500px;
  height: 304px;
  margin: 0 auto;

  .video {
    height: 304px;
    width: 100%;
  }
}

.search .box_shuqain {
  width: 100%;
  box-shadow: 0px 8px 12px #f5f5f5;
}

.bgHome {
  height: 5px;
  background: #f3f3f3;
}

.rel_commodity {
  display: flex;
  // height: 50px;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  background: #fff;
}

.taBox {
  max-width: 950px;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 0.01rem;
  opacity: 0;
  display: none;
}

.el-menu {
  border: none;

  /deep/ .el-menu-item {
    /* 选中菜单颜色 */
    font-size: 14px;

    &.is-active {
      border-bottom: 2px solid #ec9368 !important;
    }
  }

  ::v-deep .el-menu-item,
  .el-submenu__title {
    height: 50px;
    line-height: 50px;
  }

  ::v-deep .el-icon-arrow-down {
    font-size: 14px;
    right: 15px;
  }

  /* 子级菜单项 */
  /deep/ .el-submenu {
    position: relative;

    .el-submenu__title {
      display: flex;
      align-items: center;
      height: 50px;
      line-height: 50px;

      span {
        padding: 0 10px;
      }

      .el-icon-location {
        position: static;
        margin: 0;
        color: #013c807e;
      }
    }
  }
}
</style>
