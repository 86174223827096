<template>
  <div class="similarRecommendation" v-if="recommendationList.length > 0">
    <span class="similarRecommendation-text">·&nbsp;相似推荐&nbsp;·</span>
    <div :class="['similarRecommendation-list', recommendationList.length < 6 ? 'similarRecommendation-list-boxActive' : '']">
      <div v-for="(item, index) in recommendationList" class="box_Img_list" @click="getitem(item)" :key="index" v-if="index < 10 || !showNone">
        <svg-icon v-if="item.isPresell === '1'" iconClass="pre-sale-label" className="pre-sale-label"></svg-icon>
        <div class="box_Img_chirld">
          <img :src="item.imgPath" alt class="img_chirld" />
        </div>
        <div class="box_detail_name">
          <div style="display: inline-block">
            <div class="box_SP_bianhao">{{ item.styleNumber }}</div>
            <div class="box_SP_title">
              {{ item.productName }}
            </div>
          </div>
          <div class="salesNumbersClass">月销：{{ parseInt(item.salesNumbers) }}件</div>
        </div>
      </div>
    </div>
    <div class="toViewMore" v-if="recommendationList.length > 10" @click="showHidden">
      <span v-if="showNone">点击查看</span><span v-if="!showNone">点击收起 </span>
      <div :class="['box_filter_hezi', !showNone ? 'rotate' : '']"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimilarRecommendation',
  props: {
    recommendationList: {
      type: Array,
      default: [],
    },
    codeType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showNone: true,
    };
  },
  mounted() {},
  methods: {
    // 展示隐藏大于10条的数据
    showHidden() {
      this.showNone = !this.showNone;
    },
    // 点击跳转详情页
    getitem(data) {
      if (data.classify == '2' || data.classify == '1') {
        var query = {
          id: data.id,
          styleNumber: data.styleNumber,
          eliminate: data.eliminate,
          generaName: data.generaName,
          type: this.codeType == ('current_diamond' || 'current_gold') ? '1' : '2',
          codeType: this.codeType,
          classify: data.classify,
          groupFlag: data.groupFlag,
        };
      } else {
        var queryPage = {
          id: data.id,
          eliminate: data.eliminate,
          styleNumber: data.styleNumber,
          serialNumber: data.serialNumber,
          generaName: data.generaName,
          categoryName: data.categoryName,
          type: this.codeType == ('current_diamond' || 'current_gold') ? '1' : '2',
          codeType: this.codeType,
          classify: data.classify,
          groupFlag: data.groupFlag,
        };
      }
      this.$router.push({
        path:
          data.classify == '2'
            ? '/order'
            : data.classify == '1'
            ? '/plaingold'
            : data.classify == '3'
            ? '/watch'
            : data.classify == '4'
            ? '/goldsetting'
            : '',
        query: data.classify == '2' ? query : data.classify == '1' ? query : data.classify == '3' ? queryPage : data.classify == '4' ? queryPage : '',
      });
      // let routeData = this.$router.resolve({
      //   path:
      //     data.type === '1' ? '/order' : data.type === '2' ? '/plaingold' : data.type === '3' ? '/watch' : data.type === '4' ? '/goldsetting' : '',
      //   query: data.type === '1' ? query : data.type === '2' ? query : data.type === '3' ? queryPage : data.type === '4' ? queryPage : '',
      // });
      // // 打开新页面
      // window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style scoped>
.toViewMore {
  text-align: center;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  border: 1px solid rgb(237, 226, 206);
  width: 115px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
.box_filter_hezi {
  width: 10px;
  height: 10px;
  border-top: 1px solid #999;
  border-left: 1px solid #999;
  transform: rotate(225deg);
  display: inline-block;
  margin: 0 9px 3px 12px;
  font-size: 12px;
}
.rotate {
  margin-bottom: -3px;
  transform: rotate(45deg);
}
</style>
