<template>
  <div class="custom">
    <!-- 材质 -->
    <div class="fx" v-if="dropDownOptional.handTextureList && dropDownOptional.handTextureList.length > 0">
      <div class="title">材质</div>
      <div class="material selectBox">
        <el-radio-group v-model="dataFrom.texture" size="small" @change="getSizeRange($event, 'texture', 'texture')">
          <span
            class="inchesFix"
            :class="[flagArray.textureFlag == true ? 'stockBg' : '']"
            v-for="item in dropDownOptional.handTextureList"
            :key="item"
          >
            <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
            <div class="outStore" v-if="dataFrom.texture == item && flagArray.textureFlag == true">缺货</div>
          </span>
        </el-radio-group>
      </div>
    </div>
    <!-- 辅石 -->
    <div class="fx" v-if="dropDownOptional.gemNameList && dropDownOptional.gemNameList.length > 0">
      <div class="title">辅石</div>
      <div class="material selectBox">
        <el-radio-group v-model="dataFrom.gemName" size="small" @change="getSizeRange($event, 'gemName', 'assistStoneNameOne')">
          <span class="inchesFix" :class="[flagArray.gemNameFlag == true ? 'stockBg' : '']" v-for="item in dropDownOptional.gemNameList" :key="item">
            <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
            <div class="outStore" v-if="dataFrom.gemName == item && flagArray.gemNameFlag == true">缺货</div>
          </span>
        </el-radio-group>
      </div>
    </div>
    <!-- 版型 -->
    <div class="fx" v-if="dropDownOptional.formatTypes && dropDownOptional.formatTypes.length > 0">
      <div class="title">版型</div>
      <div class="material selectBox">
        <el-radio-group v-model="dataFrom.formatType" size="small" @change="getSizeRange($event, 'formatType', 'formatType')">
          <span
            class="inchesFix"
            :class="[flagArray.formatTypeFlag == true ? 'stockBg' : '']"
            v-for="item in dropDownOptional.formatTypes"
            :key="item"
          >
            <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
            <div class="outStore" v-if="dataFrom.formatType == item && flagArray.formatTypeFlag == true">缺货</div>
          </span>
        </el-radio-group>
      </div>
    </div>
    <!-- 主石重量 -->
    <div class="fx" v-if="dropDownOptional.mainStoneList && dropDownOptional.mainStoneList.length > 0">
      <div class="title">主石重量</div>
      <div class="material selectBox">
        <el-radio-group v-model="dataFrom.mainStone" size="small" @change="getSizeRange($event, 'mainStone', 'mainStone')">
          <span
            class="inchesFix"
            :class="[flagArray.mainStoneFlag == true ? 'stockBg' : '']"
            v-for="item in dropDownOptional.mainStoneList"
            :key="item"
          >
            <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
            <div class="outStore" v-if="dataFrom.mainStone == item && flagArray.mainStoneFlag == true">缺货</div>
          </span>
        </el-radio-group>
      </div>
    </div>
    <!-- 净度 -->
    <div class="fx" v-if="dropDownOptional.mainStoneClarity && dropDownOptional.mainStoneClarity.length > 0">
      <div class="title">净度</div>
      <div class="material selectBox">
        <el-radio-group v-model="dataFrom.mainStoneClarity" size="small" @change="getSizeRange($event, 'mainStoneClarity', 'mainStoneClarity')">
          <span
            class="inchesFix"
            :class="[flagArray.clarityFlag == true ? 'stockBg' : '']"
            v-for="item in dropDownOptional.mainStoneClarity"
            :key="item"
          >
            <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
            <div class="outStore" v-if="dataFrom.mainStoneClarity == item && flagArray.clarityFlag == true">缺货</div>
          </span>
        </el-radio-group>
      </div>
    </div>
    <!-- 颜色 -->
    <div class="fx" v-if="dropDownOptional.mainStoneColour && dropDownOptional.mainStoneColour.length > 0">
      <div class="title">颜色</div>
      <div class="material selectBox">
        <el-radio-group v-model="dataFrom.mainStoneColour" size="small" @change="getSizeRange($event, 'mainStoneColour', 'mainStoneColor')">
          <span
            class="inchesFix"
            :class="[flagArray.colourFlag == true ? 'stockBg' : '']"
            v-for="item in dropDownOptional.mainStoneColour"
            :key="item"
          >
            <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
            <div class="outStore" v-if="dataFrom.mainStoneColour == item && flagArray.colourFlag == true">缺货</div>
          </span>
        </el-radio-group>
      </div>
    </div>
    <!-- 规格 -->
    <div class="fx" v-if="dropDownOptional.handSizeList && dropDownOptional.handSizeList.length > 0">
      <div class="title">
        规格<span class="inchesTips" v-if="dropDownOptional.handSizeList && dropDownOptional.handSizeList[0].includes('寸')">(1英寸＝2.54厘米)</span>
      </div>
      <div class="material selectBox">
        <el-radio-group v-model="dataFrom.ringSize" size="small" @change="getSizeRange($event, 'ringSize', 'spec')">
          <span class="inchesFix" :class="[flagArray.sizeFlag == true ? 'stockBg' : '']" v-for="item in dropDownOptional.handSizeList" :key="item">
            <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
            <div class="outStore" v-if="dataFrom.ringSize == item && flagArray.sizeFlag == true">缺货</div>
          </span>
        </el-radio-group>
      </div>
    </div>
    <!-- gia -->
    <div class="fx" v-if="dropDownOptional.gia && dropDownOptional.gia.length > 0">
      <div class="title">GIA</div>
      <div class="material selectBox">
        <el-radio-group v-model="dataFrom.gia" size="small" @change="getSizeRange($event, 'gia', 'gia')">
          <span class="inchesFix" :class="[flagArray.giaFlag == true ? 'stockBg' : '']" v-for="item in dropDownOptional.gia" :key="item">
            <el-radio-button :value="item" :label="item">{{ item | cutFilter }}</el-radio-button>
            <div class="outStore" v-if="dataFrom.gia == item && flagArray.giaFlag == true">缺货</div>
          </span>
        </el-radio-group>
      </div>
    </div>
    <!-- 备注 -->
    <div class="textareaRemark">
      <div class="title">备注</div>
      <el-input
        :rows="6"
        :maxlength="64"
        type="textarea"
        placeholder="其他要求与说明请在此处备注说明,字符数限制64位"
        v-model="dataFrom.productRemark"
      ></el-input>
    </div>
    <div class="fl" style="justify-content: end;">
      <div
        style="color:red;margin-right:20px"
        v-if="
          (dataFrom.tradePrice && (!hasPermission('展厅') || !hasPermission('加盟公司总经理'))) ||
            (dataFrom.labelPrice && (!dataFrom.exclusivePrice || dataFrom.exclusivePrice == 0)) ||
            (dataFrom.exclusivePrice && dataFrom.exclusivePrice != 0)
        "
      >
        (预估价格，实际价格请以结算为准)
      </div>
      <div>
        <!-- 批发价格 -->
        <div class="box_detail_input marginFx" v-if="dataFrom.tradePrice && (!hasPermission('展厅') || !hasPermission('加盟公司总经理'))">
          <div class="input-wrap">批发价格：{{ dataFrom.tradePrice }}</div>
        </div>
        <!-- 标签价格 -->
        <div class="box_detail_input marginFx" v-if="dataFrom.labelPrice && (!dataFrom.exclusivePrice || dataFrom.exclusivePrice == 0)">
          <div class="input-wrap">标签价格：{{ dataFrom.labelPrice }}</div>
        </div>
        <!-- 尊享价 -->
        <div class="box_detail_input marginFx" v-if="dataFrom.exclusivePrice && dataFrom.exclusivePrice != 0">
          <div class="input-wrap">尊享价：{{ dataFrom.exclusivePrice }}</div>
        </div>
      </div>
    </div>

    <!-- 下单件数 -->
    <div class="box_detail_input marginFx">
      <div class="input-wrap">
        <span class="prompt">库存{{ dropDownOptional.stockQuantity }}件，可选择不同规格</span>
        <span class="prompt placeNum">购买数量：</span>
        <el-input-number
          v-model="dataFrom.quantity"
          :min="0"
          :max="dropDownOptional.stockQuantity"
          @change="getSizeRange($event, 'quantity')"
          label="描述文字"
        ></el-input-number>
      </div>
    </div>
  </div>
</template>
<script>
import { cutFilter } from '@/utils/filetrs';
import { getSpotSpecification, getPriceRangeSpot } from '@/api/detailsApi';
import { deepClone } from '@/utils/util';
import { debounce } from '@/utils/index';
export default {
  name: 'Spot',
  props: {
    DataList: {
      type: Object,
      default() {
        return {};
      },
    },
    leastQuantity: {
      type: Number,
      default: 0,
    },
    stockQuantityCut: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dataFrom: {
        styleNumber: this.DataList.styleNumber, // 公司款号
        productName: this.DataList.productName,
        isCouples: this.DataList.isCouples, // 是否情侣款
        classify: 2, // 产品结构
        quantity: 0, // 数量
        shopType: 1,
        spotFlag: 2,
        ringSize: '',
        goldWeigh: '',
        productRemark: '',
        ringSize: '',
        texture: '',
        gemName: '',
        formatType: '',
        mainStone: '',
        mainStoneClarity: null,
        mainStoneColour: null,
        gia: '',
        productRemark: '',
        shopType: 1,
        quantity: 0,
        tradePrice: '',
        labelPrice: '',
        exclusivePrice: '',
      },
      dropDownOptional: {
        handTextureList: [],
        mainStoneList: [],
        handSizeList: [],
        mainStoneClarity: [],
        mainStoneColour: [],
        gia: [],
        gemNameList: [],
        formatTypes: [],
        stockQuantity: 0,
      },
      mainStoneList: [],
      handTextureList: [],
      handSizeList: [],
      textureIndex: 0,
      spotList: [],
      flagArray: {
        textureFlag: false,
        mainStoneFlag: false,
        sizeFlag: false,
        colourFlag: false,
        clarityFlag: false,
        giaFlag: false,
        gemNameFlag: false,
        formatTypeFlag: false,
      },
    };
  },
  filters: {
    cutFilter,
  },
  mounted() {
    this.dataFrom = { ...this.dataFrom, ...this.DataList };
    // 获取可选属性
    this.getOptional();
  },
  methods: {
    // 金重排序
    mainStoneSort(list) {
      // 1. 将每个区间字符串解析为对象
      const parsedRanges = list?.map((range) => {
        const [start, end] = range.split('-').map(parseFloat);
        return { start, end, range }; // 保留原始字符串以便最后恢复
      });
      // 2. 根据 start 值进行排序
      parsedRanges.sort((a, b) => a.start - b.start);
      // 3. 提取排序后的原始字符串
      return parsedRanges.map((item) => item.range);
    },
    getParameter(spotList, gender) {
      spotList?.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (key && key == 'texture' && item[key] && !this.dropDownOptional.handTextureList?.includes(item[key])) {
            this.dropDownOptional.handTextureList?.push(item[key]);
          }
          if (key && key == 'spec' && item[key] && !this.dropDownOptional.handSizeList?.includes(item[key])) {
            this.dropDownOptional.handSizeList?.push(item[key]);
            // 规格重新排序
            this.dropDownOptional.handSizeList?.sort(function(a, b) {
              return a - b;
            });
          }
          if (key && key == 'mainStoneWeight' && item[key] && !this.dropDownOptional.mainStoneList?.includes(item[key])) {
            this.dropDownOptional.mainStoneList?.push(item[key]);
            // 主石重新排序
            this.dropDownOptional.mainStoneList?.sort(function(a, b) {
              return a - b;
            });
          }
          if (key && key == 'mainStoneClarity' && item[key] && !this.dropDownOptional.mainStoneClarity?.includes(item[key])) {
            this.dropDownOptional.mainStoneClarity?.push(item[key]);
          }
          if (key && key == 'mainStoneColor' && item[key] && !this.dropDownOptional.mainStoneColour?.includes(item[key])) {
            this.dropDownOptional.mainStoneColour?.push(item[key]);
          }
          if (key && key == 'gia' && item[key] && !this.dropDownOptional.gia?.includes(item[key])) {
            this.dropDownOptional.gia?.push(item[key]);
          }
          if (key && key == 'assistStoneNameOne' && item[key] && !this.dropDownOptional.gemNameList?.includes(item[key])) {
            this.dropDownOptional.gemNameList?.push(item[key]);
          }
          if (key && key == 'formatType' && item[key] && !this.dropDownOptional.formatTypes?.includes(item[key])) {
            this.dropDownOptional.formatTypes?.push(item[key]);
          }
        });
      });
      this.dropDownOptional.mainStoneClarity = [...new Set(this.dropDownOptional.mainStoneClarity)];
      this.dropDownOptional.mainStoneColour = [...new Set(this.dropDownOptional.mainStoneColour)];
      this.dropDownOptional.formatTypes = this.sortByChineseSize(this.dropDownOptional.formatTypes);
    },
    // 版型排序
    sortByChineseSize(list) {
      const orderMap = { 小: 1, 中: 2, 大: 3 };
      return list.slice().sort((a, b) => orderMap[a] - orderMap[b]);
    },
    // 获取可选属性
    getOptional(gender) {
      let obj = {
        styleNumber: this.DataList.styleNumber,
        classify: 2,
        spotFlag: 1,
        gender: gender,
      };
      getSpotSpecification(obj).then((res) => {
        if (!gender || gender == '女') {
          this.spotList.splice(0, this.spotList.length);
          res.data.records.forEach((item) => {
            if (!item.mainStoneClarity == null || item.mainStoneClarity == undefined) {
              item.mainStoneClarity = '';
            }
            if (item.mainStoneColor == null || item.mainStoneColor == undefined) {
              item.mainStoneColor = '';
            }
            this.spotList.push(item);
          });
          this.dropDownOptional.stockQuantity = this.stockQuantityCut;
          this.getParameter(this.spotList, gender);
        }
      });
    },
    getSizeRange(value, type) {
      this.dataFrom[type] = value;
      this.dataProcessing(this.spotList, value, 'dataFrom', type);
      this.fetchPriceRangeSpot();
    },
    fetchPriceRangeSpot() {
      const obj = deepClone(this.dataFrom);
      if (!obj.mainStone || obj.mainStone == '' || !obj.gia || obj.gia == '') return;
      getPriceRangeSpot(obj).then((res) => {
        this.dropDownOptional.stockQuantity = res.data.stockQuantity;
        this.dataFrom.tradePrice = res.data.tradePrice;
        this.dataFrom.labelPrice = res.data.labelPrice;
        this.dataFrom.exclusivePrice = res.data.exclusivePrice;
        if (res.data.stockQuantity > this.leastQuantity && this.dataFrom.quantity > res.data.stockQuantity) {
          this.dataFrom.quantity = res.data.stockQuantity;
        }
        if (res.data.stockQuantity < this.leastQuantity) {
          this.dataFrom.quantity = this.leastQuantity;
        }
      });
    },
    dataProcessing(spotList, item, type, select) {
      if (select == 'quantity') return;
      const shopArray = [];
      shopArray.splice(0, shopArray.length);
      if (!item) {
        shopArray.push(...spotList);
      } else {
        spotList?.forEach((res) => {
          Object.keys(res).forEach((key) => {
            if (item == res[key] && key != 'accessoryStone' && key != 'assistStoneNameTwo') {
              shopArray.push(res);
            }
          });
        });
      }
      if (shopArray.length == spotList.length) {
        this.fetchPriceRangeSpot();
        return;
      }
      if (this[type].mainStone) {
        this.flagArray.mainStoneFlag = !shopArray.some((product) => product.mainStoneWeight === this[type].mainStone);
      }
      if (this[type].texture) {
        this.flagArray.textureFlag = !shopArray.some((product) => product.texture === this[type].texture);
      }
      if (this[type].ringSize) {
        this.flagArray.sizeFlag = !shopArray.some((product) => product.spec === this[type].ringSize);
      }
      if (this[type].mainStoneClarity) {
        this.flagArray.clarityFlag = !shopArray.some((product) => product.mainStoneClarity === this[type].mainStoneClarity);
      }
      if (this[type].mainStoneColour) {
        this.flagArray.colourFlag = !shopArray.some((product) => product.mainStoneColor === this[type].mainStoneColour);
      }
      if (this[type].gia) {
        this.flagArray.giaFlag = !shopArray.some((product) => product.gia === this[type].gia);
      }
      if (this[type].gemName) {
        this.flagArray.gemNameFlag = !shopArray.some((product) => product.assistStoneNameOne === this[type].gemName);
      }
      if (this[type].formatType) {
        this.flagArray.formatTypeFlag = !shopArray.some((product) => product.formatType === this[type].formatType);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.custom {
  .fx {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    position: relative;
    margin: 8px 0;
  }

  .fl {
    display: flex;
    align-items: center;
    position: relative;
  }

  .selectBox {
    ::v-deep .el-popper {
      left: 0 !important;
    }

    .el-scrollbar__view li {
      width: 178px;
    }
  }

  .selectBoxWatch {
    .el-scrollbar__view li {
      min-width: 90px;
    }
  }
  .inchesFix {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    .outStore {
      font-size: 12px;
      position: absolute;
      right: -1px;
      top: -1px;
      color: #999999;
    }
  }
  /deep/ .stockBg .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #c0c4cc;
    background-color: #f2f6fc;
    border-color: #f2f6fc;
  }
  .inchesTips {
    color: red;
    font-size: 12px;
    margin-left: 4px;
  }
  .material {
    width: 800px;
    ::v-deep .el-radio-button__inner {
      min-width: 65px;
    }
  }
  .title {
    font-size: 18px;
    color: #000;
    display: inline-block;
    margin-bottom: 8px;
    .hidden {
      visibility: hidden;
    }
  }

  .value-font {
    font-size: 18px;
    color: #ec9368;
  }
  .mainStoneClssBoxFx {
    width: 675px;
    display: flex;
    align-items: center;
    position: relative;
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #fff;
    background-color: #ec9368;
    border-color: #ec9368;
    box-shadow: none;
  }
  ::v-deep .el-radio-button__inner:hover {
    color: rgb(236, 147, 104);
  }
  ::v-deep .el-radio-button__inner {
    margin: 5px;
    border-left: 1px solid #dcdfe6;
    border-radius: 4px;
  }
  ::v-deep .inchesFix:first-child .el-radio-button__inner {
    margin-left: 0;
  }
  ::v-deep .el-radio-group {
    display: flex;
    flex-wrap: wrap;
  }
  .input-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
}
</style>
