<template>
  <div class="custom">
    <!-- 材质 -->
    <div class="fx" v-if="handTextureList && handTextureList.length > 0">
      <div class="title">材质</div>
      <div class="material selectBox">
        <el-radio-group v-model="dataFrom.texture" size="small" @change="getSizeRange($event, 'texture')">
          <span class="inchesFix">
            <el-radio-button v-for="item in handTextureList" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
          </span>
        </el-radio-group>
      </div>
    </div>
    <!-- 辅石 -->
    <div class="fx" v-if="dropDownOptional.gemNameList && dropDownOptional.gemNameList.length > 0">
      <div class="title">辅石</div>
      <div class="material selectBox">
        <el-radio-group v-model="dataFrom.gemName" size="small" @change="getSizeRange($event, 'gemName')">
          <span class="inchesFix">
            <el-radio-button v-for="item in dropDownOptional.gemNameList" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
          </span>
        </el-radio-group>
      </div>
    </div>
    <!-- 版型 -->
    <div class="fx" v-if="dropDownOptional.formatTypes && dropDownOptional.formatTypes.length > 0">
      <div class="title">版型</div>
      <div class="material selectBox">
        <el-radio-group v-model="dataFrom.formatType" size="small" @change="getSizeRange($event, 'formatType')">
          <span class="inchesFix">
            <el-radio-button v-for="item in dropDownOptional.formatTypes" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
          </span>
        </el-radio-group>
      </div>
    </div>
    <!-- 主石重量 -->
    <div class="fx" v-if="mainStoneList && mainStoneList.length > 0">
      <div class="title">主石重量</div>
      <div class="material selectBox">
        <el-radio-group v-model="dataFrom.mainStone" size="small" @change="getSizeRange($event, 'mainStone')">
          <span class="inchesFix">
            <el-radio-button v-for="item in mainStoneList" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
          </span>
        </el-radio-group>
      </div>
    </div>
    <!-- 净度 -->
    <div class="fx" v-if="dropDownOptional.mainStoneClarity && dropDownOptional.mainStoneClarity.length > 0">
      <div class="title">净度</div>
      <div class="material selectBox">
        <el-radio-group v-model="dataFrom.mainStoneClarity" size="small" @change="getSizeRange($event, 'mainStoneClarity')">
          <span class="inchesFix">
            <el-radio-button v-for="item in dropDownOptional.mainStoneClarity" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
          </span>
        </el-radio-group>
      </div>
    </div>
    <!-- 颜色 -->
    <div class="fx" v-if="dropDownOptional.mainStoneColour && dropDownOptional.mainStoneColour.length > 0">
      <div class="title">颜色</div>
      <div class="material selectBox">
        <el-radio-group v-model="dataFrom.mainStoneColour" size="small" @change="getSizeRange($event, 'mainStoneColour')">
          <span class="inchesFix">
            <el-radio-button v-for="item in dropDownOptional.mainStoneColour" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
          </span>
        </el-radio-group>
      </div>
    </div>
    <!-- 规格 -->
    <div class="fx" v-if="handSizeList && handSizeList.length > 0">
      <div class="title">规格<span class="inchesTips" v-if="handSizeList && handSizeList[0].includes('寸')">(1英寸＝2.54厘米)</span></div>
      <div class="material selectBox">
        <el-radio-group v-model="dataFrom.ringSize" size="small" @change="getSizeRange($event, 'ringSize')">
          <span class="inchesFix">
            <el-radio-button v-for="item in handSizeList" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
          </span>
        </el-radio-group>
      </div>
    </div>
    <!-- gia -->
    <div class="fx" v-if="dropDownOptional.gia && dropDownOptional.gia.length > 0">
      <div class="title">GIA</div>
      <div class="material selectBox">
        <el-radio-group v-model="dataFrom.gia" size="small" @change="getSizeRange($event, 'gia')">
          <span class="inchesFix">
            <el-radio-button v-for="item in dropDownOptional.gia" :key="item" :value="item" :label="item">{{ item | cutFilter }}</el-radio-button>
          </span>
        </el-radio-group>
      </div>
    </div>
    <!-- 备注 -->
    <div class="textareaRemark">
      <div class="title">备注</div>
      <el-input
        :rows="6"
        :maxlength="64"
        type="textarea"
        placeholder="其他要求与说明请在此处备注说明,字符数限制64位"
        v-model="dataFrom.productRemark"
      ></el-input>
    </div>
    <div class="fl" style="justify-content: end;">
      <div
        style="color:red;margin-right:20px"
        v-if="
          (dataFrom.tradePrice && (!hasPermission('展厅') || !hasPermission('加盟公司总经理'))) ||
            (dataFrom.labelPrice && (!dataFrom.exclusivePrice || dataFrom.exclusivePrice == 0)) ||
            (dataFrom.exclusivePrice && dataFrom.exclusivePrice != 0)
        "
      >
        (预估价格，实际价格请以结算为准)
      </div>
      <div>
        <!-- 批发价格 -->
        <div class="box_detail_input marginFx" v-if="dataFrom.tradePrice && (!hasPermission('展厅') || !hasPermission('加盟公司总经理'))">
          <div class="input-wrap">批发价格：{{ dataFrom.tradePrice }}</div>
        </div>
        <!-- 标签价格 -->
        <div class="box_detail_input marginFx" v-if="dataFrom.labelPrice && (!dataFrom.exclusivePrice || dataFrom.exclusivePrice == 0)">
          <div class="input-wrap">标签价格：{{ dataFrom.labelPrice }}</div>
        </div>
        <!-- 尊享价 -->
        <div class="box_detail_input marginFx" v-if="dataFrom.exclusivePrice && dataFrom.exclusivePrice != 0">
          <div class="input-wrap">尊享价：{{ dataFrom.exclusivePrice }}</div>
        </div>
      </div>
    </div>
    <!-- 下单件数 -->
    <div class="box_detail_input marginFx">
      <div class="input-wrap">
        <span class="prompt groupColor" v-if="groupFlag != 1">起订量{{ leastQuantity }}件，可定单不同规格</span>
        <span class="prompt groupColor" v-if="groupFlag == 1">注：拼团有效期为10天，逾期则拼团失败（还差{{ dataFrom.quantityToGroup }}件成团）</span>
        <el-input-number
          v-model="dataFrom.quantity"
          @input.native="onNative($event)"
          @keyup.enter.native="getSizeRange($event, 'quantity')"
          @change="getSizeRange($event, 'quantity')"
          :min="groupFlag == 1 ? 1 : leastQuantity"
          :max="9999"
          label="描述文字"
        ></el-input-number>
      </div>
    </div>
  </div>
</template>
<script>
import { cutFilter } from '@/utils/filetrs';
import { getOptional, getPriceRange } from '@/api/detailsApi';
import { deepClone } from '@/utils/util';
import { debounce } from '@/utils/index';
export default {
  name: 'Custom',
  props: {
    DataList: {
      type: Object,
      default() {
        return {};
      },
    },
    leastQuantity: {
      type: Number,
      default: 0,
    },
    groupFlag: {
      type: String,
      default: 0,
    },
  },
  data() {
    return {
      dataFrom: {
        styleNumber: this.DataList.styleNumber, // 公司款号
        productName: this.DataList.productName,
        isCouples: this.DataList.isCouples, // 是否情侣款
        classify: 2, // 产品结构
        quantity: 0, // 数量
        shopType: 1,
        spotFlag: 2,
        ringSize: '',
        goldWeigh: '',
        productRemark: '',
        ringSize: '',
        texture: '',
        gemName: '',
        formatType: '',
        mainStone: '',
        mainStoneClarity: '',
        mainStoneColour: '',
        gia: '',
        productRemark: '',
        shopType: 1,
        quantity: 0,
        tradePrice: '',
        labelPrice: '',
        exclusivePrice: '',
      },
      dropDownOptional: {},
      mainStoneList: [],
      handTextureList: [],
      handSizeList: [],
      textureIndex: 0,
    };
  },
  filters: {
    cutFilter,
  },
  mounted() {
    this.dataFrom = { ...this.dataFrom, ...this.DataList };
    // 获取可选属性
    this.getOptional();
  },
  methods: {
    // 金重排序
    mainStoneSort(list) {
      // 1. 将每个区间字符串解析为对象
      const parsedRanges = list?.map((range) => {
        const [start, end] = range.split('-').map(parseFloat);
        return { start, end, range }; // 保留原始字符串以便最后恢复
      });
      // 2. 根据 start 值进行排序
      parsedRanges.sort((a, b) => a.start - b.start);
      // 3. 提取排序后的原始字符串
      return parsedRanges.map((item) => item.range);
    },
    // 规格重新排序
    sortFun(handSizeList) {
      return handSizeList?.sort(function(a, b) {
        return a - b;
      });
    },
    // 获取可选属性
    getOptional(text) {
      let obj = {
        styleNumber: this.DataList.styleNumber,
        classify: 2,
        spotFlag: 2,
        gender: '',
      };
      getOptional(obj).then((res) => {
        this.dropDownOptional = { ...this.dropDownOptional, ...res.data };
        this.handTextureList = Object.keys(res.data.handSizeMap);
        this.mainStoneList = this.mainStoneSort(Object.keys(Object.values(res.data.handSizeMap)[0]));
        this.handSizeList = this.sortFun(Object.values(Object.values(Object.values(res.data.handSizeMap)[0]))[0]);
      });
    },
    // 实时监听 防抖debounce
    onNative: debounce(
      function(event) {
        if (this.groupFlag == 1) return;
        if (Number(event.target.value)) {
          if (this.dataFrom.spotFlag == 2 && event.target.value > 9999) {
            this.dataFrom.quantity = 9999;
          } else if (this.dataFrom.spotFlag == 1 && event.target.value > this.dropDownOptional.stockQuantity) {
            this.dataFrom.quantity = this.dropDownOptional.stockQuantity;
          }
        }
      },
      500,
      true
    ),
    getSizeRange(value, type) {
      this.dataFrom[type] = value;
      if (type === 'texture') {
        this.textureIndex = this.handTextureList.indexOf(value);
        this.mainStoneList = this.mainStoneSort(Object.keys(Object.values(this.dropDownOptional.handSizeMap)[this.handTextureList.indexOf(value)]));
        this.handSizeList = this.sortFun(Object.values(Object.values(Object.values(this.dropDownOptional?.handSizeMap)[this.textureIndex]))[0]);
        this.dataFrom.mainStone = '';
        this.dataFrom.ringSize = '';
      }
      if (type === 'mainStone') {
        this.handSizeList = this.sortFun(
          Object.values(Object.values(Object.values(this.dropDownOptional?.handSizeMap)[this.textureIndex]))[this.mainStoneList.indexOf(value)]
        );
        this.dataFrom.ringSize = '';
      }
      this.fetchPriceRangeCustom();
    },
    fetchPriceRangeCustom() {
      const obj = deepClone(this.dataFrom);
      getPriceRange(obj).then((res) => {
        this.dataFrom.tradePrice = res.data.tradePrice;
        this.dataFrom.labelPrice = res.data.labelPrice;
        this.dataFrom.exclusivePrice = res.data.exclusivePrice;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.custom {
  .fx {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    position: relative;
    margin: 8px 0;
  }

  .fl {
    display: flex;
    align-items: center;
    position: relative;
  }

  .selectBox {
    ::v-deep .el-popper {
      left: 0 !important;
    }

    .el-scrollbar__view li {
      width: 178px;
    }
  }

  .selectBoxWatch {
    .el-scrollbar__view li {
      min-width: 90px;
    }
  }
  .inchesFix {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .inchesTips {
    color: red;
    font-size: 12px;
    margin-left: 4px;
  }
  .material {
    width: 800px;
    ::v-deep .el-radio-button__inner {
      min-width: 65px;
    }
  }
  .title {
    font-size: 18px;
    color: #000;
    display: inline-block;
    margin-bottom: 8px;
    .hidden {
      visibility: hidden;
    }
  }

  .value-font {
    font-size: 18px;
    color: #ec9368;
  }
  .mainStoneClssBoxFx {
    width: 675px;
    display: flex;
    align-items: center;
    position: relative;
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #fff;
    background-color: #ec9368;
    border-color: #ec9368;
    box-shadow: none;
  }
  ::v-deep .el-radio-button__inner:hover {
    color: rgb(236, 147, 104);
  }
  ::v-deep .el-radio-button__inner {
    margin: 5px;
    border-left: 1px solid #dcdfe6;
    border-radius: 4px;
  }
  ::v-deep .inchesFix:first-child .el-radio-button__inner {
    margin-left: 0;
  }
  ::v-deep .el-radio-group {
    display: flex;
    flex-wrap: wrap;
  }
  .input-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
}
</style>
