import router from './router';
import NProgress from 'nprogress';
import store from '@/store';
import 'nprogress/nprogress.css';
import { getToken } from '@/utils/auth';
import { Message } from 'element-ui';

router.beforeEach((to, from, next) => {
  NProgress.start();
  const hasToken = getToken();
  if (hasToken) {
    if (!store.getters.userData?.isUpdateUserInfo) {
      store
        .dispatch('GetRole')
        .then(() => {
          next();
        })
        .catch(() => {
          store.dispatch('FedLogOut').then((err) => {
            router.push({ path: '/login' });
            NProgress.done();
          });
        });
      store.dispatch('GetType').then(() => {
        next();
      });
      store
        .dispatch('GetUserInfo')
        .then(() => {
          next();
        })
        .catch(() => {
          store.dispatch('FedLogOut').then((err) => {
            Message.error(err || '无效信息，请重新登录');
            next({ path: '/' });
          });
        });
    } else {
      next();
    }
  } else {
    if (to.path === '/login' || to.path === '/yunLogin') {
      next();
    } else {
      next({ path: '/login' });
    }
  }
});
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});
router.afterEach((to, from) => {
  NProgress.done();
});
