import request from '@/utils/request';

// 获取首页轮播图
export function getBenner() {
  return request({
    url: `/exhibition/banner/listByCondition?systemType=1&columnType=3`,
    method: 'get',
  });
}

// 获取首页栏目
export function getColumn() {
  return request({
    url: '/exhibition/column',
    method: 'get',
  });
}

// 获取筛选条件
export function getScreening(data) {
  return request({
    // url: `/exhibition/category/repertory`,
    // url: `/exhibition/category/repertoryNew`,
    url: `/exhibition/hall-product-category/selectCategory`,
    method: 'get',
    params: data,
  });
}
// 商品列表页--新品推荐-热销列表获得大类筛选
export const getSelectDefault = (data) => {
  return request({
    url: '/exhibition/hall-product-category/selectDefault',
    method: 'put',
    data,
  });
};
// 获取明星同款品类
export const getCategoryList = (data) => {
  return request({
    url: `/exhibition/product/getProductCategoryList/${data.type}`,
    method: 'get',
    params: data,
  });
};
// 列表
export function getGoodsList(data) {
  return request({
    url: `/exhibition/product?current=${data.current}&size=${data.size}`,
    method: 'post',
    data,
  });
}

// 获取普通款筛选条件
export function getFilterCond() {
  return request({
    url: '/exhibition/cloudhall/goods/criteria',
    method: 'get',
    params: {},
  });
}

// 获取素金筛选条件
export function getPlainCond() {
  return request({
    url: '/exhibition/cloudhall/goods/goldCriteria',
    method: 'get',
    params: {},
  });
}
// 输入框商品
export function getKeyWord(data) {
  return request({
    url: '/exhibition/cloudhall/goods/search?pageSize=' + data.pageSize + '&pageNum=' + data.pageNum,
    method: 'post',
    data,
  });
}
// 获取爪型筛选条件
export function getClawList() {
  return request({
    url: '/exhibition/term/goods/claw',
    method: 'get',
    params: {},
  });
}
// 获取臂型筛选条件
export function getArmTypeList() {
  return request({
    url: '/exhibition/term/goods/arm',
    method: 'get',
    params: {},
  });
}
// 获取系列名称
export function getSeriesName() {
  return request({
    url: '/exhibition/term/goods/series',
    method: 'get',
    params: {},
  });
}
// 隐私政策文件
export function getPolicyFile() {
  return request({
    url: '/admin/dict/type/privacy_policy_file',
    cache: true,
    method: 'get',
  });
}
// 同意隐私协议
export function agreePrivacy(data) {
  return request({
    url: '/admin/user-detail/updatePrivacyAgreementReadTime',
    method: 'post',
    data,
  });
}
// 获取全部大区
export function getAreaMenu() {
  return request({
    url: '/exhibition/product/queryInitArea',
    cache: true,
    method: 'get',
  });
}
// 根据大区获取省份
export function getCity(data) {
  return request({
    // url: '/exhibition/product/getProvinceList',
    url: '/exhibition/product/queryProvincesByArea',
    method: 'post',
    data,
  });
}
