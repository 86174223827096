<template>
  <el-dialog
    title="隐私协议更新提示："
    :visible.sync="dialogVisible"
    width="30%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    append-to-body
    destroy-on-close
    :before-close="handleClose"
  >
    <div class="privacy-container">
      <p>亲爱的用户，感谢您信任并使用周六福珠宝云展厅！</p>
      <p>
        我们依据相关法律法规要求制定了
        <a :href="fileUrl" target="_blank" style="color: #50a6ff"> 《隐私政策》 </a>，请您在点击同意前仔细阅读并充分理解相关条款。
      </p>
      <p>
        若您同意以上协议内容，请点击“同意”并开始使用我们的产品和服务。若您不同意《隐私政策》，您的平台功能可能受限。为了您能够体验更好的服务，我们建议您点击同意隐私政策并继续使用我们完整的服务。
      </p>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">不同意</el-button>
      <el-button type="primary" @click="handleAgree" :loading="subLoading">同意</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { getPolicyFile, agreePrivacy } from '@/api/homeApi';

export default {
  data() {
    return {
      dialogVisible: false,
      fileUrl: null,
      subLoading: false,
    };
  },
  methods: {
    getPolicy() {
      return new Promise((resolve, reject) => {
        getPolicyFile()
          .then((res) => {
            if (res.data) {
              res.data.map((item) => {
                if (item.label == 'pos') this.fileUrl = item.value;
              });
              resolve(true);
            }
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    handleClose() {
      this.$store.dispatch('LogOut').then(() => {
        this.$router.push({ path: '/login' });
        this.dialogVisible = false;
      });
    },
    handleAgree() {
      this.subLoading = true;
      agreePrivacy({ platformType: 'EH_PC' })
        .then((res) => {
          if (res.code == 0) {
            this.$store.dispatch('GetUserInfo').then(() => {
              this.dialogVisible = false;
              this.subLoading = false;
            });
          }
        })
        .catch((err) => {
          this.subLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.privacy-container {
  color: #6e6a6a;
}
.el-button--primary {
  background: #ec9368;
}
.el-button--primary:hover {
  background: #ec9368;
}
.el-button--primary:focus {
  background: #ec9368;
}
</style>
