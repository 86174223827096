<template>
  <div class="LoversCustom">
    <div class="loversBox">
      <div class="loversBox_title">
        <div class="loversBox_title_one">参数信息</div>
        <div class="loversBox_title_two noMaleRing">
          女款
        </div>
        <div class="loversBox_title_three noMaleRing">
          男款
        </div>
      </div>
      <div class="loversBox_title">
        <div class="loversBox_title_one">款<span class="hidden">间隔</span>式</div>
        <div class="loversBox_title_two">
          <span class="title">
            女
          </span>
        </div>
        <div class="loversBox_title_three">
          <span class="title">
            男
          </span>
        </div>
      </div>
      <div class="loversBox_title">
        <div class="loversBox_title_one">
          表盘颜色
        </div>
        <div class="loversBox_title_two">
          <span class="title">{{ femaleDataFrom.dialColor }}</span>
        </div>
        <div class="loversBox_title_three">
          <span class="title">{{ maleDataFrom.dialColor }}</span>
        </div>
      </div>
      <div class="loversBox_title">
        <div class="loversBox_title_one">表带颜色</div>
        <div class="loversBox_title_two">
          <div class="loversSelectBox">
            <el-select :popper-append-to-body="false" v-model="femaleDataFrom.bandColor" placeholder clearable>
              <div>
                <el-option v-for="item in femalePullDownData.bandColor" :key="item" :label="item" :value="item"></el-option>
              </div>
            </el-select>
          </div>
        </div>
        <div class="loversBox_title_three">
          <div class="loversSelectBox">
            <el-select :popper-append-to-body="false" v-model="maleDataFrom.bandColor" placeholder clearable>
              <div>
                <el-option v-for="item in malePullDownData.bandColor" :key="item" :label="item" :value="item"></el-option>
              </div>
            </el-select>
          </div>
        </div>
      </div>
      <div class="loversBox_title">
        <div class="loversBox_title_one">
          手表类型
        </div>
        <div class="loversBox_title_two">
          <span class="title">{{ femaleDataFrom.mechanicalType }}</span>
        </div>
        <div class="loversBox_title_three">
          <span class="title">{{ maleDataFrom.mechanicalType }}</span>
        </div>
      </div>
      <div class="loversBox_title">
        <div class="loversBox_title_one">
          表带材质
        </div>
        <div class="loversBox_title_two">
          <span class="title">{{ femaleDataFrom.bandMaterial }}</span>
        </div>
        <div class="loversBox_title_three">
          <span class="title">{{ maleDataFrom.bandMaterial }}</span>
        </div>
      </div>
      <div class="loversBox_title">
        <div class="loversBox_title_one">材<span class="hidden">间隔</span>质</div>
        <div class="loversBox_title_two">
          <span class="title">{{ femaleDataFrom.texture }}</span>
        </div>
        <div class="loversBox_title_three">
          <span class="title">{{ maleDataFrom.texture }}</span>
        </div>
      </div>
      <div class="loversBox_title">
        <div class="loversBox_title_one">金<span class="hidden">间隔</span>重</div>
        <div class="loversBox_title_two">
          <span class="title">{{ femaleDataFrom.goldWeigh }}</span>
        </div>
        <div class="loversBox_title_three">
          <span class="title">{{ maleDataFrom.goldWeigh }}</span>
        </div>
      </div>
      <div class="loversBox_title">
        <div class="loversBox_title_one">
          下单件数
        </div>
        <div class="loversBox_title_two numberFx">
          <el-input-number
            v-model="femaleDataFrom.quantity"
            @change="realTimePriceOrderNumber('女', $event)"
            @input.native="updataQuantity('女', $event)"
            :min="0"
            :max="9999"
            label="描述文字"
          ></el-input-number>
          <span style="font-size:14px">(起订量{{ leastQuantity }}件，可定单不同规格)</span>
        </div>
        <div class="loversBox_title_three numberFx">
          <el-input-number
            v-model="maleDataFrom.quantity"
            @change="realTimePriceOrderNumber('男', $event)"
            @input.native="updataQuantity('男', $event)"
            :min="0"
            :max="9999"
            label="描述文字"
          ></el-input-number>
          <span style="font-size:14px">(起订量{{ leastQuantity }}件，可定单不同规格)</span>
        </div>
      </div>
      <div class="loversBox_title">
        <div class="loversBox_title_one">标签价格</div>
        <div class="loversBox_title_two">
          <span class="title">{{ femaleDataFrom.labelPrice }}</span>
        </div>
        <div class="loversBox_title_three">
          <span class="title">{{ maleDataFrom.labelPrice }}</span>
        </div>
      </div>
      <div class="loversBox_title" v-if="!hasPermission('展厅') || !hasPermission('加盟公司总经理')">
        <div class="loversBox_title_one">批发价格</div>
        <div class="loversBox_title_two">
          <span class="title">{{ femaleDataFrom.tradePrice }}</span>
        </div>
        <div class="loversBox_title_three">
          <span class="title">{{ maleDataFrom.tradePrice }}</span>
        </div>
      </div>
      <div class="loversBox_title" style="min-height:150px;">
        <div class="loversBox_title_one">备<span class="hidden">间隔</span>注</div>
        <div class="loversBox_title_two">
          <el-input
            type="textarea"
            :maxlength="64"
            :autosize="{ minRows: 6 }"
            placeholder="其他要求与说明请在此处备注说明,字符数限制64位"
            v-model="femaleDataFrom.productRemark"
          ></el-input>
        </div>
        <div class="loversBox_title_three">
          <el-input
            :autosize="{ minRows: 6 }"
            :maxlength="64"
            type="textarea"
            placeholder="其他要求与说明请在此处备注说明,字符数限制64位"
            v-model="maleDataFrom.productRemark"
          ></el-input>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getOptional, getWatchPriceRange } from '@/api/detailsApi';
export default {
  name: 'LoversCustom',
  props: {
    DataList: {
      type: Object,
      default() {
        return {};
      },
    },
    leastQuantity: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      femaleDataFrom: {
        styleNumber: this.DataList.styleNumber, // 公司款号
        serialNumber: this.DataList.serialNumber, // 情侣号
        isCouples: this.DataList.isCouples, // 是否情侣款
        productName: this.DataList.productName,
        quantity: 0, // 数量
        goldWeigh: '',
        gender: '女',
        labelPrice: 0,
        tradePrice: 0,
      },
      maleDataFrom: {
        styleNumber: this.DataList.styleNumber, // 公司款号
        serialNumber: this.DataList.serialNumber, // 情侣号
        isCouples: this.DataList.isCouples, // 是否情侣款
        productName: this.DataList.productName,
        quantity: 0, // 数量
        goldWeigh: '',
        gender: '男',
        labelPrice: 0,
        tradePrice: 0,
      },
      malePullDownData: {},
      femalePullDownData: {},
    };
  },
  mounted() {
    // 获取可选属性
    this.getOptional();
  },
  methods: {
    // 获取可选属性
    getOptional() {
      let obj = {
        styleNumber: this.DataList.styleNumber,
        gender: '',
      };
      obj.gender = '女';
      this.getPriceRange(obj.gender);
      getOptional(obj).then((res) => {
        if (res.data.dialColor && res.data.dialColor.length === 1) {
          this.femaleDataFrom.dialColor = res.data.dialColor[0];
        }
        if (res.data.bandMaterial && res.data.bandMaterial.length === 1) {
          this.femaleDataFrom.bandMaterial = res.data.bandMaterial[0];
        }
        if (res.data.textureList && res.data.textureList.length === 1) {
          this.femaleDataFrom.texture = res.data.textureList[0];
        }
        if (res.data.mechanicalType && res.data.mechanicalType.length === 1) {
          this.femaleDataFrom.mechanicalType = res.data.mechanicalType[0];
        }
        this.femaleDataFrom.goldWeigh = res.data.referenceGoldWeight;
        this.femalePullDownData = { ...this.femalePullDownData, ...res.data };
      });
      obj.gender = '男';
      this.getPriceRange(obj.gender);
      getOptional(obj).then((res) => {
        if (res.data.dialColor && res.data.dialColor.length === 1) {
          this.maleDataFrom.dialColor = res.data.dialColor[0];
        }
        if (res.data.bandMaterial && res.data.bandMaterial.length === 1) {
          this.maleDataFrom.bandMaterial = res.data.bandMaterial[0];
        }
        if (res.data.textureList && res.data.textureList.length === 1) {
          this.maleDataFrom.texture = res.data.textureList[0];
        }
        if (res.data.mechanicalType && res.data.mechanicalType.length === 1) {
          this.maleDataFrom.mechanicalType = res.data.mechanicalType[0];
        }
        this.maleDataFrom.goldWeigh = res.data.referenceGoldWeight;
        this.malePullDownData = { ...this.malePullDownData, ...res.data };
      });
    },
    //获取价格范围
    getPriceRange(gender) {
      let obj = {};
      if (gender === '女') {
        obj = JSON.parse(JSON.stringify(this.femaleDataFrom));
      } else {
        obj = JSON.parse(JSON.stringify(this.maleDataFrom));
      }
      delete obj.texture;
      getWatchPriceRange(obj).then((res) => {
        delete res.data.quantity;
        if (gender === '女') {
          this.femaleDataFrom = { ...this.femaleDataFrom, ...res.data };
        } else {
          this.maleDataFrom = { ...this.maleDataFrom, ...res.data };
        }
      });
    },
    // 数量改变实时触发
    updataQuantity(gender, event) {
      this.realTimePriceOrderNumber(gender, Number(event.target.value));
    },
    // 数量改变计算价格
    realTimePriceOrderNumber(gender, value) {
      this.$emit('selectionCustom', value, gender);
      this.getPriceRange(gender);
    },
  },
};
</script>
<style lang="scss" scoped>
.LoversCustom {
  .numberFx {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-size: 18px;
    color: #ec9368;
  }

  .hidden {
    visibility: hidden;
  }

  .noMaleRing {
    background: #fff2ea;
    font-size: 18px;
    color: #000000;
  }

  .loversSelectBox {
    text-align: left;

    ::v-deep .el-popper {
      left: 0 !important;
    }

    .el-scrollbar__view li {
      width: 85px;
    }
  }

  .el-select-dropdown__item {
    margin: 10px 0 0 10px;
    display: inline-block;
    height: 46px;
    text-align: center;
    line-height: 46px;
    border: 1px solid #d3d3d3;
    padding: 0;
    font-size: 16px;
    color: #000;
  }

  .el-select-dropdown__item.selected {
    background: #fff2ea;
    font-size: 16px;
    color: #000;
    font-weight: 400;
    border: 1px solid #ee9c75;
  }
}
</style>
