// 切工
export function cutFilter(cut) {
  var gia;
  if (cut == 'Y') {
    gia = '是';
    return gia;
  } else if (cut == 'N') {
    gia = '否';
    return gia;
  }
}
// 色度净度
export function clarityColor(color) {
  var color;
  if (color == '' || !color) {
    color = '无';
    return color;
  }
}
// 网点状态
export function dotState(dot) {
  var dot;
  if (dot == 'O') {
    dot = '运营中';
    return dot;
  } else if (dot == 'S') {
    dot = '网点已停运，请重新选择网点。';
    return dot;
  } else if (dot == 'F') {
    dot = '网点已冻结，请重新选择网点。';
    return dot;
  } else if (dot == 'T') {
    dot = '试运行';
    return dot;
  }
}
// 订单类型
export function orderTypeFilter(type) {
  const orderType = {
    '0': '待受理',
    '1': '正在生产',
    '2': '配货中',
    '3': '待付款',
    '4': '待自提',
    '5': '待发货',
    '6': '待收货',
    '7': '已完成',
    '8': '已取消',
  }[type];
  return orderType || '';
}
// 预售订单类型
export function orderPresellTypeFilter(type) {
  const orderType = {
    '0': '拼团中',
    '1': '拼团成功',
    '2': '已取消',
  }[type];
  return orderType || '';
}
