<template>
  <div class="ordinaryModify">
    <el-dialog
      :popper-append-to-body="false"
      center
      :title="'黄金镶嵌现货列表' + '(' + modifyingReplication.styleNumber + ')'"
      :close-on-click-modal="false"
      :visible.sync="goldSettingSpotDialogVisible"
      :before-close="handleClose"
    >
      <el-table
        :data="gridData"
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
        class="tableData"
        style="width: 100%"
      >
        <el-table-column property="" label="参数信息">
          <template slot-scope="scope">
            <span v-if="scope.row.gender">{{ scope.row.gender }}戒</span>
          </template>
        </el-table-column>
        <el-table-column property="texture" label="材质"></el-table-column>
        <el-table-column property="mainStoneName" label="主石名称"></el-table-column>
        <el-table-column property="mainStoneNumber" label="主石数量"></el-table-column>
        <el-table-column property="mainStone" label="主石重量"></el-table-column>
        <el-table-column property="assistStoneNameOne" label="辅石1名称"></el-table-column>
        <el-table-column property="assistStoneNumberOne" label="辅石1数量"></el-table-column>
        <el-table-column property="assistStoneWeightOne" label="辅石1重量"></el-table-column>
        <el-table-column property="goldWeigh" label="金重"></el-table-column>
        <el-table-column property="totalWeight" label="总重量"></el-table-column>
        <el-table-column property="mainStoneClarity" label="净度"></el-table-column>
        <el-table-column property="mainStoneColor" label="色度"></el-table-column>
        <el-table-column property="" label="GIA">
          <template slot-scope="scope">
            <span>{{ scope.row.gia | cutFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column property="spec" label="规格"></el-table-column>
        <el-table-column property="seriesName" label="系列"></el-table-column>
        <el-table-column property="tradePrice" v-if="!hasPermission('展厅') || !hasPermission('加盟公司总经理')" label="批发价格"></el-table-column>
        <el-table-column property="labelPrice" label="标签价格"></el-table-column>
      </el-table>
      <div class="pageClass">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :current-page="listQuery.current"
          :page-size="listQuery.size"
          @current-change="cartSpotListCurrentPage($event)"
        ></el-pagination>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getSpot } from '@/api/orderApi';
import { cutFilter } from '@/utils/filetrs';
export default {
  props: {
    goldSettingSpotDialogVisible: Boolean,
    modifyingReplication: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      listQuery: {
        id: '',
        current: 1,
        size: 5,
        styleNumber: this.modifyingReplication.styleNumber,
      },
      total: 0,
      gridData: [],
    };
  },
  filters: {
    cutFilter,
  },
  mounted() {
    this.getSpotList();
  },
  methods: {
    // 获取列表数据
    getSpotList() {
      this.listQuery.id = this.modifyingReplication.id;
      getSpot(this.listQuery).then((res) => {
        this.gridData = [];
        res.data.records.forEach((item) => {
          this.gridData.push(item);
        });
        this.total = res.data.total;
      });
    },
    // 分页
    cartSpotListCurrentPage(ev) {
      this.listQuery.current = ev;
      this.getSpotList();
    },
    // 关闭弹窗
    handleClose() {
      this.$emit('onCloseSelectDialog');
    },
  },
};
</script>

<style scoped lang="scss">
.ordinaryModify {
  /deep/ .el-dialog {
    width: 1500px;
  }

  /deep/ .el-dialog--center .el-dialog__body {
    padding: 0 10px 10px 10px;
  }

  /deep/ .el-table .cell {
    padding: 0;
  }

  .el-button {
    width: 100%;
  }

  .el-button--text {
    color: red;
  }

  .pageClass {
    text-align: center;
    padding: 20px 0;
    background: #fff;
  }

  /deep/ .el-pager li.active + li {
    border-left: none;
  }
}
</style>
