<template>
  <el-dialog title="提示" :close-on-click-modal="false" :visible.sync="visitorVisible" width="500px" center :before-close="handleClose">
    <span>完整功能请用网点账号登录查看，是否跳转登录？</span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
// 游客登录提示
export default {
  name: 'visitorDialog',
  data() {
    return {
      visitorVisible: false,
    };
  },
  methods: {
    handleVisitor() {
      this.visitorVisible = true;
    },
    handleClose() {
      this.visitorVisible = false;
    },
    confirm() {
      this.visitorVisible = false;
      this.$router.push({ path: '/login' });
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-footer {
  /deep/ .el-button--primary {
    background-color: #ec9368;
  }
}
</style>
