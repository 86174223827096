import { logout, loginByUsername, getRole, getHallInfo, getType, getUserInfo } from '@/api/userApi';
import ls from '@/utils/localStorage';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { encryption } from '@/utils/util';
const state = {
  token: getToken(),
  userData: ls.get('userData') || {},
  mainBodyType: ls.get('mainBodyType') || {},
  permissions: ls.get('permissions') || [],
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_MAINBODYTYPE: (state, mainBodyType) => {
    state.mainBodyType = mainBodyType;
  },
  SET_USERINFO: (state, userData) => {
    state.userData = userData;
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions;
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
};

const actions = {
  // 根据用户名登录
  LoginByUsername({ commit }, userData) {
    const user = encryption({
      data: userData,
      key: 'pigxpigxpigxpigx',
      param: ['password'],
    });
    return new Promise((resolve, reject) => {
      loginByUsername(user.username, user.password, user.device_type, user.code, user.randomStr)
        .then((response) => {
          const data = response.access_token;
          const userData = response.user_info;
          commit('SET_TOKEN', data);
          commit('SET_USERINFO', userData);
          setToken(data);
          ls.set('userData', userData);
          resolve(response.user_info);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 根据云平台的token登录
  LoginToken({ commit }, token) {
    commit('SET_TOKEN', token);
    setToken(token);
    return new Promise((resolve, reject) => {
      getHallInfo()
        .then((response) => {
          const userData = {
            username: response.data.username,
          };
          // const data = response.access_token;
          // const userData = response.user_info;
          // commit("SET_TOKEN", data);
          commit('SET_USERINFO', userData);
          // setToken(data);
          ls.set('userData', userData);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 查询用户信息
  GetUserInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getUserInfo({ sourceType: 'A' })
        .then((res) => {
          const data = res.data || {};
          const userInfo = Object.assign(data);
          commit('SET_USER_INFO', userInfo);
          ls.set('userInfo', userInfo);
          resolve(data);
        })
        .catch(() => {
          reject();
        });
    });
  },
  // 判斷角色
  GetRole({ state, commit }) {
    return new Promise((resolve, reject) => {
      getRole()
        .then((res) => {
          const data = res || {};
          const userData = Object.assign(state.userData, data);
          commit('SET_USERINFO', userData);
          commit('SET_PERMISSIONS', data.data || []);
          resolve(data);
        })
        .catch(() => {
          reject();
        });
    });
  },
  // 判断是周六福还是年轻空间
  GetType({ state, commit }) {
    return new Promise((resolve, reject) => {
      getType()
        .then((res) => {
          const data = res || [];
          commit('SET_MAINBODYTYPE', data.data || []);
          ls.set('mainBodyType', data.data);
          resolve(data);
        })
        .catch(() => {
          reject();
        });
    });
  },
  // 登出
  LogOut({ commit }) {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          commit('SET_TOKEN', '');
          commit('SET_USERINFO', {});
          commit('SET_PERMISSIONS', []);
          ls.remove('userData');
          ls.remove('coludQuery');
          removeToken();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 注销session
  FedLogOut({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '');
      commit('SET_USERINFO', {});
      commit('SET_USER_INFO', {});
      commit('SET_PERMISSIONS', []);
      ls.remove('userData');
      ls.remove('userInfo');
      removeToken();
      resolve();
    });
  },
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '');
      ls.remove('userData');
      ls.remove('userInfo');
      removeToken();
      resolve();
    });
  },
};
export default {
  state,
  mutations,
  actions,
};
