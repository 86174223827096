<template>
  <div class="custom">
    <div class="fl">
      <span class="title"> 款<span class="hidden">间隔</span>式： </span>
      <span class="value-font">{{ dataFrom.style }}</span>
    </div>
    <el-divider></el-divider>
    <div :class="'fl'">
      <span class="title">
        表盘颜色：
      </span>
      <span class="value-font">
        {{ dataFrom.dialColor }}
      </span>
      <!-- <div class="selectBoxWatch selectBox" v-if="dataFrom.bandColor&&dataFrom.bandColor.length > 1">
        <el-select v-model="dataFrom.bandColor" :popper-append-to-body="false" clearable placeholder>
          <div>
            <el-option v-for="(item, index) in dataFrom.bandColor" :key="index" :label="item" :value="item"></el-option>
          </div>
        </el-select>
      </div> -->
    </div>
    <el-divider></el-divider>
    <div class="fx">
      <div class="title">
        表带颜色：
      </div>
      <div class="selectBoxWatch selectBox">
        <el-select v-model="dataFrom.bandColor" :popper-append-to-body="false" clearable placeholder>
          <div>
            <el-option v-for="(item, index) in pullDownData.bandColor" :key="index" :label="item" :value="item"></el-option>
          </div>
        </el-select>
      </div>
    </div>
    <el-divider></el-divider>
    <div :class="'fl'">
      <span class="title">
        手表类型：
      </span>
      <span class="value-font">
        {{ dataFrom.mechanicalType }}
      </span>
      <!-- <div class="selectBoxWatch selectBox" v-if="dataFrom.mechanicalType&&dataFrom.mechanicalType.length > 1">
        <el-select v-model="dataFrom.mechanicalType" :popper-append-to-body="false" clearable placeholder>
          <div>
            <el-option v-for="(item, index) in dataFrom.mechanicalType" :key="index" :label="item" :value="item"></el-option>
          </div>
        </el-select>
      </div> -->
    </div>
    <el-divider></el-divider>
    <div :class="'fl'">
      <span class="title">
        表带材质：
      </span>
      <span class="value-font">{{ dataFrom.bandMaterial }}</span>
      <!-- <div class="selectBoxWatch selectBox" v-if="dataFrom.bandMaterial&&dataFrom.bandMaterial.length > 1">
        <el-select v-model="dataFrom.bandMaterial" :popper-append-to-body="false" clearable placeholder>
          <div>
            <el-option v-for="(item, index) in dataFrom.bandMaterial" :key="index" :label="item" :value="item"></el-option>
          </div>
        </el-select>
      </div> -->
    </div>
    <el-divider></el-divider>
    <div :class="'fl'">
      <span class="title"> 材<span class="hidden">间隔</span>质： </span>
      <span class="value-font">
        {{ dataFrom.texture }}
      </span>
      <!-- <div class="selectBoxWatch selectBox" v-if="dataFrom.textureList&&dataFrom.textureList.length > 1">
        <el-select v-model="dataFrom.textureList" :popper-append-to-body="false" clearable placeholder>
          <div>
            <el-option v-for="(item, index) in dataFrom.textureList" :key="index" :label="item" :value="item"></el-option>
          </div>
        </el-select>
      </div> -->
    </div>
    <el-divider></el-divider>
    <div class="fl">
      <span class="title"> 金<span class="hidden">间隔</span>重： </span>
      <span class="value-font">
        {{ dataFrom.goldWeigh }}
      </span>
    </div>
    <el-divider></el-divider>
    <div class="box_detail_input">
      <span class="title">下单件数：</span>
      <span class="value-font">
        <el-input-number
          v-model="dataFrom.quantity"
          @change="realTimePriceOrderNumber"
          @input.native="updataQuantity"
          :min="0"
          :max="9999"
          label="描述文字"
        ></el-input-number>
        <span class="prompt">(起订量{{ leastQuantity }}件，可定单不同规格)</span>
      </span>
    </div>
    <el-divider></el-divider>
    <div>
      <span class="title">标签价格：</span>
      <span class="value-font">{{ dataFrom.labelPrice }}</span>
    </div>
    <el-divider></el-divider>
    <div v-if="!hasPermission('展厅') || !hasPermission('加盟公司总经理')">
      <span class="title">批发价格：</span>
      <span class="value-font">{{ dataFrom.tradePrice }}</span>
    </div>
    <el-divider v-if="!hasPermission('展厅') || !hasPermission('加盟公司总经理')"></el-divider>
  </div>
</template>
<script>
import { getOptional, getWatchPriceRange } from '@/api/detailsApi';
import { deepClone } from '@/utils/util';
export default {
  name: 'Custom',
  props: {
    DataList: {
      type: Object,
      default() {
        return {};
      },
    },
    leastQuantity: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dataFrom: {
        styleNumber: this.DataList.styleNumber, // 公司款号
        productName: this.DataList.productName,
        isCouples: this.DataList.isCouples, // 是否情侣款
        quantity: 0, // 数量
        labelPrice: 0,
        tradePrice: 0,
      },
      pullDownData: {},
    };
  },
  mounted() {
    // 获取可选属性
    this.getOptional();
    //获取价格范围
    this.getPriceRange();
  },
  methods: {
    // 获取可选属性
    getOptional() {
      let obj = {
        styleNumber: this.DataList.styleNumber,
        gender: '',
      };
      getOptional(obj).then((res) => {
        if (res.data.dialColor && res.data.dialColor.length === 1) {
          this.dataFrom.dialColor = res.data.dialColor[0];
        }
        if (res.data.bandMaterial && res.data.bandMaterial.length === 1) {
          this.dataFrom.bandMaterial = res.data.bandMaterial[0];
        }
        if (res.data.textureList && res.data.textureList.length === 1) {
          this.dataFrom.texture = res.data.textureList[0];
        }
        if (res.data.mechanicalType && res.data.mechanicalType.length === 1) {
          this.dataFrom.mechanicalType = res.data.mechanicalType[0];
        }
        this.dataFrom.goldWeigh = res.data.referenceGoldWeight;
        this.dataFrom.style = res.data.style;
        this.pullDownData = { ...this.pullDownData, ...res.data };
      });
    },
    //获取价格范围
    getPriceRange() {
      const query = deepClone(this.dataFrom);
      delete query.texture;
      getWatchPriceRange(query).then((res) => {
        this.dataFrom.labelPrice = res.data.labelPrice;
        this.dataFrom.tradePrice = res.data.tradePrice;
      });
    },
    // 数量改变实时触发
    updataQuantity(event) {
      this.realTimePriceOrderNumber(Number(event.target.value));
    },
    // 数量改变计算价格
    realTimePriceOrderNumber(value) {
      this.getPriceRange();
      this.$emit('selectionCustom', value);
    },
  },
};
</script>
<style lang="scss" scoped>
.custom {
  .fx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .fl {
    display: flex;
    align-items: center;
    position: relative;
  }

  .selectBox {
    ::v-deep .el-popper {
      left: 0 !important;
    }

    .el-scrollbar__view li {
      width: 100px;
    }
  }

  .selectBoxWatch {
    /deep/ .el-select .el-input__inner {
      text-align: left;
      padding-left: 6px;
    }
  }

  .el-select-dropdown__item {
    margin: 10px 0 0 10px;
    display: inline-block;
    height: 46px;
    text-align: center;
    line-height: 46px;
    border: 1px solid #d3d3d3;
    padding: 0;
    font-size: 16px;
    color: #000;
  }

  .el-select-dropdown__item.selected {
    background: #fff2ea;
    font-size: 16px;
    color: #000;
    font-weight: 400;
    border: 1px solid #ee9c75;
  }

  .title {
    font-size: 18px;
    color: #000;

    .hidden {
      visibility: hidden;
    }
  }

  .value-font {
    font-size: 18px;
    color: #ec9368;
    margin-left: 56px;
  }

  .el-divider--horizontal {
    margin: 17px 0;
  }
}
</style>
