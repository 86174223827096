import axios from "axios";
import { Message } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";
import router from "@/router";
import errorCode from "./errorCode";
// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // responseType: 'json',
  withCredentials: true, // 是否允许带cookie这些
  // headers: {
  //   'Content-Type': 'application/json;charset=utf-8'
  // },
  validateStatus: function(status) {
    return status >= 200 && status <= 500;
  },
  timeout: 60000, // request timeout
});
// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    const isToken = (config.headers || {}).isToken === false;
    const token = store.getters.token;
    if (store.getters.token && !isToken) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers["Authorization"] = "Bearer " + token; // token
      // config.headers["T-token"] = getToken() || "";
    }
    return config;
  },
  error => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */
  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    if (response.config.responseType === "blob") {
      return response;
    }
    const status = Number(response.status) || 200;
    const message = response.data.msg || errorCode[status] || errorCode["default"];
    if (status === 401) {
      Message({
        message: message,
        type: "error",
        duration: 3 * 1000,
      });
      store.dispatch("FedLogOut").then(() => {
        router.push({ path: "/login" });
      });
      return;
    }
    if (status != 200 || response.data.code === 1) {
      Message({
        message: message,
        type: "error",
        duration: 3 * 1000,
      });
      return Promise.reject(message);
    }
    return response.data;
  },
  error => {
    // console.log('err' + error.msg) // for debug
    console.log("err:" + error); // for debug
    Message({
      message: error.message,
      type: "error",
      duration: 1 * 1000,
    });
    return Promise.reject(new Error(error));
  }
);

export default service;
