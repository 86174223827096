export default {
  get (key) {
    if (localStorage) {
      try {
        return JSON.parse(localStorage.getItem(key))
      } catch (err) {
        return null
      }
    } else {
      return null
    }
  },
  set (key, value) {
    if (localStorage) {
      try {
        var val = JSON.stringify(value)
        localStorage.setItem(key, val)
        return val
      } catch (err) {
        return null
      }
    } else {
      return null
    }
  },
  remove (key) {
    if (localStorage) {
      try {
        localStorage.removeItem(key)
        return true
      } catch (err) {
        return null
      }
    } else {
      return null
    }
  },
  clear () {
    if (localStorage) {
      try {
        localStorage.clear()
        return true
      } catch (err) {
        return null
      }
    } else {
      return null
    }
  }
}
