<template>
  <div class="userFeedback">
    <el-dialog title="意见反馈" :visible.sync="accoutFormVisible" width="660px" :close-on-click-modal="false">
      <el-form ref="accoutForm" label-width="100px" inline :model="formData" :rules="formRules">
        <el-form-item label="反馈类型：" prop="feedbackType">
          <el-select v-model="formData.feedbackType" placeholder="请选择反馈类型">
            <el-option v-for="item in feedbackTypeList" :key="item.value" :label="item.name" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="反馈详情：" prop="content">
          <el-input v-model.trim="formData.content" show-word-limit type="textarea" :rows="6" placeholder="请输入" maxlength="100" />
        </el-form-item>
        <el-form-item label="联系方式：" prop="mobile">
          <el-input v-model.trim="formData.mobile" placeholder="联系方式（选填，方便我们与您联系）" maxlength="11" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="submitBtnLoading" @click="handleConfirm()">
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { createFeedb } from '@/api/feedback';
export default {
  name: 'UserFeedback',
  data() {
    return {
      accoutFormVisible: false,
      formData: {
        feedbackType: null,
        content: '',
        mobile: '',
      },
      formRules: {
        feedbackType: [{ required: true, message: '请选择反馈类型', trigger: 'blur' }],
        content: [{ required: true, message: '请填写反馈详情', trigger: 'blur' }],
      },
      submitBtnLoading: false,
      feedbackTypeList: [
        { value: 1, name: '商品建议' },
        { value: 2, name: '内容建议' },
        { value: 3, name: '系统优化' },
        { value: 4, name: '其他' },
      ],
    };
  },
  methods: {
    handleConfirm() {
      this.$refs['accoutForm'].validate((valid) => {
        if (valid) {
          this.submitBtnLoading = true;
          createFeedb(this.formData)
            .then((res) => {
              this.$message({
                message: res.data,
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.submitBtnLoading = false;
                  this.accoutFormVisible = false;
                },
              });
            })
            .catch(() => {
              this.submitBtnLoading = false;
            });
        }
      });
    },
    onShow() {
      this.accoutFormVisible = true;
      this.$nextTick(() => {
        this.$refs['accoutForm'].resetFields();
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-form-item {
  margin-bottom: 18px !important;
}
/deep/ .el-input__inner {
  width: 510px;
}
/deep/ .el-input--suffix .el-input__inner {
  width: 510px;
}
/deep/ .el-textarea__inner {
  width: 510px;
  background: #fff !important;
  color: #606266 !important;
}
/deep/ textarea::-webkit-input-placeholder {
  color: #909399 !important;
}
.el-scrollbar__view li {
  color: #606266;
}
.el-scrollbar__view .selected {
  color: #ec9368;
}
.dialog-footer {
  text-align: center;
  .el-button {
    background: #ec9368;
    color: #fff;
  }
  .el-button:focus {
    border-color: #ec9368;
  }
}
</style>
