import { Decimal } from 'decimal.js';
/**
 * 购物车计算属性
 */
export function getComputed(shoppingCart, text) {
  let copyList = [];
  let shangp = 0;
  shoppingCart.forEach((res, index) => {
    if (res.length > 0) {
      res.forEach((item, i) => {
        item.cartList.forEach((ele) => {
          if (text == 'checkedData') {
            if (ele.classify == '1' && ele.spotFlag == 2) {
              if (ele.quantity >= 5 && res.customStock != true) {
                copyList.push(ele);
              }
            } else if (ele.cartStatus === '现货') {
              copyList.push(ele);
            }
          } else if (text === 'customData') {
            if (ele.classify == 1) {
              if (ele.spotFlag == 2 && ele.quantity >= 5 && res.customStock != true) {
                copyList.push(ele);
              }
            } else if (ele.spotFlag == 2 && ele.cartStatus === '现货') {
              copyList.push(ele);
            }
          } else if (text === 'spot') {
            if (ele.spotFlag == 1 && ele.cartStatus === '现货') {
              copyList.push(ele);
            }
          } else if (text === 'cartNumber') {
            shangp += Number(ele.quantity);
          } else if (text === 'shangp') {
            if (ele.switchFlag === true) {
              shangp += Number(ele.quantity);
            }
          } else if (text === 'spotNumber') {
            if (ele.switchFlag === true && ele.spotFlag === 1) {
              shangp += Number(ele.quantity);
            }
          } else if (text === 'comtosNumber') {
            if (ele.switchFlag === true && ele.spotFlag === 2) {
              shangp += Number(ele.quantity);
            }
          } else if (text === 'wholesalePrice') {
            if (ele.switchFlag === true && ele.tradePrice) {
              shangp = new Decimal(shangp).add(new Decimal(ele.tradePrice));
            }
          } else if (text === 'shopCarPrice') {
            if (ele.switchFlag === true && ele.labelPrice) {
              shangp = new Decimal(shangp).add(new Decimal(ele.labelPrice));
            }
          } else if (text === 'totalGoldWeight') {
            if (ele.switchFlag === true && (ele.classify == '1' || ele.classify == '3') && ele.spotFlag === 1) {
              if (ele.goldWeigh?.includes('-')) {
                shangp = new Decimal(shangp).add(new Decimal(ele.goldWeigh.split('-')[1]).mul(ele.quantity));
              } else {
                shangp = new Decimal(shangp).add(new Decimal(ele.goldWeigh).mul(ele.quantity));
              }
            }
          } else if (text === 'totalCustomGoldWeight') {
            if (ele.switchFlag === true && (ele.classify == '1' || ele.classify == '3') && ele.spotFlag === 2) {
              if (ele.goldWeigh?.includes('-')) {
                shangp = new Decimal(shangp).add(new Decimal(ele.goldWeigh.split('-')[1]).mul(ele.quantity));
              } else {
                shangp = new Decimal(shangp).add(new Decimal(ele.goldWeigh).mul(ele.quantity));
              }
            }
          }
        });
      });
    }
  });
  if (
    text === 'cartNumber' ||
    text === 'shangp' ||
    text === 'spotNumber' ||
    text === 'comtosNumber' ||
    text === 'wholesalePrice' ||
    text === 'shopCarPrice' ||
    text === 'totalGoldWeight' ||
    text === 'totalCustomGoldWeight'
  ) {
    return shangp;
  }
  if (copyList.length != 0) {
    return copyList.every((val) => {
      return val.switchFlag === true;
    });
  }
}
/**
 *进购物车页面列表数据处理
 */
export function getHandleList(records, addOrderList) {
  let list = [];
  records.forEach((item) => {
    item.flag = false;
    item.customStock = false;
    item.totalQuantityFlag = false;
    item.totalMaleQuantityFlag = false;
    item.totalFemaleQuantityFlag = false;
    item.isSoldOut = false; //是否已售
    item.isDelist = false; //是否下架
    let labelPrice = 0;
    let tradePrice = 0;
    let quantity = 0;
    let maleQuantity = 0;
    let femaleQuantity = 0;
    let goldWeigh = 0;
    item.forEach((ele) => {
      // 现货只展示一条，相加所有现货价格到第一条
      ele.cartList.forEach((add) => {
        add.materialFlag = false;
        add.disableInput = false;
        if (ele.spotFlag === 1 && ele.cartList.length > 1 && ele.classify !== '2') {
          labelPrice = Number(labelPrice) + Number(add.labelPrice);
          tradePrice = Number(tradePrice) + Number(add.tradePrice);
          quantity = Number(quantity) + Number(add.quantity);
          // ele.cartList[0].labelPrice = labelPrice
          // ele.cartList[0].tradePrice = tradePrice
          // ele.cartList[0].quantity = quantity
        } else if (ele.spotFlag === 1 && ele.cartList.length > 1 && ele.classify == '2' && ele.product.isCouples === '2') {
          labelPrice = Number(labelPrice) + Number(add.labelPrice);
          tradePrice = Number(tradePrice) + Number(add.tradePrice);
          quantity = Number(quantity) + Number(add.quantity);
          // ele.cartList[0].labelPrice = labelPrice
          // ele.cartList[0].tradePrice = tradePrice
          // ele.cartList[0].quantity = quantity
        }
        // if (ele.spotFlag === 1 && ele.cartList.length > 1 && add.goldWeigh) {
        //   let index = add.goldWeigh.indexOf('-')
        //   if (index == -1) {
        //     goldWeigh = new Decimal(goldWeigh).add(new Decimal(add.goldWeigh))
        //   } else {
        //     let maxWeight = add.goldWeigh.slice(index + 1)
        //     goldWeigh = new Decimal(goldWeigh).add(new Decimal(maxWeight))
        //   }
        //   ele.cartList[0].goldWeigh = goldWeigh
        // }
        if (ele.spotFlag === 2 && ele.cartList.length > 0) {
          quantity = Number(quantity) + Number(add.quantity);
          if (quantity >= add.leastQuantity || add.cartStatus !== '现货') {
            item.totalQuantityFlag = false;
          } else {
            item.totalQuantityFlag = true;
          }
        }
        if (ele.spotFlag === 2 && ele.cartList.length > 0 && add.gender === '女') {
          femaleQuantity = Number(femaleQuantity) + Number(add.quantity);
          if (femaleQuantity >= add.leastQuantity || add.cartStatus !== '现货') {
            item.totalFemaleQuantityFlag = false;
          } else {
            item.totalFemaleQuantityFlag = true;
          }
        }
        if (ele.spotFlag === 2 && ele.cartList.length > 0 && add.gender === '男') {
          maleQuantity = Number(maleQuantity) + Number(add.quantity);
          if (maleQuantity >= add.leastQuantity || add.cartStatus !== '现货') {
            item.totalMaleQuantityFlag = false;
          } else {
            item.totalMaleQuantityFlag = true;
          }
        }
      });
      // 如果是现货，只保留第一个数据
      //     if (ele.spotFlag === 1 && ele.cartList.length > 1 && ele.classify !== '2') {
      //       var i = ele.cartList.length
      //       while (i--) {
      //         if (ele.cartList.length > 1) {
      //           ele.cartList.splice(i, 1)
      //         }
      //       }
      //     } else if (
      //       ele.spotFlag === 1 &&
      //       ele.cartList.length > 1 &&
      //       ele.classify === '2' &&
      //       ele.product.isCouples === '2'
      //     ) {
      //       // var i = ele.cartList.length
      //       // while (i--) {
      //       //   if (ele.cartList.length > 1) {
      //       //     ele.cartList.splice(i, 1)
      //       //   }
      //       // }
      //     } else if (
      //       ele.spotFlag === 1 &&
      //       ele.cartList.length > 1 &&
      //       ele.classify === '2' &&
      //       ele.product.isCouples === '1'
      //     ) {
      //       // 钻石情侣戒
      //       // ele.cartList = process(ele.cartList)
      // ele.cartList = ele.cartList
      //     }
      ele.cartList.forEach((arrly) => {
        if (ele.cartStatus == '下架' || ele.cartStatus == '已售') {
          item.customStock = true;
        }
        arrly.switchFlag = false;
      });
      // 循环未截取的数组找到缺货下架的数据
      addOrderList?.forEach((res) => {
        if (ele?.id === res[0].id) {
          item.isSoldOut = res[0].cartList.some((ele) => ele.cartStatus === '已售');
          item.isDelist = res[0].cartList.some((ele) => ele.cartStatus === '下架');
        }
      });
    });
    list.push(item);
  });
  return list;
}
/**
 * 钻石情侣戒去重
 */
export function process(arr) {
  // 缓存用于记录
  const cache = [];
  for (const t of arr) {
    // 检查缓存中是否已经存在 已有的数量、价格累加
    if (cache.find((c) => c.texture === t.texture && c.gender === t.gender)) {
      cache.find((c) => c.texture === t.texture && c.gender === t.gender).quantity =
        Number(cache.find((c) => c.texture === t.texture && c.gender === t.gender).quantity) + Number(t.quantity);
      cache.find((c) => c.texture === t.texture && c.gender === t.gender).labelPrice =
        Number(cache.find((c) => c.texture === t.texture && c.gender === t.gender).labelPrice) + Number(t.labelPrice);
      cache.find((c) => c.texture === t.texture && c.gender === t.gender).tradePrice =
        Number(cache.find((c) => c.texture === t.texture && c.gender === t.gender).tradePrice) + Number(t.tradePrice);
      // 已经存在说明以前记录过，现在这个就是多余的，直接忽略
      continue;
    }
    // 不存在就说明以前没遇到过，把它记录下来
    cache.push(t);
  }
  // 记录结果就是过滤后的结果
  return cache;
}
