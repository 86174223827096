<template>
  <div class="diamond">
    <div class="spotOrderList_head">
      <div class="orderNumber">
        <span class="orderClass" v-if="pieceData.orderSource === '1'">智慧商城订单</span>
        <span class="orderClass" v-else>云展厅订单</span>
      </div>
      <div class="orderNumber">
        <span>订单编号：</span>
        <span v-text="pieceData.orderNo"></span>
      </div>
      <div class="orderDate"><span>下单时间：</span><span v-text="pieceData.createdTime"></span></div>
      <div class="upDownTopClass" @dblclick="seccendCopy(pieceData.netAddress)">
        <el-tooltip class="item" effect="dark" :content="pieceData.netAddress" placement="top-start">
          <el-button>
            <div class="dotName"><span class="dotNameTitle">网点名称：</span><span v-text="pieceData.netAddress" class="dotNameText"></span></div>
          </el-button>
        </el-tooltip>
      </div>
      <div class="network">
        <span>网点编码：</span>
        <span v-text="pieceData.netCode"></span>
      </div>
    </div>
    <div class="spotOrderList">
      <div>
        <div class="spotOrderList_body_data">
          <div class="spotOrderList_body_data_father" v-for="(val, i) in pieceData.detailList" :key="i" v-if="i < 3 || !pieceData.showNone">
            <div class="spotOrderList_body_childTwo">
              <div class="goods">
                <div class="spotOrderList_body_img">
                  <img @click="enlargeImg(val)" :src="val.imgPath" alt="" />
                </div>
                <div class="orderStyleType">
                  <div class="bigClassName">{{ val.productName }}</div>
                  <div class="styleNumber-text">款号：{{ val.styleNumber }}</div>
                  <div class="spotBtn" v-if="pieceData.isSpot === '1' && pieceData.detailList[0].classify == '3' && pieceData.isPresell !== '1'">
                    现货手表
                  </div>
                  <div class="spotBtn" v-if="pieceData.isSpot === '0' && pieceData.detailList[0].classify == '3' && pieceData.isPresell !== '1'">
                    定单手表
                  </div>
                  <div class="spotBtn" v-if="pieceData.isSpot === '1' && pieceData.detailList[0].classify == '1' && pieceData.isPresell !== '1'">
                    现货素金
                  </div>
                  <div class="spotBtn" v-if="pieceData.isSpot === '0' && pieceData.detailList[0].classify == '1' && pieceData.isPresell !== '1'">
                    定单素金
                  </div>
                  <div class="spotBtn" v-if="pieceData.isPresell === '1'">预售定单</div>
                </div>
              </div>
              <div class="listChild orderSpecifications">
                <div class="specifications">
                  <div>
                    材质：<span>{{ val.texture }}</span>
                  </div>
                  <div>
                    品类：<span
                      >{{ val.productCategoryName }}<span v-if="val.gender">-{{ val.gender }}</span></span
                    >
                  </div>
                  <div>
                    金重：<span>{{ val.goldWeigh }}</span>
                  </div>
                  <div v-if="val.classify == 3">
                    表盘颜色：<span>{{ val.dialColor }}</span>
                  </div>
                  <div v-if="val.classify == 3">
                    表带颜色：<span>{{ val.bandColor }}</span>
                  </div>
                  <div v-if="val.classify == 1">
                    规格：<span>{{ val.ringSize }}</span>
                  </div>
                  <!-- <div v-if="val.classify == 1">
                    规格：<span v-if="val.ringType == '2' && pieceData.isSpot !== '1'">{{ val.ringSize }}</span>
                  </div> -->
                </div>
                <div class="specifications" style="margin-left:20px">
                  <div v-if="val.classify == 1">
                    销售克工费：<span>{{ val.saleWage }}</span>
                  </div>
                  <div v-if="val.classify == 1 && (!hasPermission('展厅') || !hasPermission('加盟公司总经理'))">
                    批发克工费：<span>{{ val.tradeWage }}</span>
                  </div>
                  <div v-if="val.classify == 1 && (!hasPermission('展厅') || !hasPermission('加盟公司总经理'))">
                    批发件工费：<span>{{ val.wholesale }}</span>
                  </div>
                  <div v-if="val.classify == 1 && (!hasPermission('展厅') || !hasPermission('加盟公司总经理'))">
                    配石批发价：<span>{{ val.pearlWholesale }}</span>
                  </div>
                  <div v-if="val.classify == 1">
                    订货周期：<span>{{ val.orderingCycle }}</span>
                  </div>
                  <div v-if="val.classify == 3">
                    手表类型：<span>{{ val.mechanicalType }}</span>
                  </div>
                  <div v-if="val.classify == 3">
                    表带材质：<span>{{ val.bandMaterial }}</span>
                  </div>
                </div>
              </div>
              <!-- <div class="orderLabel">
                <div>标签金额：{{ val.labelPrice }}</div>
                <div v-if="!hasPermission('展厅') || !hasPermission('加盟公司总经理')">批发金额：{{ val.tradePrice }}</div>
              </div> -->
              <div class="ordrerNumberChild">{{ pieceData.isSpot === '1' ? val.size : val.quantity }}</div>
              <div class="diamondRemarkClass">
                <el-tooltip class="item" effect="dark" :content="val.productRemark" placement="bottom">
                  <el-button>
                    <div class="spotOrderList_body_child_mainStone">
                      {{ val.productRemark }}
                    </div>
                  </el-button>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display:flex;align-items: center;position: absolute;left: 837px;top: 0;bottom: 0;height: 100%;">
        <div class="spotOrderList_Tail orderReamrk">
          <div style="display:flex;align-items:center">
            <el-tooltip class="item" effect="dark" :content="pieceData.remark" placement="top">
              <el-button>
                <div style="display: flex"><span style="display:block"></span><span v-text="pieceData.remark" class="totalRemark"></span></div>
              </el-button>
            </el-tooltip>
          </div>
        </div>
        <div class="orderAmount">
          <div>总件数：{{ pieceData.totalQuantity }}</div>
          <!-- <div>标签总金额：{{ pieceData.totalLabelAmount }}</div> -->
          <!-- <div v-if="!hasPermission('展厅') || !hasPermission('加盟公司总经理')">批发总金额：{{ pieceData.totalTradeAmount }}</div> -->
          <div v-if="pieceData.detailList[0].classify == '1' && pieceData.isSpot === '1'">现货素金总金重：{{ pieceData.totalGoldWeight }}</div>
          <div v-if="pieceData.detailList[0].classify == '1' && pieceData.isSpot !== '1'">定单素金总金重：{{ pieceData.totalCustomGoldWeight }}</div>
        </div>
        <div class="ordrerNumber">{{ pieceData.pendingQuantity }}</div>
        <div class="orderType">{{ pieceData.orderStatus | orderTypeFilter }}</div>
        <div class="updata">
          <div class="updataBtn" @click="getExpress('view')" v-if="pieceData.orderStatus == '6' || pieceData.orderStatus == '7'">查看物流</div>
          <div class="updataBtn" @click="getExpress('cancel')" v-if="pieceData.orderStatus == '0'">取消</div>
          <div class="updataBtn" @click="getExpress('verify')" v-if="pieceData.orderStatus == '6'">确认收货</div>
        </div>
      </div>
    </div>
    <div class="toViewMore" v-if="pieceData.detailList.length > 3" @click="showHidden">
      共有&nbsp;<span style="color:#ec9368">{{ pieceData.detailList.length }}</span
      >&nbsp;种商品,<span v-if="pieceData.showNone">还有&nbsp;{{ pieceData.detailList.length - 3 }}&nbsp;种,</span
      ><span v-if="pieceData.showNone">点击查看</span><span v-if="!pieceData.showNone">点击收起</span>
    </div>
    <PlainGoldSpot
      v-if="plainGoldSpotDialogVisible"
      :plainGoldSpotDialogVisible="plainGoldSpotDialogVisible"
      @onCloseSelectDialog="onCloseSelectDialog"
      :modifyingReplication="modifyingReplication"
    />
    <WatchSpot
      v-if="watchSpotDialogVisible"
      :watchSpotDialogVisible="watchSpotDialogVisible"
      @onCloseSelectDialog="onCloseSelectDialog"
      :modifyingReplication="modifyingReplication"
    />
  </div>
</template>
<script>
import PlainGoldSpot from './spot/PlainGoldSpot.vue';
import WatchSpot from './spot/WatchSpot.vue';
import { orderTypeFilter } from '@/utils/filetrs';
export default {
  name: 'GoldWatch',
  props: {
    pieceData: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  components: {
    PlainGoldSpot,
    WatchSpot,
  },
  filters: {
    orderTypeFilter,
  },
  data() {
    return {
      plainGoldSpotDialogVisible: false,
      watchSpotDialogVisible: false,
      modifyingReplication: {},
      mosaic: false,
    };
  },
  mounted() {
    if (this.pieceData.detailList[0].productName.indexOf('黄金镶嵌') != -1) {
      this.mosaic = true;
    }
  },
  methods: {
    // 获取物流
    getExpress(type) {
      this.$emit('fetchExpress', type, this.pieceData.orderNo);
    },
    // 订单跳转详情页
    enlargeImg(res) {
      if (this.pieceData.orderSource !== '1') {
        if (res.classify == '1') {
          this.$router.push({
            path: '/plaingold',
            query: {
              id: res.productId,
              generaName: res.productName,
              styleNumber: res.styleNumber,
              styleFlag: this.pieceData.isSpot === '1' ? '素金现货' : '素金定单',
              classify: res.classify,
              groupFlag: res.groupFlag,
            },
          });
        } else if (res.classify == '3') {
          this.$router.push({
            path: '/watch',
            query: {
              id: res.productId,
              styleNumber: res.styleNumber,
              serialNumber: res.serialNumber,
              generaName: res.productName,
              styleFlag: this.pieceData.isSpot === '1' ? '手表现货' : '手表定单',
              classify: res.classify,
              groupFlag: res.groupFlag,
            },
          });
        }
      }
    },
    // 获取现货列表
    getSpot(text, val) {
      if (text === '素金') {
        this.modifyingReplication = val;
        this.plainGoldSpotDialogVisible = true;
      } else {
        this.modifyingReplication = val;
        this.watchSpotDialogVisible = true;
      }
    },
    // 关闭现货弹窗
    onCloseSelectDialog() {
      this.plainGoldSpotDialogVisible = false;
      this.watchSpotDialogVisible = false;
    },
    // 展示隐藏大于3条的数据
    showHidden() {
      this.$emit('showHidden', this.index);
    },
    // 复制网点名称
    seccendCopy(netAddress) {
      var that = this;
      this.$copyText(netAddress).then(function(e) {
        that.$message({
          message: '复制成功!',
          type: 'success',
          duration: 1500,
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.diamond {
  .spotOrderList_head {
    display: flex;
    background: #fff2ea;
    line-height: 40px;
    border: 1px solid rgb(237, 226, 206);
    border-bottom: none;
    align-items: center;
    box-sizing: border-box;
    width: 1500px;

    .orderNumber {
      margin-left: 10px;
      .orderClass {
        padding: 4px;
        background: #ec9368;
        color: #ffffff;
        font-size: 12px;
        border-radius: 4px;
      }
    }

    .orderDate {
      margin: 0 18px 0 10px;
    }

    .network {
      margin-right: 18px;
    }

    .upDownTopClass {
      margin-right: 18px;

      .dotName {
        display: flex;
        font-family: -webkit-body;

        .dotNameTitle {
          display: block;
        }

        .dotNameText {
          text-align: left;
          width: 400px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .el-button {
        width: 600px;
        background: #fff2ea;
        border: none;
        font-size: 16px;
        padding: 0;
        text-align: left;
        color: #000;
        height: 40px;
        line-height: 40px;
      }
    }
  }

  .spotOrderList_body_img {
    margin-left: 10px;
    margin-top: 20px;

    img {
      width: 70px;
      height: 70px;
      box-sizing: border-box;
      cursor: pointer;
      border: 1px solid rgb(220, 223, 230);
    }
  }

  .spotOrderList_body_childTwo {
    display: flex;
    border-right: 1px solid rgb(220, 223, 230);
    border-bottom: 1px solid rgb(220, 223, 230);
  }

  .spotOrderList_body_data_father:last-child .spotOrderList_body_childTwo {
    border-bottom: none;
  }

  .spotOrderList {
    display: flex;
    align-items: center;
    border: 1px solid rgb(237, 226, 206);
    width: 1498px;
    position: relative;
  }

  .spotOrderList_body_data {
    min-height: 110px;
    border-bottom: 1px solid rgb(237, 226, 206);
    position: relative;
  }

  .spotOrderList_body_data:last-child {
    border-bottom: none;
  }

  .spotOrderList_body_data_father {
    margin-left: 6px;
    font-size: 14px;
  }

  .goods {
    display: flex;
    border-right: 1px solid #ede2ce;
    box-sizing: border-box;
    padding: 15px 0;
    width: 225px;
    margin-right: 5px;
  }

  .orderStyleType {
    margin-left: 10px;
    margin-top: 20px;
  }

  .spotBtn {
    width: 100px;
    margin-top: 10px;
    text-align: center;
    height: 32px;
    line-height: 32px;
    color: #ec9368;
    font-size: 14px;
    border: 1px solid #ec9368;
    border-radius: 5px;
    cursor: pointer;
  }

  .bigClassName:hover {
    color: red;
  }

  .bigClassName {
    width: 120px;
    white-space: pre-wrap;
  }

  .orderSpecifications {
    width: 410px;
    padding-right: 30px;
    border-right: 1px solid #ede2ce;
    box-sizing: border-box;
    padding: 15px 0;
    margin-right: 5px;
    justify-content: flex-start;
  }

  .orderLabel {
    width: 145px;
    padding: 15px 0;
    margin-right: 5px;
    border-right: 1px solid #ede2ce;
    box-sizing: border-box;
    white-space: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ordrerNumber {
    width: 75px;
    padding: 15px 0;
    margin-right: 5px;
    border-right: 1px solid #ede2ce;
    box-sizing: border-box;
    white-space: normal;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5px;
  }
  .ordrerNumberChild {
    width: 75px;
    padding: 15px 0;
    margin-right: 5px;
    border-right: 1px solid #ede2ce;
    box-sizing: border-box;
    white-space: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5px;
  }
  .orderReamrk {
    width: 100px;
  }

  .totalRemark {
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #999999;
    text-align: center;
  }

  .goodsRemark {
    width: 150px;
  }

  .orderAmount {
    width: 205px;
    border-left: 1px solid #ede2ce;
    border-right: 1px solid #ede2ce;
    padding-left: 5px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    div {
      margin: 10px 0;
    }
  }

  .orderType {
    width: 100px;
    padding-left: 5px;
    height: 100%;
    border-right: 1px solid #ede2ce;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: break-spaces;
  }
  .updata {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100px;
    padding-left: 5px;
    height: 100%;
    .updataBtn {
      padding: 6px 14px;
      color: #ec9368;
      margin: 10px;
      text-align: center;
      font-size: 14px;
      border-radius: 10px;
      cursor: pointer;
    }
  }
  .diamondRemarkClass {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .el-button {
      background: #fff;
      border: none;
      font-size: 16px;
      padding: 0;
      text-align: center;
      color: #000;
      width: 100px;
      height: 34px;
      line-height: 34px;
    }
  }

  .spotOrderList_Tail .el-button {
    width: 100px;
    background: transparent;
    border: none;
    font-size: 16px;
    padding: 0;
    text-align: left;
    color: #000 !important;
    font-family: -webkit-body;
  }

  .spotOrderList_body_child_mainStone {
    min-width: 90px;
    color: #ec9368;
    margin-top: 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }

  .toViewMore {
    text-align: center;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    border: 1px solid rgb(237, 226, 206);
    border-top: none;
  }
}
</style>
