<template>
  <div class="back">
    <div class="zlfLogo">
      <img src="../Img/login/logo-2.png" alt />
    </div>
    <div class="login_class" v-loading="loading" element-loading-text="拼命登录中。。。"></div>
  </div>
</template>

<script>
import ls from '@/utils/localStorage';
export default {
  data() {
    return {
      loading: true,
    };
  },
  components: {},
  mounted() {
    this.getLogin();
  },
  methods: {
    getLogin() {
      if (this.$route.query.token) {
        if (this.$route.query.coludQuery) {
          ls.set('coludQuery', JSON.parse(decodeURIComponent(this.$route.query.coludQuery)));
        }
        this.$store.dispatch('LoginToken', this.$route.query.token).then(() => {
          // 智慧零售一键下单
          if (this.$route.query.goldenPost) {
            return this.$router.push('/CommodityOrder');
          }
          if (this.$route.query.coludQuery) {
            var coludQuery = {
              id: JSON.parse(decodeURIComponent(this.$route.query.coludQuery)).id,
              styleNumber: JSON.parse(decodeURIComponent(this.$route.query.coludQuery)).styleNumber,
              productName: JSON.parse(decodeURIComponent(this.$route.query.coludQuery)).productName,
              generaName: JSON.parse(decodeURIComponent(this.$route.query.coludQuery)).generaName,
              type: JSON.parse(decodeURIComponent(this.$route.query.coludQuery)).type,
            };
            return this.$router.push({
              path:
                coludQuery.type == '1'
                  ? '/order'
                  : coludQuery.type === '2'
                  ? '/plaingold'
                  : coludQuery.type === '3'
                  ? '/watch'
                  : coludQuery.type == '4'
                  ? '/goldsetting'
                  : '',
              query: coludQuery,
            });
          }
          this.$router.push({ path: '/' });
        });
      } else {
        this.$router.push({ path: '/login' });
      }
    },
  },
};
</script>
<style scoped>
.el-loading-mask {
  background-color: transparent !important;
}
.zlfLogo {
  width: 126px;
  height: 58px;
  position: absolute;
  top: 78px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.back {
  position: absolute;
  width: 100%;
  bottom: 0;
  top: 0;
  background: url('../Img/login/back.png');
}
.login_class {
  width: 100%;
  margin-top: 300px;
}
.el-loading-spinner .circular {
  width: 55px !important;
  height: 55px !important;
}
.el-loading-spinner .el-loading-text {
  font-size: 20px !important;
}
</style>

<style scope>
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 1400px) {
}
@media screen and (min-width: 1600px) {
}
@media screen and (min-width: 1920px) {
}
</style>
