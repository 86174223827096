<template>
  <div class="loverSortBox">
    <div class="spotTabs">
      <el-tabs v-model="spotActive" type="card" @tab-click="handleClick">
        <el-tab-pane label="女款" name="first"> </el-tab-pane>
        <el-tab-pane label="男款" name="second"> </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 女款现货列表 -->
    <div class="custom" v-if="spotActive == 'first'">
      <div class="fx" v-if="lcTechnologList && lcTechnologList.length > 0">
        <div class="title">鎏彩工艺</div>
        <div class="material selectBox">
          <el-radio-group v-model="dataFrom.lcTechnology" size="small" @change="getSizeRange($event, 'lcTechnology', '女')">
            <span class="inchesFix">
              <el-radio-button v-for="item in lcTechnologList" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
            </span>
          </el-radio-group>
        </div>
      </div>
      <div class="fx" v-if="goldenWeighList && goldenWeighList.length > 0">
        <div class="title">金重</div>
        <div class="material selectBox">
          <el-radio-group v-model="dataFrom.goldWeigh" size="small" @change="getSizeRange($event, 'goldWeigh', '女')">
            <span class="inchesFix">
              <el-radio-button v-for="item in goldenWeighList" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
            </span>
          </el-radio-group>
        </div>
      </div>
      <!-- 规格 -->
      <div class="fx" v-if="handSizeList && handSizeList.length > 0">
        <div class="title">规格<span class="inchesTips" v-if="handSizeList && handSizeList[0].includes('寸')">(1英寸＝2.54厘米)</span></div>
        <div class="material selectBox">
          <el-radio-group v-model="dataFrom.ringSize" size="small" @change="getSizeRange($event, 'ringSize', '女')">
            <span class="inchesFix">
              <el-radio-button v-for="item in handSizeList" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
            </span>
          </el-radio-group>
        </div>
      </div>
      <!-- 备注 -->
      <div class="textareaRemark">
        <div class="title">备注</div>
        <el-input
          :rows="6"
          :maxlength="64"
          type="textarea"
          placeholder="其他要求与说明请在此处备注说明,字符数限制64位"
          v-model="dataFrom.productRemark"
        ></el-input>
      </div>
      <!-- 下单件数 -->
      <div class="box_detail_input marginFx">
        <div class="input-wrap">
          <span class="prompt"
            >库存{{ dropDownOptional.stockQuantity || 0 }}件<span class="inchesTips">（情侣款需成对下单）</span> ，可选择不同规格</span
          >
          <span class="prompt placeNum">购买数量：</span>
          <el-input-number v-model="dataFrom.quantity" :min="0" :max="stockQuantity" label="描述文字"></el-input-number>
        </div>
      </div>
    </div>
    <div class="custom" v-if="spotActive == 'second'">
      <!-- 男款现货列表 -->
      <div class="fx" v-if="maleLcTechnologList && maleLcTechnologList.length > 0">
        <div class="title">鎏彩工艺</div>
        <div class="material selectBox">
          <el-radio-group v-model="maleDataFrom.lcTechnology" size="small" @change="getSizeRange($event, 'lcTechnology', '男')">
            <span class="inchesFix">
              <el-radio-button v-for="item in maleLcTechnologList" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
            </span>
          </el-radio-group>
        </div>
      </div>
      <div class="fx" v-if="maleGoldenWeighList && maleGoldenWeighList.length > 0">
        <div class="title">金重</div>
        <div class="material selectBox">
          <el-radio-group v-model="maleDataFrom.goldWeigh" size="small" @change="getSizeRange($event, 'goldWeigh', '男')">
            <span class="inchesFix">
              <el-radio-button v-for="item in maleGoldenWeighList" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
            </span>
          </el-radio-group>
        </div>
      </div>
      <!-- 规格 -->
      <div class="fx" v-if="maleHandSizeList && maleHandSizeList.length > 0">
        <div class="title">规格<span class="inchesTips" v-if="maleHandSizeList && maleHandSizeList[0].includes('寸')">(1英寸＝2.54厘米)</span></div>
        <div class="material selectBox">
          <el-radio-group v-model="maleDataFrom.ringSize" size="small" @change="getSizeRange($event, 'ringSize', '男')">
            <span class="inchesFix">
              <el-radio-button v-for="item in maleHandSizeList" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
            </span>
          </el-radio-group>
        </div>
      </div>
      <!-- 备注 -->
      <div class="textareaRemark">
        <div class="title">备注</div>
        <el-input
          :rows="6"
          :maxlength="64"
          type="textarea"
          placeholder="其他要求与说明请在此处备注说明,字符数限制64位"
          v-model="maleDataFrom.productRemark"
        ></el-input>
      </div>
      <!-- 下单件数 -->
      <div class="box_detail_input marginFx">
        <div class="input-wrap">
          <span class="prompt"
            >库存{{ dropDownOptional.stockQuantity || 0 }}件<span class="inchesTips">（情侣款需成对下单）</span> ，可选择不同规格</span
          >
          <span class="prompt placeNum">购买数量：</span>
          <el-input-number v-model="maleDataFrom.quantity" :min="0" :max="maleStockQuantity" label="描述文字"></el-input-number>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getOptional, getPriceRangeSpot } from '@/api/detailsApi';
import { deepClone } from '@/utils/util';
export default {
  name: '',
  props: {
    DataList: {
      type: Object,
      default() {
        return {};
      },
    },
    leastQuantity: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      spotActive: 'first',
      dataFrom: {
        styleNumber: this.DataList.styleNumber, // 公司款号
        productName: this.DataList.productName,
        isCouples: this.DataList.isCouples, // 是否情侣款
        classify: 1, // 产品结构
        quantity: 0, // 数量
        shopType: 2,
        spotFlag: 1,
        ringSize: '',
        goldWeigh: '',
        productRemark: '',
        gender: '女',
      },
      maleDataFrom: {
        styleNumber: this.DataList.styleNumber, // 公司款号
        productName: this.DataList.productName,
        isCouples: this.DataList.isCouples, // 是否情侣款
        classify: 1, // 产品结构
        quantity: 0, // 数量
        shopType: 2,
        spotFlag: 1,
        ringSize: '',
        goldWeigh: '',
        productRemark: '',
        gender: '男',
      },
      dropDownOptional: {},
      lcTechnologList: [],
      goldenWeighList: [],
      handSizeList: [],
      maleLcTechnologList: [],
      maleGoldenWeighList: [],
      maleHandSizeList: [],
      stockQuantity: 0,
      maleStockQuantity: 0,
      lcTechnologyIndex: 0,
      maleLcTechnologyIndex: 0,
      goldWeighIndex: 0,
      maleGoldWeighIndex: 0,
    };
  },
  mounted() {
    this.dataFrom = { ...this.dataFrom, ...this.DataList };
    this.maleDataFrom = { ...this.maleDataFrom, ...this.DataList };
    // 获取可选属性
    this.getOptional();
  },
  methods: {
    handleClick(tab) {
      if (tab.name == 'first') {
        if (this.dropDownOptional.lcTechnologyFlag == 1) {
          this.lcTechnologList = Object.keys(this.dropDownOptional.couplesMap['女']);
          this.goldenWeighList = this.mainStoneSort(Object.keys(Object.values(this.dropDownOptional.couplesMap['女'])[this.lcTechnologyIndex]));
          this.handSizeList = this.sortFun(
            Object.values(Object.values(this.dropDownOptional.couplesMap['女'])[this.lcTechnologyIndex])[this.goldWeighIndex]
          );
        } else {
          this.goldenWeighList = this.mainStoneSort(Object.keys(this.dropDownOptional.couplesMap['女']));
          this.handSizeList = this.sortFun(Object.values(this.dropDownOptional.couplesMap['女'])[this.goldWeighIndex]);
        }
        this.fetchPriceRangeSpot('女');
      } else {
        if (this.dropDownOptional.lcTechnologyFlag == 1) {
          this.maleLcTechnologList = Object.keys(this.dropDownOptional.couplesMap['男']);
          this.maleGoldenWeighList = this.mainStoneSort(
            Object.keys(Object.values(this.dropDownOptional.couplesMap['男'])[this.maleLcTechnologyIndex])
          );
          this.maleHandSizeList = this.sortFun(
            Object.values(Object.values(this.dropDownOptional.couplesMap['男'])[this.maleLcTechnologyIndex])[this.maleGoldWeighIndex]
          );
        } else {
          this.maleGoldenWeighList = this.mainStoneSort(Object.keys(this.dropDownOptional.couplesMap['男']));
          this.maleHandSizeList = this.sortFun(Object.values(this.dropDownOptional.couplesMap['男'])[this.maleGoldWeighIndex]);
        }
        this.fetchPriceRangeSpot('男');
      }
    },
    // 金重排序
    mainStoneSort(list) {
      // 1. 将每个区间字符串解析为对象
      const parsedRanges = list?.map((range) => {
        const [start, end] = range.split('-').map(parseFloat);
        return { start, end, range }; // 保留原始字符串以便最后恢复
      });
      // 2. 根据 start 值进行排序
      parsedRanges.sort((a, b) => a.start - b.start);
      // 3. 提取排序后的原始字符串
      return parsedRanges.map((item) => item.range);
    },
    // 规格重新排序
    sortFun(handSizeList) {
      return handSizeList?.sort(function(a, b) {
        return a - b;
      });
    },
    // 获取可选属性
    getOptional(text) {
      let obj = {
        styleNumber: this.DataList.styleNumber,
        classify: 1,
        spotFlag: 1,
        gender: '',
      };
      getOptional(obj).then((res) => {
        this.dropDownOptional = { ...this.dropDownOptional, ...res.data };
        if (this.dropDownOptional.lcTechnologyFlag == 1) {
          this.lcTechnologList = Object.keys(res.data.couplesMap['女']);
          this.goldenWeighList = this.mainStoneSort(Object.keys(Object.values(res.data.couplesMap['女'])[0]));
          this.handSizeList = this.sortFun(Object.values(Object.values(res.data.couplesMap['女'])[0])[0]);
        } else {
          this.goldenWeighList = this.mainStoneSort(Object.keys(res.data.couplesMap['女']));
          this.handSizeList = this.sortFun(Object.values(res.data.couplesMap['女'])[0]);
        }
        this.$emit('upDataStockQuantity', this.dropDownOptional.stockQuantity);
      });
    },
    getSizeRange(value, type, gender) {
      if (gender === '女') {
        this.dataFrom[type] = value;
        if (this.dropDownOptional.lcTechnologyFlag == 1) {
          if (type === 'lcTechnology') {
            this.lcTechnologyIndex = this.lcTechnologList.indexOf(value);
            this.goldenWeighList = this.mainStoneSort(Object.keys(Object.values(this.dropDownOptional.couplesMap['女'])[this.lcTechnologyIndex]));
            this.handSizeList = this.sortFun(Object.values(Object.values(this.dropDownOptional.couplesMap['女'])[this.lcTechnologyIndex])[0]);
            this.dataFrom.goldWeigh = '';
            this.dataFrom.ringSize = '';
          }
          if (type === 'goldWeigh') {
            this.goldWeighIndex = this.goldenWeighList.indexOf(value);
            this.handSizeList = this.sortFun(Object.values(Object.values(this.dropDownOptional.couplesMap['女'])[this.lcTechnologyIndex])[0]);
            this.dataFrom.ringSize = '';
          }
        } else {
          if (type === 'goldWeigh') {
            this.goldWeighIndex = this.goldenWeighList.indexOf(value);
            this.handSizeList = this.sortFun(
              Object.values(this.dropDownOptional.couplesMap[this.dataFrom.gender])[this.goldenWeighList.indexOf(value)]
            );
            this.dataFrom.ringSize = '';
          }
        }
      } else {
        this.maleDataFrom[type] = value;
        if (this.dropDownOptional.lcTechnologyFlag == 1) {
          if (type === 'lcTechnology') {
            this.maleLcTechnologyIndex = this.maleLcTechnologList.indexOf(value);
            this.maleGoldenWeighList = this.mainStoneSort(
              Object.keys(Object.values(this.dropDownOptional.couplesMap['男'])[this.maleLcTechnologyIndex])
            );
            this.maleHandSizeList = this.sortFun(Object.values(Object.values(this.dropDownOptional.couplesMap['男'])[this.maleLcTechnologyIndex])[0]);
            this.maleDataFrom.goldWeigh = '';
            this.maleDataFrom.ringSize = '';
          }
          if (type === 'goldWeigh') {
            this.maleGoldWeighIndex = this.maleGoldenWeighList.indexOf(value);
            this.maleHandSizeList = this.sortFun(Object.values(Object.values(this.dropDownOptional.couplesMap['男'])[this.maleLcTechnologyIndex])[0]);
            this.maleDataFrom.ringSize = '';
          }
        } else {
          if (type === 'goldWeigh') {
            this.maleGoldWeighIndex = this.maleGoldenWeighList.indexOf(value);
            this.maleHandSizeList = this.sortFun(
              Object.values(this.dropDownOptional.couplesMap[this.maleDataFrom.gender])[this.maleGoldenWeighList.indexOf(value)]
            );
            this.maleDataFrom.ringSize = '';
          }
        }
      }
      this.fetchPriceRangeSpot(gender);
    },
    fetchPriceRangeSpot(gender) {
      const obj = gender == '女' ? deepClone(this.dataFrom) : deepClone(this.maleDataFrom);
      delete obj.texture;
      if (!obj.goldWeigh) return;
      getPriceRangeSpot(obj).then((res) => {
        if (gender == '女') {
          this.stockQuantity = res.data.stockQuantity;
          if (res.data.stockQuantity > this.leastQuantity && this.dataFrom.quantity > res.data.stockQuantity) {
            this.dataFrom.quantity = res.data.stockQuantity;
          }
        } else {
          this.maleStockQuantity = res.data.stockQuantity;
          if (res.data.stockQuantity > this.leastQuantity && this.maleDataFrom.quantity > res.data.stockQuantity) {
            this.maleDataFrom.quantity = res.data.stockQuantity;
          }
        }
        this.dropDownOptional.stockQuantity = res.data.stockQuantity;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.loverSortBox {
  margin-bottom: 10px;
  position: relative;

  .spotTabs {
    // width: 230px;
  }

  .spotTabs .el-tabs__nav {
    width: 100%;
  }

  .spotTabs .el-tabs__item {
    // width: 50%;
    text-align: center;
  }

  .spotTabs .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    border-bottom: #e4e7ed;
    background: #ec9368;
    color: #fff;
  }

  .spotTabs .el-tabs--card > .el-tabs__header .el-tabs__item.is-active:hover {
    color: #fff !important;
  }

  .spotTabs .el-tabs__item:hover {
    color: #ec9368 !important;
  }

  .loverPricesPrompt {
    font-size: 13px;
    color: red;
    position: absolute;
    top: 40px;
    z-index: 1;
  }

  .el-select-dropdown__item {
    margin: 10px 0 0 10px;
    display: inline-block;
    height: 46px;
    text-align: center;
    line-height: 46px;
    border: 1px solid #d3d3d3;
    padding: 0;
    font-size: 16px;
    color: #000;
  }

  .el-select-dropdown__item.selected {
    background: #fff2ea;
    font-size: 16px;
    color: #000;
    font-weight: 400;
    border: 1px solid #ee9c75;
  }
}
.custom {
  .fx {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    position: relative;
    margin: 8px 0;
  }

  .fl {
    display: flex;
    align-items: center;
    position: relative;
  }

  .selectBox {
    ::v-deep .el-popper {
      left: 0 !important;
    }

    .el-scrollbar__view li {
      width: 178px;
    }
  }

  .selectBoxWatch {
    .el-scrollbar__view li {
      min-width: 90px;
    }
  }
  .inchesFix {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .inchesTips {
    color: #ec9368;
  }
  .material {
    width: 800px;
    ::v-deep .el-radio-button__inner {
      min-width: 65px;
    }
  }
  .title {
    font-size: 18px;
    color: #000;
    display: inline-block;
    margin-bottom: 8px;
    .hidden {
      visibility: hidden;
    }
  }

  .value-font {
    font-size: 18px;
    color: #ec9368;
  }
  .mainStoneClssBoxFx {
    width: 675px;
    display: flex;
    align-items: center;
    position: relative;
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #fff;
    background-color: #ec9368;
    border-color: #ec9368;
    box-shadow: none;
  }
  ::v-deep .el-radio-button__inner:hover {
    color: rgb(236, 147, 104);
  }
  ::v-deep .el-radio-button__inner {
    margin: 5px;
    border-left: 1px solid #dcdfe6;
    border-radius: 4px;
  }
  ::v-deep .inchesFix:first-child .el-radio-button__inner {
    margin-left: 0;
  }
  ::v-deep .el-radio-group {
    display: flex;
    flex-wrap: wrap;
  }
  .input-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
}
</style>
