<template>
  <div class="home">
    <appheader index="0"></appheader>
    <zlf-search></zlf-search>
    <about></about>
    <bottom></bottom>
    <div class="subscribeBadge" @click="goSubscribe">
      <el-badge :value="badge" class="item">
        <img src="../../Img/detaile/not-subscribe-to.png" alt="" />
        到货通知
      </el-badge>
    </div>
    <!-- 隐私协议 -->
    <CheckPrivacy ref="privacyRef" />
  </div>
</template>
<script>
import Appheader from '.././moudel/Appheader';
import zlfSearch from './components/zlfSearch.vue';
import bottom from '../bady_bottom/bottom.vue';
import about from './components/zlfList.vue';
import CheckPrivacy from './checkPrivacy.vue';
import { subscribeBadge } from '@/api/detailsApi';
import ls from '@/utils/localStorage';
export default {
  name: 'Home',
  inject: ['reload'],
  data() {
    return {
      keepScroll: 0,
      badge: null,
    };
  },
  beforeRouteLeave(to, from, next) {
    // 从列表页去到别的页面，如果不是详情页，则不缓存列表页
    if (to.name != 'order' && to.name != 'plaingold' && to.name != 'watch' && to.name != 'goldsetting') {
      this.$route.meta.keepAlive = false;
      this.$route.meta.flag = false;
    } else if (to.name == 'order' || to.name == 'plaingold' || to.name == 'watch' || to.name == 'goldsetting') {
      this.$route.meta.keepAlive = true;
      this.$route.meta.flag = true;
      const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
      this.keepScroll = scrollTop;
    }
    next();
  },
  components: {
    Appheader,
    zlfSearch,
    bottom,
    about,
    CheckPrivacy,
  },
  mounted() {
    // 首次登录及隐私协议更新时弹出弹窗
    if (
      !ls.get('userInfo').privacyAgreementReadMap ||
      ls.get('userInfo').privacyAgreementReadMap.some((value, index, array) => {
        return value.EH_PC == 0;
      })
    ) {
      this.getPrivacyInfo();
    }
    this.getSubscribeBadge();
  },
  methods: {
    // 隐私协议弹窗
    getPrivacyInfo() {
      this.$refs.privacyRef.getPolicy().then((res) => {
        if (res) {
          this.$refs.privacyRef.dialogVisible = true;
        }
      });
    },
    getSubscribeBadge() {
      subscribeBadge().then((res) => {
        this.badge = res.data != 0 ? res.data : null;
      });
    },
    goSubscribe() {
      this.$router.push({ path: '/subscribe', query: { frontPort: 2 } });
    },
  },
  activated() {
    var gotoScroll = this.keepScroll;
    setTimeout(function() {
      //兼容 PC and Mobile 写两个
      document.body.scrollTop = gotoScroll;
      document.documentElement.scrollTop = gotoScroll;
    }, 10);
    this.getSubscribeBadge();
  },
};
</script>
<style scoped lang="scss">
.home {
  background: #f3f3f3;
  position: relative;
  .subscribeBadge {
    position: fixed;
    z-index: 1999;
    right: 30px;
    top: 300px;
    cursor: pointer;
    .el-badge {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid #ec9368;
      color: #ec9368;
      border-radius: 50%;
      font-size: 12px;
      width: 62px;
      height: 62px;
    }
    /deep/ .el-badge__content.is-fixed {
      right: 20px;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
