<template>
  <div class="box_btoom_conten">
    <div class="footer-copy">
      <p style="margin:0 20px 0 0">版本10.3</p>
      <p>技术支持：深圳市小雨滴信息技术有限公司</p>
      <p style="margin:0 20px">
        Copyright © 2018-2020 周六福 All rights reserved.
        <a href="http://beian.miit.gov.cn/" target="_blank" style="color:white;">粤ICP备13087551号</a>
      </p>
      <div>
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030302001449"
          style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"
          ><img src="../..//Img/record.png" style="float:left;" />
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">
            粤公网安备 44030302001449号
          </p>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style scope>
.dianhua {
  width: 20px;
  height: 20px;
}

.boex_bottom_top {
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}
.box_btoom_conten {
  width: 100%;
  background-color: #212529;
  margin-top: 40px;
}
.footer-copy {
  padding: 20px 0;
  /* background: #333333; */
  border-top: 1px solid #5c5c5c;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-copy > p {
  color: #7e7e7e;
  font-size: 14px;
  margin: 0;
  /* width: 100%; */
  text-align: center;
  /* margin-top: 80px; */
}
.footer-copy p a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
</style>
