/**
 *  防抖函数debounce：入参有三个，func：函数名，wait：间隔时间，
 * immediate：初始状态 false/true（不设置或者设置为 false，达到间隔 wait 执行一次，
 * 设置为 true，初始就会执行一次，间隔 wait 后才会触发下一次）
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;
  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;
    // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };
  return function(...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}
// 密码组合
export const oldPasswordFive = (val) => /^(?=.*[a-zA-Z])(?=.*[0-9])^.{8,16}$/.test(val);
export const oldPasswordTwo = (val) => /^(?=.*[a-zA-Z])(?=.*[@#%$&^*()+_/,!~])^.{8,16}$/.test(val);
export const oldPasswordThree = (val) => /^(?=.*[0-9])(?=.*[@#%$&^*()+_/,!~])^.{8,16}$/.test(val);
export const oldPasswordFour = (val) => /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@#%$&^*()+_/,!~])^.{8,16}$/.test(val);

// 非0合法金额
export const validateMoney = (val) => /(^[1-9]\d*(\.\d{1,2})?$)|(^0\.\d{1,2}$)/.test(val);

// 合法uri
export const validateURL = (val) =>
  /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/.test(
    val
  );

// 手机号
export const validateMobile = (val) => /^[1][0-9]{10}$/.test(val);

// 身份证
export const validateIdcard = (val) => /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(val);

// 真实姓名
export const validateName = (textval) => /^[\u4e00-\u9fa5]{2,4}$/.test(textval);

// 小写字母
export const validateLowerCase = (str) => /^[a-z]+$/.test(str);

// 大写字母
export const validateUpperCase = (str) => /^[A-Z]+$/.test(str);

// 大小写字母
export const validatAlphabets = (str) => /^[A-Za-z]+$/.test(str);

// 合法uri
export function url(value) {
  return /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/.test(
    value
  );
}
//手机号码
export function isPhoneNumber(tel) {
  var reg = /^0?1[3|4|5|6|7|8|9][0-9]\d{8}$/;
  return reg.test(tel);
}
//数字小数点
export function isNumber(tel) {
  var reg = /^[0-9]+\.{0,1}[0-9]{0,2}$/;
  return reg.test(tel);
}

// 汉字、字母、数字、_及中英文标点符号
export function wordPunctuation(rule, value, cb) {
  if (value === '' || value === null) return cb();
  if (!/^[\u4e00-\u9fa5\w\s·~！@#￥%…&*（）—\-+=【】{}；‘’：“”《》？，。、`!$^()[\]\\|;':",./<>?]+$/g.test(value)) {
    return cb(new Error('不能输入特殊字符'));
  }
  cb();
}

// 手机号
export function mobile(rule, value, callback) {
  const argumentsLen = arguments.length;
  if (!value) {
    callback();
  }
  if (argumentsLen > 3) {
    if (!/^[1][0-9]{10}$/.test(value)) {
      return callback(new Error('手机号格式有误'));
    }
    callback();
  } else {
    return /^[1][0-9]{10}$/.test(rule);
  }
}

// 金额
export function money(rule, value, callback) {
  const argumentsLen = arguments.length;
  if (argumentsLen > 2) {
    if (value && !/(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/.test(value)) {
      return callback(new Error('请输入数字，且最多保留两位小数'));
    }
    callback();
  } else {
    return /^[0-9]*$/.test(rule);
  }
}

// 验证只能输入数字和横杠
export function numbergang(rule, value, callback) {
  if (value === '' || value === null) callback();
  const argumentsLen = arguments.length;
  if (argumentsLen > 2) {
    if (/[^0-9-]+/.test(value)) {
      return callback(new Error('只能输入数字和连接符“-”'));
    }
    callback();
  } else {
    return /^[0-9]*$/.test(rule);
  }
}

// 请输入会员手机号,多个手机号之间用英文逗号","隔开
export function batchMobile(rule, value, callback) {
  if (value === '' || value === null) callback();
  let isPass = true;
  const arr = value.split(',');
  arr.forEach((v) => {
    if (!/^1\d{10}$/.test(v.trim())) {
      isPass = false;
    }
  });
  const argumentsLen = arguments.length;
  if (argumentsLen > 2) {
    if (!isPass) return callback(new Error('请输入会员手机号，多个手机号之间用英文逗号“,”隔开。注：末尾不要有逗号'));
    callback();
  } else {
    return isPass;
  }
}

// 数字
export function number(rule, value, callback) {
  if (value === '' || value === null) callback();
  const argumentsLen = arguments.length;
  if (argumentsLen > 2) {
    if (!/^[0-9]*$/.test(value)) {
      return callback(new Error('只能输入数字'));
    }
    callback();
  } else {
    return /^[0-9]*$/.test(rule);
  }
}

// 正整数
export function positiveNumber(rule, value, callback) {
  // if (value === '') callback()
  const argumentsLen = arguments.length;
  if (argumentsLen > 2) {
    if (value && !/^[1-9]\d*$/.test(value)) {
      return callback(new Error('只能输入大于零的整数'));
    }
    callback();
  } else {
    return /^[1-9]\d*$/.test(rule);
  }
}

// 身份证
export function isIDCard(rule, value, callback) {
  const argumentsLen = arguments.length;
  const reg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx])|([1−9]\d5\d2((0[1−9])|(10|11|12))(([0−2][1−9])|10|20|30|31)\d2[0−9Xx])/;
  if (argumentsLen > 2) {
    if (value && !reg.test(value)) {
      return callback(new Error('身份证号格式不对'));
    }
    callback();
  } else {
    return reg.test(rule);
  }
}

// 限制最大、最小值(包含小数)
export function limitFloatNumber(rule, value, callback, { min, max }) {
  console.log(parseFloat(min) && parseFloat(value) < parseFloat(min));
  if (parseFloat(min) && parseFloat(value) < parseFloat(min)) {
    return callback(new Error(`最小值为${min}`));
  }
  if (max && value > max) {
    return callback(new Error(`最大值为${max}`));
  }
  callback();
}

// 限制最大、最小值
export function limitNumber(rule, value, callback, { min, max }) {
  if (!/^[0-9]*$/.test(value)) {
    return callback(new Error('只能输入整数'));
  }

  if (min && value < min) {
    return callback(new Error(`最小值为${min}`));
  }
  if (max && value > max) {
    return callback(new Error(`最大值为${max}`));
  }
  callback();
}

export function perNumber(rule, value, callback) {
  const argumentsLen = arguments.length;
  if (argumentsLen > 2) {
    if (!/^[0-9]*$/.test(value)) {
      return callback(new Error('只能输入数字'));
    }
    callback();
  } else {
    return /^(100|[0-9]\d|\d)(.\d{1,2})?%$/.test(rule);
  }
}

// 大于0的数，并且可以有1-2位小数部分
export function min0(rule, value, callback, { min, max }) {
  if (!/^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d{1,2})$/.test(value)) {
    return callback(new Error('需大于0的数字，最多保留两位小数'));
  }
  if (min && value < min) {
    return callback(new Error(`最小值为${min}，最多保留两位小数`));
  }
  if (max && value > max) {
    return callback(new Error(`最大值为${max}，最多保留两位小数`));
  }
  callback();
}

// 数字字母汉字
export function atterNumberChiness(rule, value, cb) {
  const argumentsLen = arguments.length;
  const re = /^[\u4e00-\u9fa5a-zA-Z0-9]+$/g;
  if (argumentsLen > 2) {
    if (value === '' || value === null) return cb();
    if (!re.test(value)) {
      return cb(new Error('只能输入数字,字母,汉字'));
    }
    cb();
  } else {
    return re.test(rule);
  }
}
