import request from '@/utils/request';

// 进订单页获取数据
export function getList(data, type) {
  if (type == 1) {
    return request({
      // baseURL: process.env.VUE_APP_TM_API, //购物车接口单独的端口
      url:
        '/exhibition/orderInfo/list?current=' +
        data.current +
        '&size=' +
        data.size +
        '&orderNo=' +
        data.orderNo +
        '&netAddress=' +
        data.netAddress +
        '&netCode=' +
        data.netCode +
        '&orderStatus=' +
        data.orderStatus,
      method: 'get',
      data,
    });
  } else {
    return request({
      // baseURL: process.env.VUE_APP_TM_API, //购物车接口单独的端口
      url:
        '/exhibition/orderInfo/list?current=' +
        data.current +
        '&size=' +
        data.size +
        '&orderNo=' +
        data.orderNo +
        '&netAddress=' +
        data.netAddress +
        '&netCode=' +
        data.netCode +
        '&groupStatus=' +
        data.groupStatus +
        '&groupFlag=' +
        data.groupFlag,
      method: 'get',
      data,
    });
  }
}

// 进订状态
export function getState(data, type) {
  if (data?.groupFlag) {
    return request({
      // baseURL: process.env.VUE_APP_TM_API, //购物车接口单独的端口
      url: '/exhibition/orderInfo/read?groupFlag=' + data.groupFlag,
      method: type,
      data,
    });
  } else {
    return request({
      // baseURL: process.env.VUE_APP_TM_API, //购物车接口单独的端口
      url: '/exhibition/orderInfo/read',
      method: type,
      data,
    });
  }
}

// 现货列表
export function getSpot(data) {
  return request({
    // baseURL: process.env.VUE_APP_TM_API, //购物车接口单独的端口
    url: '/exhibition/orderInfo/detail/list?current=' + data.current + '&styleNumber=' + data.styleNumber + '&size=' + data.size + '&id=' + data.id,
    method: 'get',
    data,
  });
}
// 获取物流信息
export function getExpress(data) {
  return request({
    // baseURL: process.env.VUE_APP_TM_API, //购物车接口单独的端口
    url: '/exhibition/order-express/info?orderNo=' + data.orderNo,
    method: 'get',
    data,
  });
}
// 获取物流包裹
export function getParcel(data) {
  return request({
    // baseURL: process.env.VUE_APP_TM_API, //购物车接口单独的端口
    url: '/exhibition/order-express/detail?orderNo=' + data.orderNo + '&expressNo=' + data.expressNo,
    method: 'get',
    data,
  });
}
// 取消订单
export function cancelOrder(data) {
  return request({
    // baseURL: process.env.VUE_APP_TM_API, //购物车接口单独的端口
    url: '/exhibition/orderInfo/cancel?orderNo=' + data.orderNo,
    method: 'post',
    data,
  });
}
// 确认收货
export function verifyOrder(data) {
  return request({
    // baseURL: process.env.VUE_APP_TM_API, //购物车接口单独的端口
    url: '/exhibition/orderInfo/confirm?orderNo=' + data.orderNo,
    method: 'post',
    data,
  });
}
