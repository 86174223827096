import request from '@/utils/request';

//加入post、修改put、删除delete 购物车
export function modifyCartPublic(data, type) {
  return request({
    // baseURL: process.env.VUE_APP_TM_API, //购物车接口单独的端口
    url: '/exhibition/shoppingCart',
    method: type,
    data,
  });
}
// 获取购物车列表数据
export function cartListData(data) {
  return request({
    // baseURL: process.env.VUE_APP_TM_API, //购物车接口单独的端口
    url: `/exhibition/shoppingCart/list?size=${data.size}&current=${data.current}&styleNumber=${data.styleNumber}&siteId=${data.siteId}&pageType=${data.pageType}&requestFrom=${data.requestFrom}`,
    method: 'get',
  });
}

// 获取购物车现货列表
export function spotPageList(data) {
  return request({
    // baseURL: process.env.VUE_APP_TM_API, //购物车接口单独的端口
    url: `/exhibition/shoppingCart/getShoppingCartById?cartId=${data.cartId}&current=${data.current}&size=${data.size}`,
    method: 'post',
    data,
  });
}
// 获取购物车素金现货列表
export function spotGoldPageList(data) {
  return request({
    // baseURL: process.env.VUE_APP_TM_API, //购物车接口单独的端口
    url: `/exhibition/shoppingCart/getShoppingCartById?cartId=${data.id}&current=${data.current}&size=${data.size}`,
    method: 'get',
    data,
  });
}
// 获取上次下单信息
export function getOrderInfo() {
  return request({
    // baseURL: process.env.VUE_APP_TM_API, //购物车接口单独的端口
    url: '/exhibition/orderInfo/lastOrder',
    method: 'get',
    params: {},
  });
}
// 删除现货列表
export function deleteSpot(data) {
  return request({
    url: '/exhibition/shoppingCart/deleteShopCartB',
    method: 'PUT',
    data,
  });
}
// 修改购物车定单商品
export function editCart(data) {
  return request({
    url: '/exhibition/shoppingCart/editCart',
    method: 'POST',
    data,
  });
}
// 复制购物车定单商品
export function copyCart(data) {
  return request({
    url: '/exhibition/shoppingCart/editCart',
    method: 'POST',
    data,
  });
}
// 下单
export function pushOrder(data) {
  return request({
    // baseURL: process.env.VUE_APP_TM_API, //购物车接口单独的端口
    url: '/exhibition/orderInfo',
    method: 'POST',
    data,
  });
}
// 获取购物车失效商品列表数据
export function getLoseEffectivenessList(data) {
  return request({
    // baseURL: process.env.VUE_APP_TM_API, //购物车接口单独的端口
    url: `/exhibition/shoppingCart/invalid-list?size=${data.size}&current=${data.current}&styleNumber=${data.styleNumber}`,
    method: 'get',
  });
}
// 获取网点列表
export const getNetworkList = (data) => {
  return request({
    url: '/admin/organization/network',
    method: 'get',
    params: data,
  });
};
