import request from '@/utils/request';

// 获取详情页
export function getDiamondInfo(data) {
  return request({
    url: `/exhibition/product/getDetail/${data.styleNumber}?productPage=0&classify=${data.classify}`,
    method: 'get',
    params: {},
  });
}
// 获取详情页现货列表
export function getDiamondSpotList(data) {
  return request({
    url: `/exhibition/product-detail/search?size=${data.size}&current=${data.current}`,
    method: 'post',
    data,
  });
}
// 钻石现货规格
export const getSpotSpecification = (data) => {
  return request({
    url: `/exhibition/product-detail/search`,
    method: 'post',
    data,
  });
};
// 获取详情页可选属性
export function getOptional(data) {
  return request({
    url: `/exhibition/product-detail/select/specifications`,
    method: 'post',
    data,
  });
}
// 获取价格范围-现货库存
export const getPriceRangeSpot = (data) => {
  return request.post(`/exhibition/product-detail/price`, data);
};
// 获取钻石金重
export function getWeight(data) {
  return request({
    url: `/exhibition/product-custom-attribute/weight`,
    method: 'post',
    data,
  });
}

// 获取钻石价格范围
export function getPriceRange(data) {
  return request({
    url: `/exhibition/product-custom-attribute/price`,
    method: 'post',
    data,
  });
}

// 获取手表价格范围
export function getWatchPriceRange(data) {
  return request({
    url: `/exhibition/product-custom-attribute/watch/price`,
    method: 'post',
    data,
  });
}

// 获取素金价格范围
export function getPlainPriceRange(data) {
  return request({
    url: `/exhibition/product-custom-attribute/plain/price`,
    method: 'post',
    data,
  });
}
// 通过款号获取产品分类
export const getClassify = (styleNumber) => {
  return request({
    url: `/exhibition/product/getClassify/${styleNumber}`,
    method: 'get',
    params: {},
  });
};
// 收藏商品
export function addCollection(data) {
  return request({
    url: `/exhibition/collection/${data.id}?isLike=${data.isLike}&columnCode=${data.columnCode}`,
    method: 'PUT',
  });
}
// 感兴趣
export function interestedIn(data) {
  return request({
    url: `/exhibition/product-like/${data.id}?isLike=${data.isLike}`,
    method: 'PUT',
  });
}

// 感兴趣数量
export function interestCut(data) {
  return request({
    url: `/exhibition/product-like/like/count/${data}`,
    method: 'get',
    params: {},
  });
}

// 相似推荐
export function getSimilarity(styleNumber) {
  return request({
    url: `/exhibition/product/similarRecommendPage/${styleNumber}`,
    method: 'get',
    params: {},
  });
}
// 套系推荐
export function getMantleSystem(styleNumber) {
  return request({
    url: `/exhibition/product/recommendPage/${styleNumber}`,
    method: 'get',
    params: {},
  });
}
// 进详情页获取数据
export function getDatailList(data) {
  return request({
    url: `/exhibition/cloudhall/goods/info?styleNumber=${data}`,
    method: 'get',
    params: {},
  });
}

// 获取可选属性跟金重
export function dynamicAttr(data) {
  return request({
    url: '/exhibition/cloudhall/goods/optionalAttr',
    method: 'post',
    data,
  });
}
// 钻石价格区间及库存
export function getPriceInventory(data) {
  return request({
    url: '/exhibition/cloudhall/goods/storeAndPrice',
    method: 'post',
    data,
  });
}
// 素金价格区间与库存
export function getGoldPriceInventory(data) {
  return request({
    url: '/exhibition/cloudhall/goods/GoldStoreAndPrice',
    method: 'post',
    data,
  });
}
// 现货列表
export function getSpotPaging(data) {
  return request({
    url: '/exhibition/cloudhall/goods/store?pageSize=' + data.pageSize + '&pageNum=' + data.pageNum,
    method: 'post',
    data,
  });
}
// 情侣款加入购物车
export function loversAddCart(data) {
  return request({
    url: '/exhibition/shoppingCart/addListCart',
    method: 'post',
    data,
  });
}
// 查询是否已订阅
export function whetherSubscribeTo(data) {
  return request({
    url: '/exhibition/subscribeProduct/isSubscribe',
    method: 'post',
    data,
  });
}
// 点击订阅-取消订阅
export function editSubscribeTo(data) {
  return request({
    url: '/exhibition/subscribeProduct/subscribe',
    method: 'post',
    data,
  });
}
// 订阅列表
export function subscribeList(data) {
  return request({
    url: '/exhibition/subscribeProduct/subscribeList',
    method: 'post',
    data,
  });
}
// 订阅数量角标
export function subscribeBadge(data) {
  return request({
    url: '/exhibition/subscribeProduct/subscribeSum',
    method: 'post',
    data,
  });
}
// 批量取消订阅
export function allCancelSubscribe(data) {
  return request({
    url: '/exhibition/subscribeProduct/cancelSubscribeBatch',
    method: 'post',
    data,
  });
}
// 已读订阅-角标
export function readStatus(data) {
  return request({
    url: '/exhibition/subscribeProduct/updateReadStatus',
    method: 'post',
    data,
  });
}
/**
 * 埋点类型：0 浏览，1 加入购物车，2 下单
 */
export function messagePoint(data) {
  return request({
    url: '/exhibition/product/save/exhibition/point',
    method: 'post',
    data,
  });
}
