<template>
  <div class="header" v-cloak>
    <div class="header_box">
      <div style="display: inline-block">
        <img src="../../Img/List/logo.png" @click.stop="toIndex" alt class="headerLogo" />
      </div>
      <div class="userName">
        <div class="homeBox" @click.stop="gohome">
          <svg-icon iconClass="homeIcon" class="homeIcon"></svg-icon>
          <span class="headerSize" v-if="index == 0" style="color: #ec9368">首页</span>
          <span class="headerSize" v-else>首页</span>
        </div>
        <div class="spacer"></div>
        <div @click.stop="gotoshoppingCart" class="homeBox">
          <svg-icon iconClass="gouwuche" class="gouwuche"></svg-icon>
          <span class="headerSize" v-if="index == 1" style="color: #ec9368">购物车</span>
          <span class="headerSize" v-else>购物车</span>
        </div>
        <div class="spacer"></div>
        <div @click.stop="gotoCommodityOrder(1)" class="homeBox">
          <svg-icon iconClass="myOrder" class="myOrder"></svg-icon>
          <span class="headerSize" v-if="index == 2" style="color: #ec9368">我的订单</span>
          <span class="headerSize" v-else>我的订单</span>
        </div>
        <div class="spacer"></div>
        <div @click.stop="gotoCommodityOrder(2)" class="homeBox">
          <svg-icon iconClass="myOrder" class="myOrder"></svg-icon>
          <span class="headerSize" v-if="index == 4" style="color: #ec9368">预售订单</span>
          <span class="headerSize" v-else>预售订单</span>
        </div>
        <div class="spacer"></div>
        <div style="position: relative">
          <div @click.stop="setout" class="homeBox">
            <svg-icon iconClass="wode" class="wode"></svg-icon>
            <span class="headerSize" v-if="index == 3" style="color: #ec9368">{{ userData.username }}</span>
            <span class="headerSize" v-else>{{ userData.username }}</span>
          </div>
          <div class="header_wode">
            <!-- <div class="user_zhanghu" v-if="userData.username" @click="upshowCondition">
              {{ userData.username }}
            </div> -->
            <div class="user_Out" @click.stop="goCollection('收藏')" @mouseenter="enter('收藏')" @mouseleave="leave('收藏')">
              <svg-icon className="columnIcon" v-if="!collection" iconClass="shoucang_icon_moren"></svg-icon>
              <svg-icon v-if="collection" className="columnIcon" iconClass="shoucang_icon_xuanzhong"></svg-icon>
              我的收藏
            </div>
            <div class="user_Out" @click.stop="goCollection('足迹')" @mouseenter="enter('足迹')" @mouseleave="leave('足迹')">
              <svg-icon className="columnIcon" v-if="!footprint" iconClass="zuji_icon_moren"></svg-icon>
              <svg-icon className="columnIcon" v-if="footprint" iconClass="zuji_icon_xuanzhong"></svg-icon>
              我的足迹
            </div>
            <!-- <div class="user_Out" v-if="userData.position == 'zlfAdmin'" @click.stop="goCollection('账号管理')">
              <svg-icon iconClass="changepassword-selected" v-if="accountmanagement" className="collection_default"></svg-icon>
              <svg-icon iconClass="changepassword-default" v-if="!accountmanagement" className="collection_default"></svg-icon>
              账号管理
            </div> -->
            <!-- <div class="user_Out" v-if="userData.position == 'zlfAdmin'" @click.stop="goCollection('组织机构')">
              <svg-icon iconClass="organization-selected" v-if="organization" className="collection_default"></svg-icon>
              <svg-icon iconClass="organization-default" v-if="!organization" className="collection_default"></svg-icon>
              组织机构
            </div> -->
            <!-- <div class="user_Out" v-if="userData.position == 'zlfAdmin'" @click.stop="goCollection('日志管理')">
              <svg-icon iconClass="logmanagement-selected" v-if="logmanagement" className="collection_default"></svg-icon>
              <svg-icon iconClass="logmanagement-default" v-if="!logmanagement" className="collection_default"></svg-icon>
              日志管理
            </div> -->
            <!-- <div class="user_Out"   @click.stop="goCollection('修改密码')">
              <svg-icon iconClass="footprint_selected" v-if="changepassword" className="collection_default"></svg-icon>
              <svg-icon iconClass="footprint_default" v-if="!changepassword" className="collection_default"></svg-icon>修改密码
            </div> -->
            <div class="user_Out" @click.stop="goCollection('用户反馈')" @mouseenter="enter('用户反馈')" @mouseleave="leave('用户反馈')">
              <svg-icon className="columnIcon" v-if="!feedback" iconClass="yijian_icon_moren"></svg-icon>
              <svg-icon className="columnIcon" v-if="feedback" iconClass="yijian_icon_xuanzhong"></svg-icon>
              用户反馈
            </div>
            <div class="user_Out" @click.stop="goCollection('到货列表')" @mouseenter="enter('到货列表')" @mouseleave="leave('到货列表')">
              <svg-icon className="columnIcon" v-if="!noticeArrival" iconClass="tongzhi_icon_moren"></svg-icon>
              <svg-icon className="columnIcon" v-if="noticeArrival" iconClass="tongzhi_icon_xuanzhong"></svg-icon>
              到货通知
            </div>
            <div class="user_Out" @click.stop="goCollection('联系我们')" @mouseenter="enter('联系我们')" @mouseleave="leave('联系我们')">
              <svg-icon className="columnIcon" v-if="!contactus" iconClass="contact_us"></svg-icon>
              <svg-icon className="columnIcon" v-if="contactus" iconClass="contact_us_xuanzhong"></svg-icon>
              联系我们
            </div>
            <div class="user_Out" @mouseenter="enter('操作指引')" @mouseleave="leave('操作指引')">
              <a href="https://static1.zlf.cn/pdf/order-1.pdf" target="_black" style="text-decoration: none; font-size: 18px">
                <svg-icon className="columnIcon" v-if="!controls" iconClass="caozuo_icon_moren"></svg-icon>
                <svg-icon className="columnIcon" v-if="controls" iconClass="caozuo_icon_xuanzhong"></svg-icon>
                操作指引
              </a>
            </div>
            <div class="user_Out" @click.stop="loginOut" @mouseenter="enter('退出')" @mouseleave="leave('退出')">
              <svg-icon className="columnIcon" v-if="!logout" iconClass="tuichu_icon_moren"></svg-icon>
              <svg-icon className="columnIcon" v-if="logout" iconClass="tuichu_icon_xuanzhong"></svg-icon>
              退出
            </div>
          </div>
        </div>
        <div class="spacer"></div>
        <div class="shadow" @click="upshowCondition"></div>
      </div>
    </div>
    <UserFeedback ref="dialogUserFeedbackEditorRef" />
    <visitorDialog ref="visitorRef" />
  </div>
</template>
<script>
import $ from 'jquery';
import ls from '@/utils/localStorage';
import UserFeedback from './UserFeedback';
import visitorDialog from '@/components/visitorDialog.vue';
export default {
  props: ['index'],
  data() {
    return {
      userName: true,
      userData: ls.get('userData'),
      logout: false,
      collection: false,
      footprint: false,
      feedback: false,
      noticeArrival: false,
      controls: false,
      accountmanagement: false,
      organization: false,
      logmanagement: false,
      changepassword: false,
      contactus: false,
    };
  },
  components: {
    UserFeedback,
    visitorDialog,
  },
  mounted() {
    if (!ls.get('userData')) {
      this.$store.dispatch('LogOut').then(() => {
        this.$router.replace('/login');
      });
    }
  },
  activated() {},
  methods: {
    enter(e) {
      if (e == '收藏') {
        this.collection = true;
      } else if (e == '足迹') {
        this.footprint = true;
      } else if (e == '退出') {
        this.logout = true;
      } else if (e == '用户反馈') {
        this.feedback = true;
      } else if (e == '到货列表') {
        this.noticeArrival = true;
      } else if (e == '操作指引') {
        this.controls = true;
      } else if (e == '联系我们') {
        this.contactus = true;
      } else if (e == '退出') {
        this.logout = true;
      }
    },
    leave(e) {
      if (e == '收藏') {
        this.collection = false;
      } else if (e == '足迹') {
        this.footprint = false;
      } else if (e == '退出') {
        this.logout = false;
      } else if (e == '用户反馈') {
        this.feedback = false;
      } else if (e == '到货列表') {
        this.noticeArrival = false;
      } else if (e == '操作指引') {
        this.controls = false;
      } else if (e == '联系我们') {
        this.contactus = false;
      } else if (e == '退出') {
        this.logout = false;
      }
    },
    // 跳转收藏或者足迹
    goCollection(text) {
      if (text === '收藏') {
        this.$router.push({ path: '/collection' });
      } else if (text === '足迹') {
        this.$router.push({ path: '/footprint' });
      } else if (text === '修改密码') {
        this.$router.push({ path: '/changepassword' });
      } else if (text === '用户反馈') {
        this.$refs.dialogUserFeedbackEditorRef.onShow();
        this.upshowCondition();
      } else if (text === '到货列表') {
        this.$router.push({ path: '/subscribe', query: { frontPort: 1 } });
      } else if (text === '联系我们') {
        this.$router.push({ path: '/contactUs' });
      }
      document.body.style.overflow = 'auto';
    },
    toIndex() {
      $('.shadow').css('display', 'none');
      $('.header_wode').css('display', 'none');
      this.$router.push({ path: '/home' });
    },
    // 前往购物车
    gotoshoppingCart() {
      if (this.userData.username == '18888888888') {
        this.$refs.visitorRef.handleVisitor();
      } else {
        $('.shadow').css('display', 'none');
        $('.header_wode').css('display', 'none');
        this.$router.push({ path: '/shoppingCart' });
        localStorage.setItem('title', '');
        localStorage.setItem('val', '');
      }
    },
    // 前往订单页
    gotoCommodityOrder(type) {
      if (this.userData.username == '18888888888') {
        this.$refs.visitorRef.handleVisitor();
      } else {
        $('.shadow').css('display', 'none');
        $('.header_wode').css('display', 'none');
        if (type == 1) {
          this.$router.push({ path: './CommodityOrder' });
        } else this.$router.push({ path: './PresellOrder' });
        localStorage.setItem('title', '');
        localStorage.setItem('val', '');
      }
    },
    // 前往首页
    gohome() {
      if (this.$route.path === '/home') {
        this.$router.go(0);
      }
      $('.shadow').css('display', 'none');
      $('.header_wode').css('display', 'none');
      this.$router.push({ path: '/home' });
      localStorage.setItem('title', '');
      localStorage.setItem('val', '');
    },
    // 退出登录
    loginOut() {
      document.body.style.overflow = 'auto';
      this.$store.dispatch('LogOut').then(() => {
        this.$router.replace('/login');
      });
    },
    setout() {
      if (this.userData.username == '18888888888') {
        this.$refs.visitorRef.handleVisitor();
      } else {
        $('.shadow').css('display', 'block');
        $('.header_wode').css('display', 'block');
        $('.header_wode').animate({ top: '28px' });
        document.body.style.overflow = 'hidden';
      }
    },
    // 关闭弹窗
    upshowCondition() {
      $('.header_wode').animate({ top: '-400px' });
      // $(".header_wode").css("display", "none");
      $('.shadow').css('display', 'none');
      document.body.style.overflow = 'auto';
      // this.userName = false
    },
    userNamedata() {
      this.userName = true;
    },
  },
};
</script>

<style scope>
[v-cloak] {
  display: none;
}
.user_Out:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: none;
}
.user_Out {
  cursor: pointer;
  width: 100%;
  height: 42px;
  border-bottom: 1px solid #f3f3f3;
  font-size: 18px;
  color: #000;
  background-color: #fff;
  text-align: center;
  line-height: 42px;
}
.user_Out:hover {
  color: #ec9368;
}
.user_Out a {
  color: #000000;
}
.user_Out a:hover {
  color: #ec9368;
}
.collection_default {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.user_zhanghu {
  width: 100%;
  height: 52px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
  font-size: 18px;
  color: #ec9368;
  background-color: #fdf2ec;
  border-radius: 5px;
  line-height: 52px;
}
.box_header_wode {
  width: 274px;
  height: 94px;
}
.header_wode {
  width: 200px;
  /* height: 180px; */
  /* position: relative; */
  position: absolute;
  z-index: 100001;
  right: -47px;
  top: -208px;
  display: none;
  border-radius: 5px;
  background-color: #fff;
}
/* // 遮罩层 */
.shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
  background-color: #000;
  opacity: 0.6;
  display: none;
}
.header_box {
  display: flex;
  justify-content: space-between;
  width: 1500px;
  margin: 0 auto;
  align-items: center;
}
.wode {
  cursor: pointer;
  width: 22px;
  height: 22px;
}
.gouwuche {
  cursor: pointer;
  width: 22px;
  height: 22px;
  margin-right: 3px;
}
.myOrder {
  cursor: pointer;
  /* right: 1px; */
  width: 22px;
  height: 22px;
  margin-right: 3px;
}
.homeIcon {
  cursor: pointer;
  width: 22px;
  height: 22px;
  margin-right: 3px;
}
.guide {
  width: 24px;
  height: 24px;
}
.headerSize {
  cursor: pointer;
  font-size: 18px;
  color: #000;
}
.headerLogo {
  width: 128px;
  height: 59px;
  display: inline-block;
  /* margin-left: 360px; */
  padding-top: 10px;
  padding-bottom: 11px;
}
.city {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid gray;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.spacer {
  width: 2px;
  background: #ec9368;
  height: 18px;
  margin: 0 20px;
}
.userName {
  display: flex;
  font-size: 0;
  align-items: center;
}
.homeBox {
  display: flex;
  align-items: center;
}
.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  background-image: url('../../Img/List/hera.png');
}
.columnIcon {
  width: 20px;
  height: 20px;
}
</style>
