<template>
  <div class="cartDiamondCustom">
    <div :class="['cartDiamondCustomList', cycleCustom.flag === true && flag === true ? 'cartDiamondBg' : '']">
      <div class="outerLayer">
        <el-checkbox @change="onChange" v-model="cycleCustom.flag"></el-checkbox>
      </div>
      <div class="cartImg">
        <div @click="goDetaild(cycleCustom[0])">
          <el-image :src="cycleCustom[0].product.imgPath"> </el-image>
          <div class="outStock" v-if="isSoldOut">部分<br />已售</div>
          <div class="outStock" v-if="isDelist">
            已下架
          </div>
          <div class="outStock" v-if="cycleCustom[0].isFailure == 1">
            暂无现货
          </div>
        </div>
      </div>
      <div class="cartDiamondCustomParameter" v-for="(item, index) in cycleCustom" :key="index">
        <div class="cartDiamondCustomHead" v-if="index === 0">
          <span class="seriesModel">系列</span>
          <span class="seriesModel">款号</span>
          <span class="cartTexture">材质</span>
          <span class="cartMainTtoneWeight">规格</span>
          <span class="cartMainTtoneWeight">GIA</span>
          <span class="cartStone">主石</span>
          <!-- <span class="cartSize">主石重量</span> -->
          <span class="cartGoldWeigh">辅石名称</span>
          <span class="cartGoldWeigh">版型</span>
          <span class="cartMainTtoneWeight">净度</span>
          <span class="cartMainTtoneWeight">颜色</span>
          <span class="cartCategory">备注</span>
          <span class="cartSize">商品数量</span>
          <span class="cartStone"
            >标签金额<el-popover placement="top" title="" width="200" trigger="hover" content="标签金额为预估标签金额，实际以出单金额为准。">
              <span slot="reference">
                <svg-icon iconClass="question-mark" class="question-mark"></svg-icon>
              </span> </el-popover
          ></span>
          <span class="cartStone" v-if="!hasPermission('展厅') || !hasPermission('加盟公司总经理')"
            >批发金额<el-popover placement="top" title="" width="200" trigger="hover" content="批发金额为预估批发金额，实际以出单金额为准。">
              <span slot="reference">
                <svg-icon iconClass="question-mark" class="question-mark"></svg-icon>
              </span> </el-popover
          ></span>
          <span class="cartStone"
            >尊享价<el-popover placement="top" title="" width="200" trigger="hover" content="尊享价为预估标签金额，实际以出单金额为准。">
              <span slot="reference">
                <svg-icon iconClass="question-mark" class="question-mark"></svg-icon>
              </span> </el-popover
          ></span>
        </div>
        <div v-for="(res, idx) in item.cartList" :key="idx" class="dataContentFather">
          <div :class="['dataContent', res.switchFlag === true ? 'curtBg' : '']">
            <div class="innerLayerSwitch">
              <el-checkbox
                v-if="cycleCustom[0].product.isCouples === '2'"
                v-model="res.switchFlag"
                :disabled="cycleCustom[0].totalQuantityFlag === true && res.spotFlag == 2"
                @change="innerLayer(item)"
              ></el-checkbox>
              <el-checkbox
                v-if="cycleCustom[0].product.isCouples === '1' && res.gender === '女'"
                v-model="res.switchFlag"
                :disabled="cycleCustom[0].spotFlag == 2 && cycleCustom[0].totalFemaleQuantityFlag === true && res.gender === '女'"
                @change="innerLayer(item)"
              ></el-checkbox>
              <el-checkbox
                v-if="cycleCustom[0].product.isCouples === '1' && res.gender === '男'"
                v-model="res.switchFlag"
                :disabled="cycleCustom[0].spotFlag == 2 && cycleCustom[0].totalMaleQuantityFlag === true && res.gender === '男'"
                @change="innerLayer(item)"
              ></el-checkbox>
            </div>
            <span class="seriesModel">{{ cycleCustom[0].product.categoryCodeTwo }}</span>
            <span class="seriesModel">{{ res.styleNumber }}</span>
            <span class="cartTexture"
              >{{ res.texture
              }}<span class="tips" v-if="item.product.isCouples === '1' && item.product.spotFlag == '1' && res.materialFlag === true"
                >需成对且同材质下单</span
              ></span
            >
            <span class="cartMainTtoneWeight"
              ><span>{{ res.ringSize }}</span></span
            >
            <span class="cartMainTtoneWeight"
              ><span>{{ res.gia | cutFilter }}</span></span
            >
            <span class="cartStone"
              ><span>{{ res.mainStone }}</span></span
            >
            <!-- <span class="cartSize"
              ><span>{{ res.mainStoneSize }}</span></span
            > -->
            <span class="cartGoldWeigh"
              ><span>{{ res.gemName }}</span></span
            >
            <span class="cartGoldWeigh"
              ><span>{{ res.formatType }}</span></span
            >
            <span class="cartMainTtoneWeight"
              ><span>{{ res.mainStoneClarity }}</span></span
            >
            <span class="cartMainTtoneWeight"
              ><span>{{ res.mainStoneColour }}</span></span
            >
            <span class="cartCategory">
              <el-tooltip class="item" effect="dark" :content="res.productRemark" placement="bottom">
                <el-button>{{ res.productRemark }}</el-button>
              </el-tooltip>
            </span>
            <span class="cartSize reminder"
              >{{ res.quantity
              }}<span
                class="tips "
                v-if="
                  cycleCustom[0].spotFlag == 2 &&
                    cycleCustom[0].totalQuantityFlag === true &&
                    idx === item.cartList.length - 1 &&
                    cycleCustom[0].product.isCouples === '2'
                "
                >最少定单{{ res.leastQuantity }}件</span
              ><span
                class="tips"
                v-if="
                  cycleCustom[0].spotFlag == 2 &&
                    cycleCustom[0].product.isCouples === '1' &&
                    res.gender === '女' &&
                    cycleCustom[0].totalFemaleQuantityFlag === true
                "
                >情侣款女款最少定单{{ res.leastQuantity }}件</span
              ><span
                class="tips"
                v-if="
                  cycleCustom[0].spotFlag == 2 &&
                    cycleCustom[0].product.isCouples === '1' &&
                    res.gender === '男' &&
                    cycleCustom[0].totalMaleQuantityFlag === true
                "
                >情侣款男款最少定单{{ res.leastQuantity }}件</span
              ></span
            >
            <span class="cartStone"
              ><span v-if="res.labelPrice && !res.enjoyLabelPrice">{{ res.labelPrice }}</span></span
            >
            <span class="cartStone" v-if="!hasPermission('展厅') || !hasPermission('加盟公司总经理')"
              ><span>{{ res.tradePrice }}</span></span
            >
            <span class="cartStone"
              ><span v-if="res.enjoyLabelPrice">{{ res.enjoyLabelPrice }}</span></span
            >
            <div class="controls-wrap">
              <div class="modify" @click="updateCart(res)">修改</div>
              <div class="deleteCustom" @click="deleteCart(res)">删除</div>
            </div>
            <!-- <div class="diamondSpotBtn" v-if="cycleCustom[0].spotFlag == 1 && res.quantity > 1" @click="spotList(res)">现货列表</div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="statusImg">
      <svg-icon v-if="cycleCustom[0].spotFlag == 2 && cycleCustom[0].isPresell !== '1'" iconClass="custom-diamond" className="custom-gold"></svg-icon>
      <svg-icon v-if="cycleCustom[0].spotFlag == 1 && cycleCustom[0].isPresell !== '1'" iconClass="spot-diamond" className="custom-gold"></svg-icon>
      <svg-icon v-if="cycleCustom[0].isPresell === '1'" iconClass="pre-sale-shopCart" className="pre-sale-shopCart"></svg-icon>
    </div>
    <!-- 修改复制弹窗 -->
    <UpdateDiamond
      v-on="$listeners"
      v-if="diamondSelectDialogVisible"
      :diamondSelectDialogVisible="diamondSelectDialogVisible"
      :modifyingReplication="modifyingReplication"
      @onCloseSelectDialog="onCloseSelectDialog"
      :product="cycleCustom[0].product"
    />
    <!-- 删除确认弹窗 -->
    <ConfirmDeletion
      v-if="deletingPopoversVisible"
      :deletingPopoversVisible="deletingPopoversVisible"
      @onCloseSelectDialog="onCloseSelectDialog"
      @confirmDel="confirmDel"
    />
    <!-- 钻石现货弹窗 -->
    <DiamondSpot
      v-on="$listeners"
      v-if="diamondSpotDialogVisible"
      :diamondSpotDialogVisible="diamondSpotDialogVisible"
      :addOrderList="addOrderList"
      @onCloseSelectDialog="onCloseSelectDialog"
      :modifyingReplication="modifyingReplication"
    />
  </div>
</template>
<script>
import { cutFilter } from '@/utils/filetrs';
import UpdateDiamond from '../updata/UpdateDiamond.vue';
import ConfirmDeletion from '../updata/ConfirmDeletion.vue';
import DiamondSpot from '../spot/DiamondSpot.vue';
import { modifyCartPublic } from '@/api/cartApi';
export default {
  name: 'CartDiamondCustom',
  props: {
    cycleCustom: {
      type: Array,
      default() {
        return [];
      },
    },
    addOrderList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      modifyingReplication: {},
      diamondSelectDialogVisible: false,
      diamondSpotDialogVisible: false,
      deletingPopoversVisible: false,
      flag: false,
      isSoldOut: false, //是否已售
      isDelist: false, //是否下架
    };
  },
  components: {
    UpdateDiamond,
    ConfirmDeletion,
    DiamondSpot,
  },
  filters: {
    cutFilter,
  },
  watch: {
    cycleCustom: {
      //深度监听，可监听到对象、数组的变化
      handler(newV, oldV) {
        this.arrayChange();
        this.totalQuantity();
        this.cycleCustom.forEach((res) => {
          res.cartList?.forEach((item) => {
            // item.spotFlag = false;
            // if (item.quantity > 1) {
            //   item.spotFlag = true;
            // }
          });
          this.flag = res.cartList.every((val) => val.switchFlag == true);
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.arrayChange();
      this.totalQuantity();
      // 循环未截取的数组找到缺货下架的数据
      this.addOrderList.forEach((res) => {
        if (this.cycleCustom[0]?.id === res[0].id) {
          this.isSoldOut = res[0].cartList.some((ele) => ele.cartStatus === '已售');
          this.isDelist = res[0].cartList.some((ele) => ele.cartStatus === '下架');
        }
      });
    });
  },
  methods: {
    // 处理数据
    totalQuantity() {
      let totalQuantity = 0;
      let totalMaleQuantity = 0;
      let totalFemaleQuantity = 0;
      this.cycleCustom?.forEach((res) => {
        res.totalQuantityFlag = false;
        res.totalMaleQuantityFlag = false;
        res.totalFemaleQuantityFlag = false;
        res.cartList?.forEach((ele) => {
          // ele.spotFlag = false;
          // if (ele.quantity > 1) {
          //   ele.spotFlag = true;
          // }
          if (res.product.isCouples === '2') {
            totalQuantity = Number(totalQuantity) + Number(ele.quantity);
          } else if (res.product.isCouples === '1' && ele.gender === '女') {
            totalFemaleQuantity = Number(totalFemaleQuantity) + Number(ele.quantity);
          } else if (res.product.isCouples === '1' && ele.gender === '男') {
            totalMaleQuantity = Number(totalMaleQuantity) + Number(ele.quantity);
          }
          if ((totalQuantity >= ele.leastQuantity && !ele.gender) || ele.cartStatus !== '现货') {
            res.totalQuantityFlag = false;
          } else if (!ele.gender) {
            res.totalQuantityFlag = true;
          }
          if ((totalMaleQuantity >= ele.leastQuantity && ele.gender === '男') || ele.cartStatus !== '现货') {
            res.totalMaleQuantityFlag = false;
          } else if (ele.gender === '男') {
            res.totalMaleQuantityFlag = true;
          }
          if ((totalFemaleQuantity >= ele.leastQuantity && ele.gender === '女') || ele.cartStatus !== '现货') {
            res.totalFemaleQuantityFlag = false;
          } else if (ele.gender === '女') {
            res.totalFemaleQuantityFlag = true;
          }
          this.$forceUpdate();
        });
      });
    },
    arrayChange() {
      let list = [];
      const isEven = (num) => num % 2 === 0;
      if (this.cycleCustom[0]?.spotFlag == 1 && this.cycleCustom[0]?.product.isCouples === '1') {
        this.cycleCustom[0]?.cartList.forEach((res) => {
          this.cycleCustom[0]?.cartList.forEach((item) => {
            if (res.texture === item.texture && res.gender !== item.gender && res.quantity !== item.quantity) {
              res.materialFlag = true;
            }
          });
        });
      }
      if (isEven(this.cycleCustom[0]?.cartList.length) === false) {
        this.cycleCustom[0]?.cartList.forEach((res) => {
          list.push(res.texture);
        });
        let texture = this.FindNumsAppearOnce(list);
        this.cycleCustom[0]?.cartList.forEach((res) => {
          if (res.texture === texture) {
            res.materialFlag = true;
          }
        });
      } else if (this.cycleCustom[0]?.cartList.length === 2) {
        this.cycleCustom[0]?.cartList.forEach((res) => {
          this.cycleCustom[0]?.cartList.forEach((item) => {
            if (res.texture !== item.texture) {
              res.materialFlag = true;
            }
          });
        });
      }
    },
    FindNumsAppearOnce(array) {
      // return list, 比如[a,b]，其中ab是出现一次的两个数字
      var res = [];
      for (let i = 0; i < array.length; i++) {
        if (array.indexOf(array[i]) === array.lastIndexOf(array[i])) {
          res.push(array[i]);
        }
      }
      return res[0];
    },
    // 跳转详情
    goDetaild(item) {
      this.$router.push({
        path: '/order',
        query: {
          id: item.product.id,
          styleNumber: item.product.styleNumber,
          generaName: item.product.generaName,
          styleFlag: item.spotFlag == 1 ? '钻石现货' : '钻石定单',
          codeType: item.cartList[0]?.columnCode,
          classify: item.product.classify,
        },
      });
    },
    // 关闭弹窗
    onCloseSelectDialog() {
      this.diamondSelectDialogVisible = false;
      this.deletingPopoversVisible = false;
      this.diamondSpotDialogVisible = false;
    },
    // 现货列表
    spotList(res) {
      this.diamondSpotDialogVisible = true;
      this.modifyingReplication = res;
    },
    // 修改购物车
    updateCart(res) {
      this.diamondSelectDialogVisible = true;
      this.modifyingReplication = res;
    },
    // 删除购物车数据
    deleteCart(res) {
      this.modifyingReplication = res;
      this.deletingPopoversVisible = true;
    },
    // 确认删除
    confirmDel() {
      let obj = [
        {
          id: this.modifyingReplication.id,
          sequences: this.modifyingReplication.sequenceList,
        },
      ];
      modifyCartPublic(obj, 'delete').then((res) => {
        if (res.code === 0) {
          this.$message({
            message: '删除成功',
            type: 'success',
            duration: 1500,
          });
          this.deletingPopoversVisible = false;
          this.$emit('updataCart');
        }
      });
    },
    //外层开关
    onChange(value) {
      // spotFlag 1是现货 2是定制 isCouples是情侣款 2是单独款
      if (value === true) {
        this.cycleCustom.forEach((res) => {
          res.cartList.forEach((item) => {
            if (item.spotFlag === 1 && item.cartStatus === '现货') {
              item.switchFlag = true;
            } else if (item.spotFlag === 2 && item.cartStatus === '现货') {
              if (this.cycleCustom[0].product.isCouples === '2' && this.cycleCustom[0].totalQuantityFlag === false && item.cartStatus === '现货') {
                item.switchFlag = true;
              } else if (
                this.cycleCustom[0].product.isCouples === '1' &&
                item.gender === '女' &&
                this.cycleCustom[0].totalFemaleQuantityFlag === false &&
                item.cartStatus === '现货'
              ) {
                item.switchFlag = true;
              } else if (
                this.cycleCustom[0].product.isCouples === '1' &&
                item.gender === '男' &&
                this.cycleCustom[0].totalMaleQuantityFlag === false &&
                item.cartStatus === '现货'
              ) {
                item.switchFlag = true;
              } else {
                item.switchFlag = false;
              }
            } else {
              item.switchFlag = false;
            }
          });
        });
      } else {
        this.cycleCustom.forEach((res) => {
          res.cartList.forEach((item) => {
            item.switchFlag = false;
          });
        });
      }
      this.cycleCustom.forEach((res) => {
        this.flag = res.cartList.every((val) => val.switchFlag == true);
      });
      this.$forceUpdate();
    },
    //内层开关
    innerLayer(item) {
      this.cycleCustom.flag = item.cartList.every((val) => val.switchFlag == true);
      this.flag = item.cartList.every((val) => val.switchFlag == true);
    },
  },
};
</script>
<style lang="scss" scoped>
.cartDiamondCustom {
  border: 1px solid #ede2ce;
  margin-top: 12px;
  font-size: 14px;
  position: relative;

  .statusImg {
    position: absolute;
    right: 0;
    width: 69px;
    height: 20px;
    top: 0;
    z-index: 0;

    .custom-gold {
      width: 73px;
      height: 20px;
    }

    .pre-sale-shopCart {
      width: 72px;
      height: 24px;
    }
  }

  .cartDiamondCustomList {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;

    .outerLayer {
      margin: 10px 7px 0 10px;

      /deep/ .el-checkbox__inner {
        width: 16px;
        height: 16px;
      }

      /deep/ .el-checkbox__inner:after {
        height: 9px;
        left: 5px;
        top: 0;
        width: 4px;
      }
    }

    /deep/ .el-checkbox__inner:hover {
      border-color: #ec9368;
    }

    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #ec9368;
      border-color: #ec9368;
    }

    .cartImg {
      min-width: 70px;
      max-width: 70px;
      display: flex;
      align-items: center;
      position: relative;

      /deep/ .el-image__inner {
        cursor: pointer;
      }
    }

    .cartDiamondCustomParameter {
      text-align: center;
      margin-left: 8px;

      .cartDiamondCustomHead {
        display: flex;
        align-items: center;
        margin: 15px 0 0 20px;
      }

      .dataContentFather:last-child .dataContent {
        border-bottom: none;
      }

      .dataContent {
        display: flex;
        align-items: center;
        color: #ec9368;
        border-bottom: 1px solid rgb(220, 223, 230);
        padding: 18px 0px;
        height: 34px;
        min-width: 1364px;
        position: relative;
        .innerLayerSwitch {
          width: 20px;
        }
        .controls-wrap {
          display: flex;
          position: absolute;
          right: 0;
        }
      }

      .curtBg {
        background: rgb(255, 247, 242);
      }

      .seriesModel {
        width: 110px;
        white-space: pre-wrap;
        word-wrap: break-word;
      }

      .cartTexture {
        width: 67px;
        position: relative;

        .tips {
          color: rgb(255, 0, 0);
          position: absolute;
          left: 50%;
          top: 29px;
          transform: translate(-50%, 0);
          width: 150px;
          font-size: 12px;
        }
      }

      .cartCategory {
        width: 69px;

        .el-button {
          width: 100%;
          border: none;
          padding: 0;
          color: #ec9368;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .el-button:focus,
        .el-button:hover {
          background-color: transparent;
        }
      }

      .cartSize {
        width: 60px;
      }

      .reminder {
        position: relative;

        .tips {
          color: rgb(255, 0, 0);
          position: absolute;
          left: 50%;
          top: 20px;
          transform: translate(-50%, 0);
          width: 150px;
          font-size: 12px;
        }
      }

      .cartMainTtoneWeight {
        width: 50px;
      }

      .cartStone {
        width: 99px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          height: 16px;
        }

        .el-popover__reference {
          font-size: 12px;
        }
      }

      .modify {
        color: #00baff;
        cursor: pointer;
        min-width: 28px;
      }

      .deleteCustom {
        color: #ff0000;
        margin-left: 16px;
        cursor: pointer;
        min-width: 28px;
      }

      .diamondSpotBtn {
        width: 70px;
        text-align: center;
        height: 32px;
        line-height: 32px;
        border: 1px solid #ec9368;
        border-radius: 5px;
        cursor: pointer;
      }

      .question-mark {
        cursor: pointer;
        width: 16px;
        height: 16px;
        margin-left: 3px;
      }

      .cartCut {
        width: 85px;
      }

      .cartFluorescence {
        width: 70px;
      }

      .cartGoldWeigh {
        width: 85px;
      }
    }
  }

  .cartDiamondBg {
    background: rgb(255, 247, 242);
  }
}
</style>
