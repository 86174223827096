import request from '@/utils/request';
import { isPhoneNumber } from '@/utils/index';
import qs from 'qs';

// 登录
export function login(data) {
  return request({
    url: '/doLogin?userName=' + data.username + '&passWord=' + data.password + '&captcha=' + data.code,
    method: 'POST',
    params: {},
  });
}
export const loginByUsername = (username, password, device_type, code, randomStr) => {
  let grant_type = 'password';
  let dataObj = qs.stringify({
    username: username,
    password: password,
    device_type,
  });
  return request({
    // baseURL: process.env.VUE_APP_WT_API, //登录接口单独的端口
    url: '/auth/oauth/token',
    headers: {
      isToken: false,
      'TENANT-ID': '1',
      Authorization: isPhoneNumber(username) === true ? 'Basic YWRtaW46YWRtaW4=' : 'Basic cGlnOnBpZw==',
    },
    method: 'post',
    params: { randomStr, code, grant_type },
    data: dataObj,
  });
};
// 获取权限
export const getRole = () => {
  return request({
    url: '/exhibition/role',
    method: 'get',
  });
};
// 判断是年轻空间还是周六福
export const getType = () => {
  return request({
    url: '/exhibition/dic/type/exhibition_tenant_id',
    method: 'get',
  });
};
// 云平台跳转过来获取用户信息
export const getHallInfo = () => {
  return request({
    url: '/exhibition/user',
    method: 'get',
  });
};
// 退出登录
export const logout = () => {
  return request({
    // baseURL: process.env.VUE_APP_WT_API, //登录接口单独的端口
    url: '/auth/token/logout',
    method: 'delete',
  });
};

// pos登录页接口
export function posLogin(data) {
  return request({
    url: '/posLogin?token=' + data,
    method: 'POST',
    params: {},
  });
}
export const getUserInfo = () => {
  return request({
    url: '/admin/user/info',
    method: 'get',
  });
};
