<template>
  <div id="app">
    <keep-alive include="Home">
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive && isReloadAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload,
    };
  },
  data: function() {
    return {
      isReloadAlive: true,
    };
  },
  methods: {
    reload() {
      this.isReloadAlive = false;
      this.$nextTick(function() {
        this.isReloadAlive = true;
      });
    },
  },
  created() {},
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}
html,
body {
  width: 100%;
}
#app {
  width: 100%;
}
</style>
