import { Decimal } from "decimal.js";
import * as CryptoJS from "crypto-js";

// 表单序列化
export const serialize = data => {
  const list = [];
  Object.keys(data).forEach(ele => {
    list.push(`${ele}=${data[ele]}`);
  });
  return list.join("&");
};
export const getObjType = obj => {
  var toString = Object.prototype.toString;
  var map = {
    "[object Boolean]": "boolean",
    "[object Number]": "number",
    "[object String]": "string",
    "[object Function]": "function",
    "[object Array]": "array",
    "[object Date]": "date",
    "[object RegExp]": "regExp",
    "[object Undefined]": "undefined",
    "[object Null]": "null",
    "[object Object]": "object",
  };
  if (obj instanceof Element) {
    return "element";
  }
  return map[toString.call(obj)];
};

/**
 * 对象深拷贝
 */
export const deepClone = data => {
  var type = getObjType(data);
  var obj;
  if (type === "array") {
    obj = [];
  } else if (type === "object") {
    obj = {};
  } else {
    // 不再具有下一层次
    return data;
  }
  if (type === "array") {
    for (var i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]));
    }
  } else if (type === "object") {
    for (var key in data) {
      obj[key] = deepClone(data[key]);
    }
  }
  return obj;
};

/**
 * 判断路由是否相等
 */
export const diff = (obj1, obj2) => {
  delete obj1.close;
  var o1 = obj1 instanceof Object;
  var o2 = obj2 instanceof Object;
  if (!o1 || !o2) {
    /*  判断不是对象  */
    return obj1 === obj2;
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
    // Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
  }

  for (var attr in obj1) {
    var t1 = obj1[attr] instanceof Object;
    var t2 = obj2[attr] instanceof Object;
    if (t1 && t2) {
      return diff(obj1[attr], obj2[attr]);
    } else if (obj1[attr] !== obj2[attr]) {
      return false;
    }
  }
  return true;
};

/**
 * 设置灰度模式
 */
export const toggleGrayMode = status => {
  if (status) {
    document.body.className = document.body.className + " grayMode";
  } else {
    document.body.className = document.body.className.replace(" grayMode", "");
  }
};

/**
 * 设置主题
 */
export const setTheme = name => {
  document.body.className = name;
};

/**
 *加密处理
 */
export const encryption = params => {
  let { data, type, param, key } = params;
  const result = JSON.parse(JSON.stringify(data));
  if (type === "Base64") {
    param.forEach(ele => {
      result[ele] = btoa(result[ele]);
    });
  } else {
    param.forEach(ele => {
      var data = result[ele];
      key = CryptoJS.enc.Latin1.parse(key);
      var iv = key;
      // 加密
      var encrypted = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding,
      });
      result[ele] = encrypted.toString();
    });
  }
  return result;
};

/**
 * 解密处理
 */
export const decryption = params => {
  let { data, type, param, key } = params;
  const result = JSON.parse(JSON.stringify(data));
  if (type === "Base64") {
    param.forEach(ele => {
      result[ele] = atob(result[ele]);
    });
  } else {
    param.forEach(ele => {
      var data = result[ele];
      key = CryptoJS.enc.Latin1.parse(key);
      var iv = key;
      // 解密
      var decrypted = CryptoJS.AES.decrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.ZeroPadding,
      });
      result[ele] = decrypted.toString(CryptoJS.enc.Utf8);
    });
  }
  return result;
};

/**
 * 浏览器判断是否全屏
 */
export const fullscreenToggel = () => {
  if (fullscreenEnable()) {
    exitFullScreen();
  } else {
    reqFullScreen();
  }
};

/**
 * esc监听全屏
 */
export const listenfullscreen = callback => {
  function listen() {
    callback();
  }

  document.addEventListener("fullscreenchange", function() {
    listen();
  });
  document.addEventListener("mozfullscreenchange", function() {
    listen();
  });
  document.addEventListener("webkitfullscreenchange", function() {
    listen();
  });
  document.addEventListener("msfullscreenchange", function() {
    listen();
  });
};

/**
 * 浏览器判断是否全屏
 */
export const fullscreenEnable = () => {
  return document.isFullScreen || document.mozIsFullScreen || document.webkitIsFullScreen;
};

/**
 * 递归寻找子类的父类
 */
export const findParent = (menu, id) => {
  for (let i = 0; i < menu.length; i++) {
    if (menu[i].children.length !== 0) {
      for (let j = 0; j < menu[i].children.length; j++) {
        if (menu[i].children[j].id === id) {
          return menu[i];
        } else {
          if (menu[i].children[j].children.length !== 0) {
            return findParent(menu[i].children[j].children, id);
          }
        }
      }
    }
  }
};

/**
 * 动态插入css
 */
export const loadStyle = url => {
  const link = document.createElement("link");
  link.type = "text/css";
  link.rel = "stylesheet";
  link.href = url;
  const head = document.getElementsByTagName("head")[0];
  head.appendChild(link);
};

/**
 * 判断路由是否相等
 */
export const isObjectValueEqual = (a, b) => {
  let result = true;
  Object.keys(a).forEach(ele => {
    const type = typeof a[ele];
    if (type === "string" && a[ele] !== b[ele]) result = false;
    else if (type === "object" && JSON.stringify(a[ele]) !== JSON.stringify(b[ele])) result = false;
  });
  return result;
};
/**
 * 数组降序
 */
export const sortDesByKey = (ary, key) => {
  let sum = ary.sort((a, b) => {
    let x = a[key];
    let y = b[key];
    return +x - +y;
  });
  return sum;
};
/**
 * 数组升序
 */
export const sortDosByKey = (ary, key) => {
  return ary.sort((a, b) => {
    let x = b[key];
    let y = a[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

/**
 * 根据字典的value查找对应的index
 */
export const findArray = (dic, value) => {
  for (let i = 0; i < dic.length; i++) {
    if (dic[i].value === value) {
      return i;
    }
  }
  return -1;
};
/**
 *
 * 数组中是否存在相等
 */
export const isRepeat = array => {
  var hash = {};
  for (var i in array) {
    if (array[i] != "") {
      if (hash[array[i]]) return true;
      hash[array[i]] = true;
    }
  }
  return false;
};
/**
 * 除去为""项
 */
export const trimSpace = array => {
  for (var i = 0; i < array.length; i++) {
    if (array[i] == "" || typeof array[i] == "undefined") {
      array.splice(i, 1);
      i = i - 1;
    }
  }
  return array;
};

/**
 * 生成随机len位数字
 */
export const randomLenNum = (len, date) => {
  let random = "";
  random = Math.ceil(Math.random() * 100000000000000)
    .toString()
    .substr(0, len || 4);
  if (date) random = random + Date.now();
  return random;
};
/**
 * 打开小窗口
 */
export const openWindow = (url, title, w, h) => {
  // Fixes dual-screen position                            Most browsers       Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const left = width / 2 - w / 2 + dualScreenLeft;
  const top = height / 2 - h / 2 + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      top +
      ", left=" +
      left
  );

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus();
  }
};

export function getQueryString(url, paraName) {
  const arrObj = url.split("?");
  if (arrObj.length > 1) {
    const arrPara = arrObj[1].split("&");
    let arr;
    for (let i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split("=");
      // eslint-disable-next-line
      if (arr != null && arr[0] == paraName) {
        return arr[1];
      }
    }
    return "";
  } else {
    return "";
  }
}

/**
 * 节流
 * @param  {[function]} func  [执行的函数]
 * @param  {[number]} delay [多少秒之内执行一次]
 */
export function throttle(func, delay) {
  let prev = Date.now();
  return function() {
    const context = this;
    const args = arguments;
    const now = Date.now();
    if (now - prev >= delay) {
      func.apply(context, args);
      prev = Date.now();
    }
  };
}

/**
 * 防抖
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function(...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}

/**
 * 强制保留两位小数
 * @param {number} num 原始数字
 * @param {number} precision 保留的小数位数 precision为0需特别处理
 */
export function round2Fixed(num, precision) {
  if (!precision && precision !== 0) {
    precision = precision || 2;
  }
  if (!num) {
    //在这里进行一次传递数据判断.如果传递进来的为空值,返回其空字符串
    return "";
  }
  const decimal = new Decimal(num);
  num = decimal.toFixed(precision);
  return num;
  // let str = num + ''
  // const len = str.length
  // let last = str.substring(len - 1, len)
  // if (last == '5') {
  //   last = '6'
  //   str = str.substring(0, len - 1) + last
  //   return Number(str - 0).toFixed(precision)
  // } else {
  //   return Number(num).toFixed(precision)
  // }
}
/**
 * 截取两位小数
 * @param {number} num 原始数字
 * @param {number} precision 保留的小数位数
 */
export function roundFixed2(num, precision) {
  num = num.toString();
  if (!precision && precision !== 0) {
    precision = precision || 2;
  }
  let index = num.indexOf(".");
  if (index !== -1) {
    num = num.substring(0, precision + index + 1);
  } else {
    num = num.substring(0);
  }
  return parseFloat(num).toFixed(precision);
}
/**
 * 根据条码判断是新pos迁移还是新平台数据
 * @param {String} retailOrderNo 原始条码
 * @param {String} siteCode 网点代码
 */
export function isNewYun(retailOrderNo, siteCode) {
  if (retailOrderNo.length == 0) {
    return true;
  }
  var start = retailOrderNo.indexOf(siteCode); //获得字符串的开始位置
  var end = start + siteCode.length + 6; //截取字符串结束的位置
  let isleng = retailOrderNo.length - end;
  return isleng == 6 ? true : false;
}
/**
 * 数组对象中求和
 * @param {Array} arr 计算求和的数组
 * @param  {String} key 数组对象的key
 * @param {number} precision 保留的小数位数
 * eg：res = getSum('age', 'price', arr)
 */
export function calcSum(arr, key, precision) {
  let sum = 0;
  if (!Array.isArray(arr)) {
    throw new Error("请传入数组对象");
  }
  if (!arr.length) {
    return 0;
  }
  arr.forEach(item => {
    sum += Number(item[key]);
  });
  return round2Fixed(sum, precision);
}
/**
 * 占比值
 * @param {Array} arr 计算求和的数组
 * @param  {String} key 数组对象的key
 * @param {number} value 分子比值
 * @param {number} precision 保留的小数位数 value
 */
export function ratioSum(arr, key, value, precision) {
  let sum = 0;
  sum = value / calcSum(arr, key, precision);
  return sum;
}
/**
 * 调用浏览器的打印功能
 * @param  {String} codeHtml html字符串
 */
export function iframe2Print(codeHtml) {
  const newWindow = window.open("", "_blank", "location=1, left=0, top=0, width=980,height=750"); // 打开新窗口,第三个参数一定要配置，才是打开新窗口
  newWindow.document.write(
    "<head>" + document.getElementsByTagName("head")[0].innerHTML + "</head>"
  ); // 向文档写入HTML表达式或者JavaScript代码
  newWindow.document.write('<body onload="window.print()">' + codeHtml + "</body>"); // 向文档写入HTML表达式或者JavaScript代码
  newWindow.document.close(); // 关闭document的输出流, 显示选定的数据
  // newWindow.print(); // 打印当前窗口
  // return true
}

/**
 * 打开新窗口
 * @param  {String} codeHtml html字符串
 */
export function iframe2NewWindow(codeHtml) {
  const newWindow = window.open(
    "",
    "_blank",
    `location=1, left=0, top=0, width=${window.screen.width},height=${window.screen.height}`
  );
  const head = "<head>" + document.getElementsByTagName("head")[0].innerHTML + "</head>";
  const body = "<body>" + codeHtml + "</body>";
  newWindow.document.write(head);
  newWindow.document.write(body);
  newWindow.document.close();
}

/**
 * HTML 实体与字符的相互转换
 * @param {*} type escape 将HTML转义为实体; unescape 将实体转回为HTML
 * @param {*} str
 * @returns
 */
export function transferEscape(type, str) {
  const entityMap = {
    escape: {
      "&": "&amp;",
      "<": "&lt;",
      ">": "&gt;",
      '"': "&quot;",
      "'": "&apos;",
    },
    unescape: {
      "&amp;": "&",
      "&apos;": "'",
      "&gt;": ">",
      "&lt;": "<",
      "&quot;": '"',
    },
  };
  const entityReg = {
    escape: RegExp("[" + Object.keys(entityMap.escape).join("") + "]", "g"),
    unescape: RegExp("(" + Object.keys(entityMap.unescape).join("|") + ")", "g"),
  };

  if (!type) {
    throw new Error("请传入需要转移的类型");
  }
  if (type === "escape") {
    // 将HTML转义为实体
    if (typeof str !== "string") return "";
    return str.replace(entityReg.escape, function(match) {
      return entityMap.escape[match];
    });
  } else if (type === "unescape") {
    // 将实体转回为HTML
    if (typeof str !== "string") return "";
    return str.replace(entityReg.unescape, function(match) {
      return entityMap.unescape[match];
    });
  }
}
