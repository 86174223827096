<template>
  <div class="loverSortBox">
    <div class="spotTabs">
      <el-tabs v-model="spotActive" type="card" @tab-click="handleClick">
        <el-tab-pane label="女款" name="first"> </el-tab-pane>
        <el-tab-pane label="男款" name="second"> </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 女款现货列表 -->
    <div class="custom" v-if="spotActive == 'first'">
      <!-- 材质 -->
      <div class="fx" v-if="dropDownOptional.handTextureList && dropDownOptional.handTextureList.length > 0">
        <div class="title">材质</div>
        <div class="material selectBox">
          <el-radio-group v-model="dataFrom.texture" size="small" @change="getSizeRange($event, 'texture', 'texture', '女')">
            <span
              class="inchesFix"
              :class="[flagArray.textureFlag == true ? 'stockBg' : '']"
              v-for="item in dropDownOptional.handTextureList"
              :key="item"
            >
              <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
              <div class="outStore" v-if="dataFrom.texture == item && flagArray.textureFlag == true">缺货</div>
            </span>
          </el-radio-group>
        </div>
      </div>
      <!-- 辅石 -->
      <div class="fx" v-if="dropDownOptional.gemNameList && dropDownOptional.gemNameList.length > 0">
        <div class="title">辅石</div>
        <div class="material selectBox">
          <el-radio-group v-model="dataFrom.gemName" size="small" @change="getSizeRange($event, 'gemName', 'assistStoneNameOne', '女')">
            <span
              class="inchesFix"
              :class="[flagArray.gemNameFlag == true ? 'stockBg' : '']"
              v-for="item in dropDownOptional.gemNameList"
              :key="item"
            >
              <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
              <div class="outStore" v-if="dataFrom.gemName == item && flagArray.gemNameFlag == true">缺货</div>
            </span>
          </el-radio-group>
        </div>
      </div>
      <!-- 版型 -->
      <div class="fx" v-if="dropDownOptional.formatTypes && dropDownOptional.formatTypes.length > 0">
        <div class="title">版型</div>
        <div class="material selectBox">
          <el-radio-group v-model="dataFrom.formatType" size="small" @change="getSizeRange($event, 'formatType', 'formatType', '女')">
            <span
              class="inchesFix"
              :class="[flagArray.formatTypeFlag == true ? 'stockBg' : '']"
              v-for="item in dropDownOptional.formatTypes"
              :key="item"
            >
              <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
              <div class="outStore" v-if="dataFrom.formatType == item && flagArray.formatTypeFlag == true">缺货</div>
            </span>
          </el-radio-group>
        </div>
      </div>
      <!-- 主石重量 -->
      <div class="fx" v-if="dropDownOptional.mainStoneList && dropDownOptional.mainStoneList.length > 0">
        <div class="title">主石重量</div>
        <div class="material selectBox">
          <el-radio-group v-model="dataFrom.mainStone" size="small" @change="getSizeRange($event, 'mainStone', 'mainStone', '女')">
            <span
              class="inchesFix"
              :class="[flagArray.mainStoneFlag == true ? 'stockBg' : '']"
              v-for="item in dropDownOptional.mainStoneList"
              :key="item"
            >
              <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
              <div class="outStore" v-if="dataFrom.mainStone == item && flagArray.mainStoneFlag == true">缺货</div>
            </span>
          </el-radio-group>
        </div>
      </div>
      <!-- 净度 -->
      <div class="fx" v-if="dropDownOptional.mainStoneClarity && dropDownOptional.mainStoneClarity.length > 0">
        <div class="title">净度</div>
        <div class="material selectBox">
          <el-radio-group
            v-model="dataFrom.mainStoneClarity"
            size="small"
            @change="getSizeRange($event, 'mainStoneClarity', 'mainStoneClarity', '女')"
          >
            <span
              class="inchesFix"
              :class="[flagArray.clarityFlag == true ? 'stockBg' : '']"
              v-for="item in dropDownOptional.mainStoneClarity"
              :key="item"
            >
              <el-radio-button :value="item" :label="item">{{ item | clarityColor }}</el-radio-button>
              <div class="outStore" v-if="dataFrom.mainStoneClarity == item && flagArray.clarityFlag == true">缺货</div>
            </span>
          </el-radio-group>
        </div>
      </div>
      <!-- 颜色 -->
      <div class="fx" v-if="dropDownOptional.mainStoneColour && dropDownOptional.mainStoneColour.length > 0">
        <div class="title">颜色</div>
        <div class="material selectBox">
          <el-radio-group v-model="dataFrom.mainStoneColour" size="small" @change="getSizeRange($event, 'mainStoneColour', 'mainStoneColor', '女')">
            <span
              class="inchesFix"
              :class="[flagArray.colourFlag == true ? 'stockBg' : '']"
              v-for="item in dropDownOptional.mainStoneColour"
              :key="item"
            >
              <el-radio-button :value="item" :label="item">{{ item | clarityColor }}</el-radio-button>
              <div class="outStore" v-if="dataFrom.mainStoneColour == item && flagArray.colourFlag == true">缺货</div>
            </span>
          </el-radio-group>
        </div>
      </div>
      <!-- 规格 -->
      <div class="fx" v-if="dropDownOptional.handSizeList && dropDownOptional.handSizeList.length > 0">
        <div class="title">
          规格<span class="inchesTips" v-if="dropDownOptional.handSizeList && dropDownOptional.handSizeList[0].includes('寸')"
            >(1英寸＝2.54厘米)</span
          >
        </div>
        <div class="material selectBox">
          <el-radio-group v-model="dataFrom.ringSize" size="small" @change="getSizeRange($event, 'ringSize', 'spec', '女')">
            <span class="inchesFix" :class="[flagArray.sizeFlag == true ? 'stockBg' : '']" v-for="item in dropDownOptional.handSizeList" :key="item">
              <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
              <div class="outStore" v-if="dataFrom.ringSize == item && flagArray.sizeFlag == true">缺货</div>
            </span>
          </el-radio-group>
        </div>
      </div>
      <!-- gia -->
      <div class="fx" v-if="dropDownOptional.gia && dropDownOptional.gia.length > 0">
        <div class="title">GIA</div>
        <div class="material selectBox">
          <el-radio-group v-model="dataFrom.gia" size="small" @change="getSizeRange($event, 'gia', 'gia', '女')">
            <span class="inchesFix" :class="[flagArray.giaFlag == true ? 'stockBg' : '']" v-for="item in dropDownOptional.gia" :key="item">
              <el-radio-button :value="item" :label="item">{{ item | cutFilter }}</el-radio-button>
              <div class="outStore" v-if="dataFrom.gia == item && flagArray.giaFlag == true">缺货</div>
            </span>
          </el-radio-group>
        </div>
      </div>
      <!-- 备注 -->
      <div class="textareaRemark">
        <div class="title">备注</div>
        <el-input
          :rows="6"
          :maxlength="64"
          type="textarea"
          placeholder="其他要求与说明请在此处备注说明,字符数限制64位"
          v-model="dataFrom.productRemark"
        ></el-input>
      </div>
      <div class="fl" style="justify-content: end;">
        <div
          style="color:red;margin-right:20px"
          v-if="
            (dataFrom.tradePrice && (!hasPermission('展厅') || !hasPermission('加盟公司总经理'))) ||
              (dataFrom.labelPrice && (!dataFrom.exclusivePrice || dataFrom.exclusivePrice == 0)) ||
              (dataFrom.exclusivePrice && dataFrom.exclusivePrice != 0)
          "
        >
          (预估价格，实际价格请以结算为准)
        </div>
        <div>
          <!-- 批发价格 -->
          <div class="box_detail_input marginFx" v-if="dataFrom.tradePrice && (!hasPermission('展厅') || !hasPermission('加盟公司总经理'))">
            <div class="input-wrap">批发价格：{{ dataFrom.tradePrice }}</div>
          </div>
          <!-- 标签价格 -->
          <div class="box_detail_input marginFx" v-if="dataFrom.labelPrice && (!dataFrom.exclusivePrice || dataFrom.exclusivePrice == 0)">
            <div class="input-wrap">标签价格：{{ dataFrom.labelPrice }}</div>
          </div>
          <!-- 尊享价 -->
          <div class="box_detail_input marginFx" v-if="dataFrom.exclusivePrice && dataFrom.exclusivePrice != 0">
            <div class="input-wrap">尊享价：{{ dataFrom.exclusivePrice }}</div>
          </div>
        </div>
      </div>
      <!-- 下单件数 -->
      <div class="box_detail_input marginFx">
        <div class="input-wrap">
          <span class="prompt">库存{{ dropDownOptional.stockQuantity || 0 }}件，可选择不同规格</span>
          <span class="prompt placeNum">购买数量：</span>
          <el-input-number
            v-model="dataFrom.quantity"
            :min="0"
            :max="stockQuantity"
            @change="getSizeRange($event, 'quantity', '女')"
            label="描述文字"
          ></el-input-number>
        </div>
      </div>
    </div>
    <!-- 男款 -->
    <div class="custom" v-if="spotActive == 'second'">
      <!-- 材质 -->
      <div class="fx" v-if="dropDownOptional.maleHandTextureList && dropDownOptional.maleHandTextureList.length > 0">
        <div class="title">材质</div>
        <div class="material selectBox">
          <el-radio-group v-model="maleDataFrom.texture" size="small" @change="getSizeRange($event, 'texture', 'texture', '男')">
            <span
              class="inchesFix"
              :class="[flagArray.maleTextureFlag == true ? 'stockBg' : '']"
              v-for="item in dropDownOptional.maleHandTextureList"
              :key="item"
            >
              <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
              <div class="outStore" v-if="maleDataFrom.texture == item && flagArray.maleTextureFlag == true">缺货</div>
            </span>
          </el-radio-group>
        </div>
      </div>
      <!-- 辅石 -->
      <div class="fx" v-if="dropDownOptional.gemNameList && dropDownOptional.gemNameList.length > 0">
        <div class="title">辅石</div>
        <div class="material selectBox">
          <el-radio-group v-model="maleDataFrom.gemName" size="small" @change="getSizeRange($event, 'gemName', 'assistStoneNameOne', '男')">
            <span
              class="inchesFix"
              :class="[flagArray.maleGemNameFlag == true ? 'stockBg' : '']"
              v-for="item in dropDownOptional.gemNameList"
              :key="item"
            >
              <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
              <div class="outStore" v-if="maleDataFrom.gemName == item && flagArray.maleGemNameFlag == true">缺货</div>
            </span>
          </el-radio-group>
        </div>
      </div>
      <!-- 版型 -->
      <div class="fx" v-if="dropDownOptional.maleFormatTypes && dropDownOptional.maleFormatTypes.length > 0">
        <div class="title">版型</div>
        <div class="material selectBox">
          <el-radio-group v-model="maleDataFrom.formatType" size="small" @change="getSizeRange($event, 'formatType', 'formatType', '男')">
            <span
              class="inchesFix"
              :class="[flagArray.maleFormatTypeFlag == true ? 'stockBg' : '']"
              v-for="item in dropDownOptional.maleFormatTypes"
              :key="item"
            >
              <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
              <div class="outStore" v-if="maleDataFrom.formatType == item && flagArray.maleFormatTypeFlag == true">缺货</div>
            </span>
          </el-radio-group>
        </div>
      </div>
      <!-- 主石重量 -->
      <div class="fx" v-if="dropDownOptional.maleMainStoneList && dropDownOptional.maleMainStoneList.length > 0">
        <div class="title">主石重量</div>
        <div class="material selectBox">
          <el-radio-group v-model="maleDataFrom.mainStone" size="small" @change="getSizeRange($event, 'mainStone', 'mainStone', '男')">
            <span
              class="inchesFix"
              :class="[flagArray.maleMainStoneFlag == true ? 'stockBg' : '']"
              v-for="item in dropDownOptional.maleMainStoneList"
              :key="item"
            >
              <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
              <div class="outStore" v-if="maleDataFrom.mainStone == item && flagArray.maleMainStoneFlag == true">缺货</div>
            </span>
          </el-radio-group>
        </div>
      </div>
      <!-- 净度 -->
      <div class="fx" v-if="dropDownOptional.maleMainStoneClarity && dropDownOptional.maleMainStoneClarity.length > 0">
        <div class="title">净度</div>
        <div class="material selectBox">
          <el-radio-group
            v-model="maleDataFrom.mainStoneClarity"
            size="small"
            @change="getSizeRange($event, 'mainStoneClarity', 'mainStoneClarity', '男')"
          >
            <span
              class="inchesFix"
              :class="[flagArray.maleClarityFlag == true ? 'stockBg' : '']"
              v-for="item in dropDownOptional.maleMainStoneClarity"
              :key="item"
            >
              <el-radio-button :value="item" :label="item">{{ item | clarityColor }}</el-radio-button>
              <div class="outStore" v-if="maleDataFrom.mainStoneClarity == item && flagArray.maleClarityFlag == true">缺货</div>
            </span>
          </el-radio-group>
        </div>
      </div>
      <!-- 颜色 -->
      <div class="fx" v-if="dropDownOptional.maleMainStoneColour && dropDownOptional.maleMainStoneColour.length > 0">
        <div class="title">颜色</div>
        <div class="material selectBox">
          <el-radio-group
            v-model="maleDataFrom.mainStoneColour"
            size="small"
            @change="getSizeRange($event, 'mainStoneColour', 'mainStoneColor', '男')"
          >
            <span
              class="inchesFix"
              :class="[flagArray.maleColourFlag == true ? 'stockBg' : '']"
              v-for="item in dropDownOptional.maleMainStoneColour"
              :key="item"
            >
              <el-radio-button :value="item" :label="item">{{ item | clarityColor }}</el-radio-button>
              <div class="outStore" v-if="maleDataFrom.mainStoneColour == item && flagArray.maleColourFlag == true">缺货</div>
            </span>
          </el-radio-group>
        </div>
      </div>
      <!-- 规格 -->
      <div class="fx" v-if="dropDownOptional.maleHandSizeList && dropDownOptional.maleHandSizeList.length > 0">
        <div class="title">
          规格<span class="inchesTips" v-if="dropDownOptional.maleHandSizeList && dropDownOptional.maleHandSizeList[0].includes('寸')"
            >(1英寸＝2.54厘米)</span
          >
        </div>
        <div class="material selectBox">
          <el-radio-group v-model="maleDataFrom.ringSize" size="small" @change="getSizeRange($event, 'ringSize', 'spec', '男')">
            <span
              class="inchesFix"
              :class="[flagArray.maleSizeFlag == true ? 'stockBg' : '']"
              v-for="item in dropDownOptional.maleHandSizeList"
              :key="item"
            >
              <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
              <div class="outStore" v-if="maleDataFrom.ringSize == item && flagArray.maleSizeFlag == true">缺货</div>
            </span>
          </el-radio-group>
        </div>
      </div>
      <!-- gia -->
      <div class="fx" v-if="dropDownOptional.maleGia && dropDownOptional.maleGia.length > 0">
        <div class="title">GIA</div>
        <div class="material selectBox">
          <el-radio-group v-model="maleDataFrom.gia" size="small" @change="getSizeRange($event, 'gia', 'gia', '男')">
            <span class="inchesFix" :class="[flagArray.maleGiaFlag == true ? 'stockBg' : '']" v-for="item in dropDownOptional.maleGia" :key="item">
              <el-radio-button :value="item" :label="item">{{ item | cutFilter }}</el-radio-button>
              <div class="outStore" v-if="maleDataFrom.gia == item && flagArray.maleGiaFlag == true">缺货</div>
            </span>
          </el-radio-group>
        </div>
      </div>
      <!-- 备注 -->
      <div class="textareaRemark">
        <div class="title">备注</div>
        <el-input
          :rows="6"
          :maxlength="64"
          type="textarea"
          placeholder="其他要求与说明请在此处备注说明,字符数限制64位"
          v-model="maleDataFrom.productRemark"
        ></el-input>
      </div>
      <div class="fl" style="justify-content: end;">
        <div
          style="color:red;margin-right:20px"
          v-if="
            (maleDataFrom.tradePrice && (!hasPermission('展厅') || !hasPermission('加盟公司总经理'))) ||
              (maleDataFrom.labelPrice && (!maleDataFrom.exclusivePrice || maleDataFrom.exclusivePrice == 0)) ||
              (maleDataFrom.exclusivePrice && maleDataFrom.exclusivePrice != 0)
          "
        >
          (预估价格，实际价格请以结算为准)
        </div>
        <div>
          <!-- 批发价格 -->
          <div class="box_detail_input marginFx" v-if="maleDataFrom.tradePrice && (!hasPermission('展厅') || !hasPermission('加盟公司总经理'))">
            <div class="input-wrap">批发价格：{{ maleDataFrom.tradePrice }}</div>
          </div>
          <!-- 标签价格 -->
          <div class="box_detail_input marginFx" v-if="maleDataFrom.labelPrice && (!maleDataFrom.exclusivePrice || maleDataFrom.exclusivePrice == 0)">
            <div class="input-wrap">标签价格：{{ maleDataFrom.labelPrice }}</div>
          </div>
          <!-- 尊享价 -->
          <div class="box_detail_input marginFx" v-if="maleDataFrom.exclusivePrice && maleDataFrom.exclusivePrice != 0">
            <div class="input-wrap">尊享价：{{ maleDataFrom.exclusivePrice }}</div>
          </div>
        </div>
      </div>
      <!-- 下单件数 -->
      <div class="box_detail_input marginFx">
        <div class="input-wrap">
          <span class="prompt">库存{{ dropDownOptional.stockQuantity || 0 }}件，可选择不同规格</span>
          <span class="prompt placeNum">购买数量：</span>
          <el-input-number
            v-model="maleDataFrom.quantity"
            :min="0"
            :max="maleStockQuantity"
            @change="getSizeRange($event, 'quantity', '男')"
            label="描述文字"
          ></el-input-number>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cutFilter, clarityColor } from '@/utils/filetrs';
import { getSpotSpecification, getPriceRangeSpot } from '@/api/detailsApi';
import { deepClone } from '@/utils/util';
export default {
  name: 'Spot',
  props: {
    DataList: {
      type: Object,
      default() {
        return {};
      },
    },
    leastQuantity: {
      type: Number,
      default: 0,
    },
    stockQuantityCut: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      spotActive: 'first',
      dataFrom: {
        styleNumber: this.DataList.styleNumber, // 公司款号
        productName: this.DataList.productName,
        isCouples: this.DataList.isCouples, // 是否情侣款
        classify: 2, // 产品结构
        quantity: 0, // 数量
        shopType: 1,
        spotFlag: 1,
        ringSize: '',
        goldWeigh: '',
        productRemark: '',
        texture: '',
        gemName: '',
        formatType: '',
        mainStone: '',
        mainStoneClarity: null,
        mainStoneColour: null,
        gia: '',
        quantity: 0,
        tradePrice: '',
        labelPrice: '',
        exclusivePrice: '',
        gender: '女',
      },
      maleDataFrom: {
        styleNumber: this.DataList.styleNumber, // 公司款号
        productName: this.DataList.productName,
        isCouples: this.DataList.isCouples, // 是否情侣款
        classify: 2, // 产品结构
        quantity: 0, // 数量
        shopType: 1,
        spotFlag: 1,
        ringSize: '',
        goldWeigh: '',
        productRemark: '',
        texture: '',
        gemName: '',
        formatType: '',
        mainStone: '',
        mainStoneClarity: null,
        mainStoneColour: null,
        gia: '',
        quantity: 0,
        tradePrice: '',
        labelPrice: '',
        exclusivePrice: '',
        gender: '男',
      },
      dropDownOptional: {
        handTextureList: [],
        mainStoneList: [],
        handSizeList: [],
        mainStoneClarity: [],
        mainStoneColour: [],
        gia: [],
        gemNameList: [],
        formatTypes: [],
        maleHandTextureList: [],
        maleMainStoneList: [],
        maleHandSizeList: [],
        maleMainStoneClarity: [],
        maleMainStoneColour: [],
        maleGia: [],
        maleGemNameList: [],
        maleFormatTypes: [],
        stockQuantity: 0,
      },
      mainStoneList: [],
      handTextureList: [],
      handSizeList: [],
      textureIndex: 0,
      spotList: [],
      maleSpotList: [],
      flagArray: {
        textureFlag: false,
        mainStoneFlag: false,
        sizeFlag: false,
        colourFlag: false,
        clarityFlag: false,
        giaFlag: false,
        gemNameFlag: false,
        formatTypeFlag: false,
        maleTextureFlag: false,
        maleMainStoneFlag: false,
        maleSizeFlag: false,
        maleColourFlag: false,
        maleClarityFlag: false,
        maleGiaFlag: false,
        maleGemNameFlag: false,
        maleFormatTypeFlag: false,
      },
      stockQuantity: 0,
      maleStockQuantity: 0,
    };
  },
  filters: {
    cutFilter,
    clarityColor,
  },
  mounted() {
    this.dataFrom = { ...this.dataFrom, ...this.DataList };
    this.maleDataFrom = { ...this.maleDataFrom, ...this.DataList };
    // 获取可选属性
    this.getOptional('女');
    this.getOptional('男');
    this.dropDownOptional.stockQuantity = this.stockQuantityCut;
  },
  methods: {
    handleClick(tab) {
      if (tab.name == 'first') {
        this.fetchPriceRangeSpot('女');
      } else {
        this.fetchPriceRangeSpot('男');
      }
    },
    // 金重排序
    mainStoneSort(list) {
      // 1. 将每个区间字符串解析为对象
      const parsedRanges = list?.map((range) => {
        const [start, end] = range.split('-').map(parseFloat);
        return { start, end, range }; // 保留原始字符串以便最后恢复
      });
      // 2. 根据 start 值进行排序
      parsedRanges.sort((a, b) => a.start - b.start);
      // 3. 提取排序后的原始字符串
      return parsedRanges.map((item) => item.range);
    },
    // 版型排序
    sortByChineseSize(list) {
      const orderMap = { 小: 1, 中: 2, 大: 3 };
      return list.slice().sort((a, b) => orderMap[a] - orderMap[b]);
    },
    getParameter(spotList, gender) {
      if (!gender || gender === '女') {
        spotList?.forEach((item) => {
          // if (item.isCart == 0) {
          Object.keys(item).forEach((key) => {
            if (key && key == 'texture' && item[key] && !this.dropDownOptional.handTextureList.includes(item[key])) {
              this.dropDownOptional.handTextureList.push(item[key]);
            }
            if (key && key == 'spec' && item[key] && !this.dropDownOptional.handSizeList.includes(item[key])) {
              this.dropDownOptional.handSizeList.push(item[key]);
              // 规格重新排序
              this.dropDownOptional.handSizeList?.sort(function(a, b) {
                return a - b;
              });
            }
            if (key && key == 'mainStoneWeight' && item[key] && !this.dropDownOptional.mainStoneList.includes(item[key])) {
              this.dropDownOptional.mainStoneList.push(item[key]);
              // 主石重新排序
              this.dropDownOptional.mainStoneList?.sort(function(a, b) {
                return a - b;
              });
            }
            if (key && key == 'mainStoneClarity' && !this.dropDownOptional.mainStoneClarity.includes(item[key])) {
              this.dropDownOptional.mainStoneClarity.push(item[key]);
            }
            if (key && key == 'mainStoneColor' && !this.dropDownOptional.mainStoneColour.includes(item[key])) {
              this.dropDownOptional.mainStoneColour.push(item[key]);
            }
            if (key && key == 'gia' && item[key] && !this.dropDownOptional.gia.includes(item[key])) {
              this.dropDownOptional.gia.push(item[key]);
            }
            if (key && key == 'assistStoneNameOne' && item[key] && !this.dropDownOptional.gemNameList.includes(item[key])) {
              this.dropDownOptional.gemNameList.push(item[key]);
            }
            if (key && key == 'formatType' && item[key] && !this.dropDownOptional.formatTypes.includes(item[key])) {
              this.dropDownOptional.formatTypes.push(item[key]);
            }
          });
          // }
        });
        this.dropDownOptional.mainStoneClarity = [...new Set(this.dropDownOptional.mainStoneClarity)];
        this.dropDownOptional.mainStoneColour = [...new Set(this.dropDownOptional.mainStoneColour)];
        this.dropDownOptional.formatTypes = this.sortByChineseSize(this.dropDownOptional.formatTypes);
      } else {
        spotList?.forEach((item) => {
          // if (item.isCart == 0) {
          Object.keys(item).forEach((key) => {
            if (key && key == 'texture' && item[key] && !this.dropDownOptional.maleHandTextureList.includes(item[key])) {
              this.dropDownOptional.maleHandTextureList.push(item[key]);
            }
            if (key && key == 'spec' && item[key] && !this.dropDownOptional.maleHandSizeList.includes(item[key])) {
              this.dropDownOptional.maleHandSizeList.push(item[key]);
              // 规格重新排序
              this.dropDownOptional.maleHandSizeList?.sort(function(a, b) {
                return a - b;
              });
            }
            if (key && key == 'mainStoneWeight' && item[key] && !this.dropDownOptional.maleMainStoneList.includes(item[key])) {
              this.dropDownOptional.maleMainStoneList.push(item[key]);
            }
            if (key && key == 'mainStoneClarity' && !this.dropDownOptional.maleMainStoneClarity.includes(item[key])) {
              this.dropDownOptional.maleMainStoneClarity.push(item[key]);
            }
            if (key && key == 'mainStoneColor' && !this.dropDownOptional.maleMainStoneColour.includes(item[key])) {
              this.dropDownOptional.maleMainStoneColour.push(item[key]);
            }
            if (key && key == 'gia' && item[key] && !this.dropDownOptional.maleGia.includes(item[key])) {
              this.dropDownOptional.maleGia.push(item[key]);
            }
            if (key && key == 'assistStoneNameOne' && item[key] && !this.dropDownOptional.maleGemNameList.includes(item[key])) {
              this.dropDownOptional.maleGemNameList.push(item[key]);
            }
            if (key && key == 'formatType' && item[key] && !this.dropDownOptional.maleFormatTypes.includes(item[key])) {
              this.dropDownOptional.maleFormatTypes.push(item[key]);
            }
          });
          // }
        });
        this.dropDownOptional.maleMainStoneClarity = [...new Set(this.dropDownOptional.maleMainStoneClarity)];
        this.dropDownOptional.maleMainStoneColour = [...new Set(this.dropDownOptional.maleMainStoneColour)];
        this.dropDownOptional.maleFormatTypes = this.sortByChineseSize(this.dropDownOptional.maleFormatTypes);
      }
    },
    // 获取可选属性
    getOptional(gender) {
      let obj = {
        styleNumber: this.DataList.styleNumber,
        classify: 2,
        spotFlag: 1,
        gender: gender,
      };
      getSpotSpecification(obj).then((res) => {
        if (!gender || gender == '女') {
          this.spotList.splice(0, this.spotList.length);
          res.data.records.forEach((item) => {
            if (!item.mainStoneClarity == null || item.mainStoneClarity == undefined) {
              item.mainStoneClarity = '';
            }
            if (item.mainStoneColor == null || item.mainStoneColor == undefined) {
              item.mainStoneColor = '';
            }
            this.spotList.push(item);
          });
          this.getParameter(this.spotList, gender);
        } else {
          this.maleSpotList.splice(0, this.maleSpotList.length);
          res.data.records.forEach((item) => {
            if (!item.mainStoneClarity == null || item.mainStoneClarity == undefined) {
              item.mainStoneClarity = '';
            }
            if (item.mainStoneColor == null || item.mainStoneColor == undefined) {
              item.mainStoneColor = '';
            }
            this.maleSpotList.push(item);
          });
          this.getParameter(this.maleSpotList, gender);
        }
      });
    },
    getSizeRange(value, type, spotType, gender) {
      if (gender === '女') {
        this.dataFrom[type] = value;
        this.dataProcessing(this.spotList, value, 'dataFrom', type);
      } else {
        this.maleDataFrom[type] = value;
        this.dataProcessing(this.maleSpotList, value, 'maleDataFrom', type);
      }
      this.fetchPriceRangeSpot(gender);
    },
    fetchPriceRangeSpot(gender) {
      const obj = gender == '女' ? deepClone(this.dataFrom) : deepClone(this.maleDataFrom);
      if (!obj.mainStone || obj.mainStone == '' || !obj.gia || obj.gia == '') return;
      getPriceRangeSpot(obj).then((res) => {
        if (res.data.stockQuantity > this.leastQuantity && this.dataFrom.quantity > res.data.stockQuantity) {
          this.dataFrom.quantity = res.data.stockQuantity;
        }
        if (res.data.stockQuantity < this.leastQuantity) {
          this.dataFrom.quantity = this.leastQuantity;
        }
        if (gender == '女') {
          this.stockQuantity = res.data.stockQuantity;
          this.dataFrom.tradePrice = res.data.tradePrice;
          this.dataFrom.labelPrice = res.data.labelPrice;
          this.dataFrom.exclusivePrice = res.data.exclusivePrice;
          if (res.data.stockQuantity > this.leastQuantity && this.dataFrom.quantity > res.data.stockQuantity) {
            this.dataFrom.quantity = res.data.stockQuantity;
          }
          if (res.data.stockQuantity < this.leastQuantity) {
            this.dataFrom.quantity = this.leastQuantity;
          }
        } else {
          this.maleStockQuantity = res.data.stockQuantity;
          this.maleDataFrom.tradePrice = res.data.tradePrice;
          this.maleDataFrom.labelPrice = res.data.labelPrice;
          this.maleDataFrom.exclusivePrice = res.data.exclusivePrice;
          if (res.data.stockQuantity > this.leastQuantity && this.maleDataFrom.quantity > res.data.stockQuantity) {
            this.maleDataFrom.quantity = res.data.stockQuantity;
          }
          if (res.data.stockQuantity < this.leastQuantity) {
            this.maleDataFrom.quantity = this.leastQuantity;
          }
        }
        this.dropDownOptional.stockQuantity = res.data.stockQuantity;
      });
    },
    dataProcessing(spotList, item, type, select) {
      if (select == 'quantity') return;
      const shopArray = [];
      shopArray.splice(0, shopArray.length);
      if (!item) {
        shopArray.push(...spotList);
      } else {
        spotList?.forEach((res) => {
          Object.keys(res).forEach((key) => {
            if (item == res[key] && key != 'accessoryStone' && key != 'assistStoneNameTwo') {
              shopArray.push(res);
            }
          });
        });
      }
      if (shopArray.length == spotList.length) {
        this.fetchPriceRangeSpot();
        return;
      }
      if (this[type].mainStone) {
        if (type == 'dataFrom') {
          this.flagArray.mainStoneFlag = !shopArray.some((product) => product.mainStoneWeight === this[type].mainStone);
        } else {
          this.flagArray.maleMainStoneFlag = !shopArray.some((product) => product.mainStoneWeight === this[type].mainStone);
        }
      }
      if (this[type].texture) {
        if (type == 'dataFrom') {
          this.flagArray.textureFlag = !shopArray.some((product) => product.texture === this[type].texture);
        } else {
          this.flagArray.maleTextureFlag = !shopArray.some((product) => product.texture === this[type].texture);
        }
      }
      if (this[type].ringSize) {
        if (type == 'dataFrom') {
          this.flagArray.sizeFlag = !shopArray.some((product) => product.spec === this[type].ringSize);
        } else {
          this.flagArray.maleSizeFlag = !shopArray.some((product) => product.spec === this[type].ringSize);
        }
      }
      if (this[type].mainStoneClarity) {
        if (type == 'dataFrom') {
          this.flagArray.clarityFlag = !shopArray.some((product) => product.mainStoneClarity === this[type].mainStoneClarity);
        } else {
          this.flagArray.maleClarityFlag = !shopArray.some((product) => product.mainStoneClarity === this[type].mainStoneClarity);
        }
      }
      if (this[type].mainStoneColour) {
        if (type == 'dataFrom') {
          this.flagArray.colourFlag = !shopArray.some((product) => product.mainStoneColor === this[type].mainStoneColour);
        } else {
          this.flagArray.maleColourFlag = !shopArray.some((product) => product.mainStoneColor === this[type].mainStoneColour);
        }
      }
      if (this[type].gia) {
        if (type == 'dataFrom') {
          this.flagArray.giaFlag = !shopArray.some((product) => product.gia === this[type].gia);
        } else {
          this.flagArray.maleGiaFlag = !shopArray.some((product) => product.gia === this[type].gia);
        }
      }
      if (this[type].gemName) {
        if (type == 'dataFrom') {
          this.flagArray.gemNameFlag = !shopArray.some((product) => product.assistStoneNameOne === this[type].gemName);
        } else {
          this.flagArray.maleGemNameFlag = !shopArray.some((product) => product.assistStoneNameOne === this[type].gemName);
        }
      }
      if (this[type].formatType) {
        if (type == 'dataFrom') {
          this.flagArray.formatTypeFlag = !shopArray.some((product) => product.formatType === this[type].formatType);
        } else {
          this.flagArray.maleFormatTypeFlag = !shopArray.some((product) => product.formatType === this[type].formatType);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.loverSortBox {
  margin-bottom: 10px;
  position: relative;

  .spotTabs {
    // width: 230px;
  }

  .spotTabs .el-tabs__nav {
    width: 100%;
  }

  .spotTabs .el-tabs__item {
    // width: 50%;
    text-align: center;
  }

  .spotTabs .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    border-bottom: #e4e7ed;
    background: #ec9368;
    color: #fff;
  }

  .spotTabs .el-tabs--card > .el-tabs__header .el-tabs__item.is-active:hover {
    color: #fff !important;
  }

  .spotTabs .el-tabs__item:hover {
    color: #ec9368 !important;
  }

  .loverPricesPrompt {
    font-size: 13px;
    color: red;
    position: absolute;
    top: 40px;
    z-index: 1;
  }

  .el-select-dropdown__item {
    margin: 10px 0 0 10px;
    display: inline-block;
    height: 46px;
    text-align: center;
    line-height: 46px;
    border: 1px solid #d3d3d3;
    padding: 0;
    font-size: 16px;
    color: #000;
  }

  .el-select-dropdown__item.selected {
    background: #fff2ea;
    font-size: 16px;
    color: #000;
    font-weight: 400;
    border: 1px solid #ee9c75;
  }
}
.custom {
  .fx {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    position: relative;
    margin: 8px 0;
  }

  .fl {
    display: flex;
    align-items: center;
    position: relative;
  }

  .selectBox {
    ::v-deep.el-popper {
      left: 0 !important;
    }

    .el-scrollbar__view li {
      width: 178px;
    }
  }

  .selectBoxWatch {
    .el-scrollbar__view li {
      min-width: 90px;
    }
  }
  .inchesFix {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    .outStore {
      font-size: 12px;
      position: absolute;
      right: -1px;
      top: -1px;
      color: #999999;
    }
  }
  /deep/ .stockBg .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #c0c4cc;
    background-color: #f2f6fc;
    border-color: #f2f6fc;
  }
  .inchesTips {
    color: #ec9368;
  }
  .material {
    width: 800px;
    ::v-deep .el-radio-button__inner {
      min-width: 65px;
    }
  }
  .title {
    font-size: 18px;
    color: #000;
    display: inline-block;
    margin-bottom: 8px;
    .hidden {
      visibility: hidden;
    }
  }

  .value-font {
    font-size: 18px;
    color: #ec9368;
  }
  .mainStoneClssBoxFx {
    width: 675px;
    display: flex;
    align-items: center;
    position: relative;
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #fff;
    background-color: #ec9368;
    border-color: #ec9368;
    box-shadow: none;
  }
  ::v-deep .el-radio-button__inner:hover {
    color: rgb(236, 147, 104);
  }
  ::v-deep .el-radio-button__inner {
    margin: 5px;
    border-left: 1px solid #dcdfe6;
    border-radius: 4px;
  }
  ::v-deep .inchesFix:first-child .el-radio-button__inner {
    margin-left: 0;
  }
  ::v-deep .el-radio-group {
    display: flex;
    flex-wrap: wrap;
  }
  .input-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
}
</style>
